import {
  Box,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import Close from "../../../Assets/Close";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { setProductAccessoriesWireColor } from "../../../Redux/Slice";

const ChooseWire = ({ show, close }) => {
  const cables = useSelector((state) => state.activeStep.cables);
  const selectedWire = useSelector(
    (state) => state.activeStep.product.accessories.wireColor
  );
  const dispatch = useDispatch();

  const handleSubmit = () => {
    close();
  };

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    maxHeight: "700px",
  };

  return (
    <>
      <Modal
        open={show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              // borderRadius: "25px",
              // padding: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: window.innerWidth > 768 ? "40px" : "1.5rem",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Choose Wire Color
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={close}
            >
              <Close />
            </div>
          </div>
          <div
            style={{
              padding: "15px",
              height: "400px",
              overflowY: "auto",
            }}
          >
            <Row>
              {cables.map((cable) => (
                <Col key={cable.title} xs={12} sm={6} md={4}>
                  <Card
                    className={`${
                      selectedWire === cable?.color ? "selected" : ""
                    }`}
                    style={{
                      borderRadius: "20px",
                      border: "1px solid #AFE9E0",
                      margin: "10px",
                      marginTop: "0px",
                      padding: "20px",
                    }}
                  >
                    <img
                      style={{
                        // width: "100%",
                        height: "150px",
                      }}
                      src={cable?.image}
                      alt=""
                    />
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontWeight: "600",
                          fontSize: window.innerWidth > 768 ? "16px" : "1rem",
                          color: "#595959",
                        }}
                      >
                        {cable?.name}
                      </Card.Title>
                      <Card.Text
                        style={{
                          fontSize: window.innerWidth > 768 ? "12px" : "0.5rem",
                          color: "#8F8F8F",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: cable?.desc,
                        }}
                      ></Card.Text>
                    </Card.Body>
                    <Button
                      style={{
                        border: "none",
                        color:
                          cable.color === selectedWire ? "#ffffff" : "#03705E",
                        fontSize: "16px",
                        fontWeight: "600",
                        cursor: "pointer",
                        backgroundColor:
                          cable.color === selectedWire ? "#F25C12" : "#AFE9E0",
                        textAlign: "center",
                        padding: "20px",
                        borderRadius: "16px",
                      }}
                      onClick={() => {
                        dispatch(setProductAccessoriesWireColor(cable?.color));
                      }}
                    >
                      SELECT
                    </Button>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              style={{
                color: "white",
                fontWeight: "600",
                cursor: "pointer",
                backgroundColor: "#36CDB4",
                textAlign: "center",
                padding: "10px",
                paddingRight: "40px",
                paddingLeft: "40px",
                paddingTop: "10px",
                borderRadius: "25px",
                border: "none",
              }}
              onClick={handleSubmit}
            >
              OK, I&apos;M READY
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ChooseWire;
