import { Typography } from "@mui/material";
import React, { useState } from "react";
import { Card, Col } from "react-bootstrap";

import SurfaceIframe from "../Delivery/Install/SurfaceIframe";
import ConcealedIframe from "../Delivery/Install/ConcealedIframe";
import PassthroughIframe from "../Delivery/Install/PassthroughIframe";
import BackingIframe from "../Delivery/Install/BackingIframe";
import BackingModal from "./BackingModal";
import { useSelector } from "react-redux";
import HangingIframe from "../Delivery/Install/HangingIframe";
import DesktopIframe from "../Delivery/Install/DesktopIframe";

const NoBacking = () => {
  const theme = useSelector((state) => state.activeStep.theme);
  const product = useSelector((state) => state.activeStep.product);
  const [install, setInstall] = useState("Surface");
  const [showModal, setShowModal] = useState(true);

  // console.log(install);
  return (
    <>
      {/* {product.source === "LED" ? (
        <></>
      ) : (
        <>
          <Col xs={12} md={4} sm={6} lg={3} xl={2}>
            <Typography
              onClick={() => {
                setInstall("Backing");
              }}
              className={`border-2 border-[#AFE9E0] m-2 py-3 text-center items-center cursor-pointer rounded-3xl ${
                install === "Backing" ? "selected" : ""
              }`}
              style={{
                background: "white",
                fontWeight: "400",
                marginLeft: "0px",
                color: "#000000",
                fontFamily: "Beatrice",
                borderRadius: "20px",
              }}
            >
              Backing Install
            </Typography>
          </Col>
        </>
      )} */}
      <Col xs={12} md={4} sm={6} lg={3} xl={2}>
        <Typography
          onClick={() => {
            setInstall("Surface");
          }}
          className={`border-2 border-[#AFE9E0] m-2 py-3 text-center items-center  cursor-pointer rounded-3xl ${
            install === "Surface" ? "selected" : ""
          }`}
          style={{
            background: "white",
            fontWeight: "400",
            marginLeft: "0px",
            color: "#000000",
            fontFamily: "Beatrice",
            borderRadius: "20px",
          }}
        >
          Surface Install
        </Typography>
      </Col>
      <Col xs={12} md={4} sm={6} lg={3} xl={2}>
        <Typography
          onClick={() => {
            setInstall("Concealed");
          }}
          style={{
            fontWeight: "400",
            background: "white",
            color: "#000000",
            fontFamily: "Beatrice",
            borderRadius: "20px",
          }}
          className={`border-2 border-[#AFE9E0] m-2 py-3 text-center items-center  cursor-pointer rounded-3xl ${
            install === "Concealed" ? "selected" : ""
          }`}
        >
          Concealed Install
        </Typography>
      </Col>
      {product.source === "LED" ? (
        <></>
      ) : (
        <>
          <Col xs={12} md={4} sm={6} lg={3} xl={2}>
            <Typography
              onClick={() => {
                setInstall("Passthrough");
              }}
              style={{
                fontWeight: "400",
                background: "white",
                color: "#000000",
                fontFamily: "Beatrice",
                borderRadius: "20px",
              }}
              className={`border-2 border-[#AFE9E0] m-2 py-3 text-center items-center  cursor-pointer rounded-3xl ${
                install === "Passthrough" ? "selected" : ""
              }`}
            >
              Passthrough Install
            </Typography>
          </Col>
          <Col xs={12} md={4} sm={6} lg={3} xl={2}>
            <Typography
              onClick={() => {
                setInstall("Hanging");
              }}
              style={{
                fontWeight: "400",
                background: "white",
                color: "#000000",
                fontFamily: "Beatrice",
                borderRadius: "20px",
              }}
              className={`border-2 border-[#AFE9E0] m-2 py-3 text-center items-center  cursor-pointer rounded-3xl ${
                install === "Hanging" ? "selected" : ""
              }`}
            >
              Hanging Install
            </Typography>
          </Col>
        </>
      )}
      {/* <Typography
          onClick={() => {
            setInstall("Desktop");
          }}
          className={`border-2 border-[#AFE9E0] py-4 text-center items-center text-xl cursor-pointer rounded-3xl ${
            install === "Desktop" ? "selected" : ""
          }`}
          style={{
            fontWeight: "400",
            background: "white",
            margin: "8px",
            marginRight: "20px",
            color: "#000000",
            fontFamily: "Beatrice",
            borderRadius: "20px",
            padding: "10px",
          }}
        >
          Desktop Install
        </Typography> */}
      <Card
        style={{
          backgroundColor: `${theme}`,
          border: "none",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Card.Body
          style={{
            display: "flex",
            border: "1px solid #25CCB0",
            borderRadius: "24px",
            padding: "0px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {install === "Backing" ? <BackingIframe /> : <></>}
          {install === "Surface" ? <SurfaceIframe /> : <></>}
          {install === "Concealed" ? <ConcealedIframe /> : <></>}
          {install === "Passthrough" ? <PassthroughIframe /> : <></>}
          {install === "Hanging" ? <HangingIframe /> : <></>}
          {install === "Desktop" ? <DesktopIframe /> : <></>}
        </Card.Body>
      </Card>
      <BackingModal show={showModal} close={() => setShowModal(false)} />
    </>
  );
};

export default NoBacking;
