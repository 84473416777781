import React from "react";

const Upload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="49"
      viewBox="0 0 72 49"
      fill="none"
    >
      <path
        d="M36.2396 47V24.5M36.2396 24.5L27.7524 31.9999M36.2396 24.5L44.7269 31.9999M15.8702 43.2499C8.37036 43.2499 2.29053 37.9733 2.29053 31.4644C2.29053 26.1007 6.41866 21.574 12.0678 20.1469C12.3079 20.0861 12.4753 19.8955 12.4753 19.6786C12.4753 9.91495 21.595 2 32.8447 2C44.0943 2 53.2142 9.91495 53.2142 19.6786C53.2142 19.8656 53.4164 20.0045 53.6268 19.9637C54.5865 19.7769 55.5846 19.6786 56.6091 19.6786C64.1088 19.6786 70.1887 24.9551 70.1887 31.4644C70.1887 37.9733 64.1088 43.2499 56.6091 43.2499"
        stroke="#595959"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Upload;
