import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";


const useSVG = () => {
    const [svgLink, setSvgLink] = useState([]);
    const product = useSelector((state) => state.activeStep.product);

    const mergeSVGs = async (svgPaths) => {
        const svgPromises = svgPaths.map(async (path) => {
            const response = await axios.get(path?.url);
            const svgContent = response.data;
            const newSvg = convertSVG(svgContent);
            return newSvg;
        });

        const svgContents = await Promise.all(svgPromises);
        return svgContents;
    };

    const getCharacterFromPath = (path) => {
        const fileName = path.split(".");
        // Handle Upper/Lowercase
        if (fileName[0].length <= 2) {
            return fileName[0].charAt(0);
        } else {
            return fileName[0]
        }
    };

    const getSvgNameForCharacter = (character) => {
        switch (character) {
            case " ":
                return "space"
            case "\\":
                return "_bs"
            case "-":
                return "_d"
            case "!":
                return "_e"
            case "/":
                return "_fs"
            case "'":
                return "_p"
            case '"':
                return "_q"
            case "?":
                return "_qe"
            default: 
                return character
        }
    }


    const convertSVG = (svgString) => {
        // Parse the input SVG string
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgString, "image/svg+xml");

        // Get the viewBox attribute value if it exists
        const viewBoxAttr = svgDoc.documentElement.getAttribute("viewBox");
        let width = 100;
        let height = 100;

        if (viewBoxAttr) {
            const viewBoxValues = viewBoxAttr.split(" ").map(parseFloat);
            width = viewBoxValues[2];
            height = viewBoxValues[3];
        }

        // Get the paths and lines within the original SVG
        const pathsAndLines = Array.from(svgDoc.querySelectorAll("svg > *"));

        // Create the new SVG string
        let newSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
           `;

        // Add the paths and lines to the new SVG
        pathsAndLines.forEach((element) => {
            newSVG += `            ${element.outerHTML}\n`;
        });

        newSVG += `        
        </svg>`;

        return newSVG;
    }

    const getHighestSvgHeight = (svgArray) => {
        let maxHeight = 0;

        svgArray.forEach((svgString) => {
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
            const svgElement = svgDoc.documentElement;
            const height = parseFloat(svgElement.getAttribute("height"));

            if (height > maxHeight) {
                maxHeight = height;
            }
        });

        return maxHeight;
    }

    const combineAndAdjustSVGs = (svgArray, tracking) => {
        const bottomHeight = getHighestSvgHeight(svgArray);
        const strokeWidth = 20;
        const gap = tracking ? tracking : 10;

        let xOffset = 0;
        // let yOffset = 0;
        let totalHeight = 0;

        let combinedSVG = `
          <svg xmlns="http://www.w3.org/2000/svg">
            <defs>
              <style>.e{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:${strokeWidth}px;}</style>
            </defs>
            <g>
        `;

        for (const [index, svgContent] of svgArray.entries()) {
            const svgHeight = svgContent.match(/height="([^"]+)"/);
            let floatSVGHeight = parseFloat(svgHeight[1]);
            const adjustedSVG = svgContent.replace(/<svg[^>]+>|<\/svg>/g, "");
            combinedSVG += `
              <!-- Content from the SVG -->
              <g id="${index}" transform="translate(${xOffset}, ${bottomHeight - floatSVGHeight
                })">
                ${adjustedSVG}
              </g>
          `;

            const widthMatch = svgContent.match(/width="([^"]+)"/);
            const heightMatch = svgContent.match(/height="([^"]+)"/);
            if (widthMatch && heightMatch) {
                const svgWidth = parseFloat(widthMatch[1]);
                const svgHeight = parseFloat(heightMatch[1]);
                totalHeight = Math.max(totalHeight, svgHeight + strokeWidth);
                xOffset += svgWidth + gap;
            }
        }

        combinedSVG += `
            </g>
          </svg>
        `;

        combinedSVG = combinedSVG.replace(
            "<svg",
            `<svg viewBox="0 0 ${xOffset} ${totalHeight}" width="${(product.size.width +
                product.backing.width +
                product.backing.lockedWidth) *
            96
            }" height="${(product.size.height +
                product.backing.height +
                product.backing.lockedHeight) *
            96
            }" x="192" y="0"`
        );

        return combinedSVG;
    }

    const fetchAndMergeFilteredSVGs = async (textValue, tracking, svgFiles) => {
        try {

            const normalizedTextValue = textValue.replace(/\n/g, " ");
            const orderedCharacters = normalizedTextValue.split("");
            const filteredSVGPaths = orderedCharacters.map((character) => {
                const characterName = getSvgNameForCharacter(character);
                const svgFile = svgFiles.find((svgFile) => {
                    const svgCharacter = getCharacterFromPath(svgFile.name);

                    return characterName === svgCharacter;
                });
                if (!svgFile) {
                    console.warn("Could not find svg character for ", character)
                }
                return svgFile
            }).filter(svgPath => svgPath !== undefined);

            const mergedContent = await mergeSVGs(filteredSVGPaths);
            const combinedSVGS = combineAndAdjustSVGs(mergedContent, tracking);

            const blob = new Blob([combinedSVGS], { type: "image/svg+xml" });
            const svgFile = new File([blob], "image.svg", { type: "image/svg+xml" });
            const form = new FormData();
            form.append("image", svgFile);

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}led-backing`,
                form
            );

            let newObj = { text: textValue, link: response.data };
            setSvgLink((prevSvgLink) => [...prevSvgLink, newObj]);
        } catch (error) {
            console.error("Error fetching or merging SVGs:", error);
        }
    };

    const fetchSVGs = async (textArea) => {
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}fonts-svgs/get-svgs/${product.source}/${textArea.fontFamily}`
        );
        if (response.data.svgFiles) {
            fetchAndMergeFilteredSVGs(
                textArea.value,
                textArea.tracking,
                response.data.svgFiles
            );
        }
    };

    return {
        svgLink,
        fetchSVGs,
    }
}

export default useSVG;