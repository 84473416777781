import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import Close from "../../../Assets/Close";
import CustomImage from "../../../Assets/CustomColors.png";
import {
  SketchPicker,
} from "react-color";
import { addOtherColors } from "../../../Redux/Slice";
import { useDispatch } from "react-redux";

const CustomColor = ({
  selectedTextArea,
  textAreas,
  show,
  close,
  handleColorSelection,
}) => {
  const dispatch = useDispatch();
  const handleChangeComplete = (color) => {
    let updatedColor = {
      code: color.hex,
      name: "Custom Color",
      ProductionCode: "Custom Color",
      imageOn: CustomImage,
    };
    dispatch(addOtherColors(updatedColor));
    handleColorSelection(color.hex);
  };

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 768 ? "30%" : "100%",
    // height: "95vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  return (
    <>
      <Modal
        open={show}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              border: "none",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: "3vh",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Choose Custom Color
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={close}
            >
              <Close />
            </div>
          </div>
          <div
            style={{
              // height: "525px",
              overflow: "auto",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            <div
              style={{
                margin: "5px",
                background: "white",
                borderRadius: "16px",
                padding: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SketchPicker
                presetColors={[]}
                width="100%"
                color={
                  textAreas.find((area) => area.id === selectedTextArea)
                    ?.color || "black"
                }
                onChangeComplete={handleChangeComplete}
              />
            </div>

            <div
              className="continue-button-width-adjust"
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                sx={{
                  width: window.innerWidth > 768 ? "30%" : "100%",
                  color: "#ffffff",
                  fontSize: "16px",
                  padding: "10px",
                  fontWeight: "600",
                  bgcolor: "#36CDB4",
                  borderRadius: "16px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                  ":hover": {
                    color: "#36CDB4",
                    border: "1px solid #36CDB4",
                  },
                }}
                onClick={close}
              >
                Continue
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CustomColor;
