import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import CustomColors from "../../Assets/CustomColors.png";
import { getOSInfo } from "../../utils/client/calculations";
import { debounce } from "../../utils/client/helpers";

const ColorsList = ({
  setShowCustomPopup,
  selectedTextArea,
  textAreas,
  handleColorSelection,
  handleLetterColorSelection,
  showCustom,
  setShowCustom,
}) => {
  const colors = useSelector((state) => state.activeStep.colors);
  const product = useSelector((state) => state.activeStep.product);
  const [hoveredStates, setHoveredStates] = useState([]); // Initialize an array of hover states

  const handleHoverChange = (index, isHovered) => {
    const newHoveredStates = Array(hoveredStates.length).fill(false);

    // Set the specified index to the provided isHovered value
    newHoveredStates[index] = isHovered;

    // Update the state
    setHoveredStates(newHoveredStates);
  };

  const debouncedHandleHoverChange = debounce(handleHoverChange, 300);

  useEffect(() => {
    // Attach debounced function to scroll event
    window.addEventListener("scroll", debouncedHandleHoverChange);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", debouncedHandleHoverChange);
    };
  }, [debouncedHandleHoverChange]);

  // Preload images
  const preloadImages = () => {
    colors.forEach((color) => {
      const imgOn = new Image();
      const imgOff = new Image();
      imgOn.src = color.imageOn;
      imgOff.src = color.imageOff;
    });
  };

  // Call preloadImages function when your component mounts
  useEffect(() => {
    preloadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colors]);

  // console.log(selectedTextArea);
  // console.log(colors);
  return (
    <Row draggable="false">
      {colors.map((color, index) => {
        const isSelectedTextArea = textAreas.some(
          (textArea) => textArea.color === color.code
        );
        const isSelectedLetter = textAreas.every((textArea) =>
          textArea?.letters?.some((letter) => letter.color === color.code)
        );

        // Check if there are no textAreas
        const noTextAreas = textAreas.length === 0;

        return (
          <Col key={index} md={4} lg={12} xs={6} sm={6}>
            <div
              key={index}
              className={`border-2 border-[#AFE9E0] m-3 font-black text-center items-center text-3xl cursor-pointer rounded-2xl ${
                !noTextAreas && (isSelectedTextArea || isSelectedLetter)
                  ? "selected"
                  : "hover-effect"
              }`}
              style={{
                flex: "1 0 100px", // Set to 1 to allow flex items to grow and shrink, minimum width of 100px
                display: "flex",
                justifyContent: "center",
              }}
              onTouchStart={() => {
                textAreas.forEach((textArea) => {
                  if (textArea.id === selectedTextArea && textArea.isSplit) {
                    handleLetterColorSelection(color.code);
                  } else {
                    handleColorSelection(color.code);
                  }
                });
                handleHoverChange(index, true);
              }}
              onTouchEnd={() => {
                handleHoverChange(index, false);
              }}
              onMouseEnter={() => {
                let userPlatform = getOSInfo();
                if (
                  !(userPlatform === "iPhone" || userPlatform === "Android")
                ) {
                  handleHoverChange(index, true);
                }
              }}
              onMouseLeave={() => {
                let userPlatform = getOSInfo();
                if (
                  !(userPlatform === "iPhone" || userPlatform === "Android")
                ) {
                  handleHoverChange(index, false);
                }
              }}
              onClick={() => {
                // Check for each text area and its isSplit property
                textAreas.forEach((textArea) => {
                  if (textArea.id === selectedTextArea && textArea.isSplit) {
                    handleLetterColorSelection(color.code);
                  } else {
                    handleColorSelection(color.code);
                  }
                });
              }}
            >
              {hoveredStates[index] ? (
                <img
                  loading="lazy"
                  style={{
                    borderRadius: "14px",
                    width: "100%",
                    // height: "100%",
                  }}
                  draggable="false"
                  src={color?.imageOff}
                  alt={color?.name}
                />
              ) : (
                <img
                  loading="lazy"
                  style={{
                    borderRadius: "14px",
                    width: "100%",
                    // height: "100%",
                  }}
                  draggable="false"
                  src={color?.imageOn}
                  alt={color?.name}
                />
              )}
            </div>
          </Col>
        );
      })}
      {product.source === "LED" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <div
            onClick={() => setShowRGB(true)}
            style={{
              width: "100%",
              fontWeight: "600",
              fontFamily: "Beatrice",
              display: "flex",
              justifyContent: "center",
              margin: "12px",
            }}
            className={`button-style ${
              RGBColor ? "selected" : ""
            } heading hover-effect border-2 border-[#AFE9E0]  cursor-pointer rounded-3xl font-black text-center items-center text-xl`}
          >
            <img src={RGBIcon} alt="" />
          </div> */}
          <div
            onClick={() => {
              setShowCustom(!showCustom);
              if (showCustom === false) {
                setShowCustomPopup(true);
              }
            }}
            style={{
              padding: "0px",
            }}
            className={`button-style ${
              showCustom ? "selected" : ""
            } heading hover-effect border-2 border-[#AFE9E0]  cursor-pointer rounded-3xl font-black text-center items-center text-xl`}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "22px",
              }}
              src={CustomColors}
              alt=""
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </Row>
  );
};

export default ColorsList;
