import { Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CustomImage from "../../Assets/CustomColors.png";
import image from "../../Assets/DescriptionIcon.png";

import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import Info from "../../Assets/SuperCustom/Info";
import Frame from "../../Assets/SuperCustom/Frame.png";
import {
  addOtherColors,
  removeOtherColors,
  setProductColorDescription,
  setProductFont,
  setProductRefImageLink,
  setProductRefImageName,
  setProductRefImageSize,
} from "../../Redux/Slice";
import Draggable from "react-draggable";
// import Save from "../../Assets/SuperCustom/Save";
import ChooseColors from "../../Components/SuperCustom/SuperColor/ChooseColors";
import SplitColors from "../../Components/SuperCustom/SuperColor/SplitColors";
import { ReactSketchCanvas } from "react-sketch-canvas";
import CustomColor from "../../Components/SuperCustom/SuperColor/CustomColor";
import SuperBottomControls from "../../Components/SuperCustom/SuperBottomControls";
import Close from "../../Assets/Close";
import "./styles/SuperColor.css";
import "./styles/Shared.css";
import axios from "axios";
import ColorNavigation from "../../Components/SuperCustom/SuperColor/ColorNavigation";
import SplitNavigation from "../../Components/SuperCustom/SuperColor/SplitNavigation";
import ExampleModal from "../../Components/ExampleModal";
import DescriptionButton from "../../Components/DescriptionButton";
import ClearButton from "../../Components/ClearButton";
import HeadingInfo from "../../Components/SuperCustom/HeadingInfo";

const SuperColor = () => {
  const showExamples = useSelector((state) => state.activeStep.showExamples);
  const [selectedTextArea, setSelectedTextArea] = useState(null);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [showCustom, setShowCustom] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [show, setShow] = useState(true);
  const [hasCustomColors, setHasCustomColors] = useState(false);

  const [openColorsInfo, setOpenColorsInfo] = useState(false);
  const [openCustomInfo, setOpenCustomInfo] = useState(false);
  const [openSplitInfo, setOpenSplitInfo] = useState(false);

  const theme = useSelector((state) => state.activeStep.theme);
  const colorDesc = useSelector(
    (state) => state.activeStep.product.colorDescription
  );
  const desc = colorDesc ? colorDesc : "";

  const otherFonts = useSelector((state) => state.activeStep.otherFonts);
  const otherColors = useSelector((state) => state.activeStep.otherColors);
  const product = useSelector((state) => state.activeStep.product);
  const refImage = useSelector((state) => state.activeStep.product.refImage);
  const colors = useSelector((state) => state.activeStep.colors);

  const [selectedOption, setSelectedOption] = useState({
    label: `Image ${colors[0] + 1}`,
    imageSrc: colors[0].imageOn,
    value: colors[0].name,
  });
  const selectedCustomOption = {
    label: `Image ${colors[0] + 1}`,
    imageSrc: CustomImage,
    value: CustomImage,
  };

  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <span className="toggle-icon" />
      </components.DropdownIndicator>
    );
  };
  const [textAreas, setTextAreas] = useState(product?.fonts);
  const positions = useSelector((state) => state.activeStep.positions);

  const handleTextAreaChange = (id, event) => {
    const updatedTextAreas = textAreas.map((textarea) => {
      if (textarea.id === id) {
        // console.log(textarea.id);
        const value = event.target.value;
        return {
          ...textarea,
          value,
        };
      }
      return textarea;
    });
    // console.log(updatedTextAreas);
    setTextAreas(updatedTextAreas);
    dispatch(setProductFont(updatedTextAreas));
  };

  const [showColors, setShowColors] = useState(false);
  // const [showStrokeColors, setStrokeShowColors] = useState(false);
  const [showSplit, setShowSplit] = useState(false);
  const [warningOne, setWarningOne] = useState({});

  const dispatch = useDispatch();

  const fetchWarnings = async (type, number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}warning-pages/Super Color/${type}/${number}`
      );
      switch (number) {
        case 1:
          setWarningOne(response.data);
          break;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleColorClick = (color) => {
    if (otherColors?.includes(color)) {
      dispatch(removeOtherColors(color));
    } else {
      dispatch(addOtherColors(color));
    }
  };

  const handleColorSelection = (color) => {
    // console.log("Textarea change");

    if (selectedTextArea !== null) {
      // console.log("color");
      setTextAreas((prevState) => {
        const updatedTextAreas = prevState.map((textArea) => {
          // console.log(selectedTextArea);
          if (textArea.id === selectedTextArea) {
            // console.log(" matched");
            // console.log(color);
            document.documentElement.style.setProperty("--stroke-color", color);
            return { ...textArea, color: color };
          }
          return textArea;
        });
        dispatch(setProductFont(updatedTextAreas));
        return updatedTextAreas;
      });
    }
  };

  const handleSplitButtonClick = () => {
    // console.log(selectedTextArea);
    if (selectedTextArea !== null) {
      setTextAreas((prevTextAreas) => {
        const updatedTextAreas = prevTextAreas.map((textArea) => {
          if (textArea.id === selectedTextArea) {
            const letters = textArea.value.split("");
            const coloredLetters = letters.map((letter, index) => ({
              id: index,
              letter,
              color: textArea.color, // default color
            }));
            return {
              ...textArea,
              // color: "black",
              isSplit: true,
              letters: coloredLetters,
            };
          }
          return textArea;
        });
        // console.log(updatedTextAreas);
        dispatch(setProductFont(updatedTextAreas));
        return updatedTextAreas;
      });
      setShowSplit(true);
      setShowPopUp(true);
    }
  };
  const handleMergeButtonClick = () => {
    // console.log(textAreas);
    if (selectedTextArea !== null) {
      setTextAreas((prevTextAreas) => {
        const updatedTextAreas = prevTextAreas.map((textArea) => {
          if (textArea.id === selectedTextArea) {
            // Remove the letters array and set isSplit to false
            return {
              ...textArea,
              color: textArea?.color,
              isSplit: false,
              letters: undefined, // Remove the letters array
            };
          }
          return textArea;
        });
        dispatch(setProductFont(updatedTextAreas));
        return updatedTextAreas;
      });
      setShowSplit(false);
    }
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      arrow
      placement="bottom-start"
      {...props}
      style={{
        backgroundColor: "",
      }}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "14px",
      maxWidth: 200,
      backgroundColor: "#D9FF9E",
      color: "#03705E",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#D9FF9E",
    },
  });

  const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref}>
        <Info />
      </div>
    );
  });

  const savedDrawingData =
    JSON.parse(localStorage.getItem("savedDrawing")) || "";

  const disableCanvasRef = useRef(null); // Ref for the active drawing canvas

  useEffect(() => {
    // console.log(savedDrawingData);
    if (savedDrawingData.length && disableCanvasRef.current) {
      // console.log("fetching drawing");
      // console.log(savedDrawingData);
      disableCanvasRef.current.loadPaths(savedDrawingData);
    }
  });
  useEffect(() => {
    const textAreaColors = textAreas.map((textArea) => textArea.color);
    // console.log(textAreaColors);
    const customColorExists = textAreaColors.some(
      (color) => !colors.some((c) => c.code === color)
    );
    // console.log(customColorExists);
    setHasCustomColors(customColorExists);
  }, [textAreas, colors]);

  useEffect(() => {
    if (product.source === "Glass") {
      const updatedTextAreas = textAreas.map((textArea) => {
        // Check if the textArea's color exists in othercolors
        const foundColor = otherColors.find(
          (color) => color.code === textArea.color
        );

        // If the color is not found, set it to the default color
        if (!foundColor) {
          return { ...textArea, color: "#36CDB4" };
        }

        return textArea;
      });
      setTextAreas(updatedTextAreas);
      dispatch(setProductFont(updatedTextAreas));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherColors]);

  useEffect(() => {
    fetchWarnings(product.source, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row
        style={{
          border: "2px solid #AFE9E0",
          borderRadius: "24px",
          padding: "20px",
          marginBottom: "20px",
        }}
      >
        <Col lg={8} md={8} sm={12} xs={12}>
          <Typography
            style={{
              fontFamily: "Beatrice-Display",
              color: "#25CCB0",
              fontSize: "40px",
              fontWeight: "700",
              fontStyle: "italic",
              letterSpacing: "0.05em",
            }}
          >
            Pick Colors
          </Typography>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <div
            style={{
              height: "50px",
              display: warningOne?.warningStatus ? "" : "none",
              zIndex: 9,
              marginTop: window.innerWidth < 762 ? "50px" : "",
              marginBottom: window.innerWidth < 762 ? "50px" : "",
            }}
            className="warning"
          >
            <Typography className="warning-title">
              {warningOne?.title}
            </Typography>
            <div
              className="enable-listing-style warning-desc"
              dangerouslySetInnerHTML={{
                __html: warningOne?.warningContent,
              }}
            ></div>
          </div>
        </Col>
        <Row>
          <Typography
            style={{
              fontFamily: "Beatrice",
              color: "#25CCB0",
              textAlign: "justify",
              width: window.innerWidth > 768 ? "60%" : "100%",
            }}
          >
            Now it’s time to make your neon GLO! First, create your color
            palette by choosing GLO Color(s) below. Then, select the text and
            “apply” the color from your palette by clicking on it.
          </Typography>
        </Row>
        <Row
          style={{
            marginTop: "20px",
          }}
        >
          <Col xs={12} md={4} lg={4}>
            <div
              style={{
                margin: "10px",
              }}
            >
              <HeadingInfo
                open={openColorsInfo}
                setOpen={setOpenColorsInfo}
                heading={"Choose GLO Colors"}
                text={
                  "Choose any color(s) of your choice. If you are looking for a specific combination of colors please use the note function to share with us more information."
                }
              />
              <div
                style={{
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #AFE9E0",
                  borderRadius: "16px",
                  padding: "10px",
                  cursor: "pointer",
                }}
                className={
                  otherColors.length > 0 ? "selected" : " hover-effect"
                }
                onClick={() => setShowColors(true)}
              >
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "none",
                      boxShadow: "none",
                    }),
                    indicatorsContainer: (provided) => ({
                      ...provided,
                      border: "none",
                    }),
                  }}
                  isSearchable={false}
                  value={selectedOption}
                  options={[]}
                  noOptionsMessage={() => null}
                  getOptionLabel={(option) => (
                    <img
                      width="100px"
                      src={option.imageSrc}
                      alt={`Img ${option.label}`}
                    />
                  )}
                  getOptionValue={(option) => option.value}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: CustomDropdownIndicator, // CustomDropdownIndicator for the selector icon
                  }}
                />
              </div>
            </div>
          </Col>
          {product.source === "LED" ? (
            <>
              <Col xs={12} md={4} lg={4}>
                <div
                  style={{
                    margin: "10px",
                  }}
                >
                  <HeadingInfo
                    open={openCustomInfo}
                    setOpen={setOpenCustomInfo}
                    heading={"Custom Colors"}
                    optional={true}
                    text={
                      "You can choose Custom Colors as well but these colors are expensive."
                    }
                  />
                  <div
                    style={{
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "2px solid #AFE9E0",
                      borderRadius: "16px",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                    className={hasCustomColors ? "selected" : " hover-effect"}
                    onClick={() => setShowCustom(true)}
                  >
                    <Select
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "none",
                          boxShadow: "none",
                        }),
                        indicatorsContainer: (provided) => ({
                          ...provided,
                          border: "none",
                        }),
                      }}
                      isSearchable={false}
                      value={selectedCustomOption}
                      options={[]}
                      noOptionsMessage={() => null}
                      getOptionLabel={(option) => (
                        <img
                          width="100px"
                          src={option.imageSrc}
                          alt={`Img ${option.label}`}
                        />
                      )}
                      getOptionValue={(option) => option.value}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: CustomDropdownIndicator, // CustomDropdownIndicator for the selector icon
                      }}
                    />
                  </div>
                </div>
              </Col>
            </>
          ) : (
            <></>
          )}
          <Col xs={12} md={4} lg={4}>
            <div
              style={{
                margin: "10px",
              }}
            >
              <HeadingInfo
                open={openSplitInfo}
                setOpen={setOpenSplitInfo}
                heading={"Split into letters"}
                text={
                  "You can split your words into letters and apply different colors to each letter."
                }
              />

              <div
                style={{
                  height: "100px",
                  backgroundColor: "#F9F9F9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #AFE9E0",
                  borderRadius: "16px",
                  padding: "10px",
                  cursor: "pointer",
                }}
                className={
                  textAreas[0]?.isSplit || textAreas[1]?.isSplit
                    ? "selected"
                    : " hover-effect"
                }
                onClick={() => {
                  handleSplitButtonClick();
                }}
              >
                <Typography
                  className={` cursor-pointer rounded-3xl font-black text-center items-center`}
                  style={{
                    margin: "20px",
                    fontSize: "24px",
                    fontFamily: "Beatrice-Display",
                    fontStyle: "italic",
                  }}
                >
                  Split into letters
                </Typography>
              </div>
            </div>
          </Col>
        </Row>
      </Row>
      <Row
        style={{
          marginBottom: "20px",
        }}
      >
        <Col
          className="main-drawing-container"
          style={{
            backgroundColor: `${theme}`,

            border: "2px solid #AFE9E0",
            borderRadius: "24px",
            padding: "20px",
            // height: "80vh",
          }}
          lg={9}
          md={9}
          xm={12}
          xs={12}
        >
          <div
            className="preview-design-text"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: "40px",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Preview your Design
            </Typography>
          </div>
          <Row>
            <Col xs={12} sm={12} lg={11} md={12} className="tablet-padding">
              <div
                style={{
                  margin: "30px",
                  position: "relative",
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  overflowX: "scroll",
                }}
              >
                {otherFonts.length > 0 ||
                  refImage?.link ||
                  savedDrawingData.length > 0 ? (
                  <>
                    {refImage?.link || savedDrawingData.length > 0 ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <ReactSketchCanvas
                          ref={disableCanvasRef}
                          width="100%"
                          height="450px"
                          backgroundImage={refImage?.link}
                          preserveBackgroundImageAspectRatio="1"
                          strokeWidth={0}
                          eraserWidth={10}
                          style={{
                            border: "none",
                            backgroundColor: `${theme}`,
                          }}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {otherFonts.length > 0 ? (
                      <>
                        <>
                          {textAreas.map((textArea) => (
                            <Draggable
                              disabled
                              bounds="parent"
                              key={textArea.id}
                              defaultPosition={
                                positions[textArea?.id] || {
                                  x: 0,

                                  y: 0,
                                }
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  textShadow:
                                    textArea.fontFamily === "Dylan" ||
                                      textArea.fontFamily === "Spencer"
                                      ? ""
                                      : !textArea.isSplit
                                        ? `1px 2px 10px ${textArea.color}, 0 0 50px ${textArea.color}, 0 0 50px ${textArea.color}`
                                        : "",
                                }}
                              >
                                {textArea.isSplit ? (
                                  <>
                                    <div
                                      readOnly
                                      style={{
                                        display: "flex",
                                        overflow: "auto",
                                        maxWidth: "900px",
                                        outline: "none",
                                        fontSize: `${textArea.fontSize}px`,
                                        wordWrap: "break-word",
                                        textAlign: "center",
                                        whiteSpace: "pre-line",
                                        wordBreak: "break-word",
                                        overflowWrap: "break-word",
                                        resize: "none",
                                        zIndex: 1,
                                      }}
                                      onClick={() =>
                                        setSelectedTextArea(textArea.id)
                                      }
                                    >
                                      {textArea?.letters?.map(
                                        (letterObj, letterIndex) => (
                                          <div
                                            key={letterIndex}
                                            style={{
                                              minWidth: "20px",

                                              fontFamily: textArea.fontFamily,
                                              color: letterObj.color,

                                              borderRadius: "0px",
                                              border: "none",
                                              cursor: "pointer",
                                              background: "none",
                                              zIndex: 3,
                                            }}
                                          >
                                            {letterObj.letter}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      id={`textArea-${textArea.id}`}
                                      readOnly
                                      value={textArea.value}
                                      onChange={(event) =>
                                        handleTextAreaChange(textArea.id, event)
                                      }
                                      className={
                                        textArea.fontFamily === "Dylan" ||
                                          textArea.fontFamily === "Spencer"
                                          ? "Dylan-Spencer"
                                          : ""
                                      }
                                      style={{
                                        color: `${textArea.color}`,

                                        fontFamily: `${textArea.fontFamily}`,
                                        border:
                                          selectedTextArea === textArea.id
                                            ? "1px solid #4EBFFF"
                                            : "none",
                                        outline: "none",
                                        fontSize: `${textArea.fontSize}px`,
                                        wordWrap: "break-word",
                                        textAlign: "center",
                                        justifyContent: "center",
                                        whiteSpace: "pre-line",
                                        wordBreak: "break-word",
                                        overflowWrap: "break-word",
                                        resize: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        setSelectedTextArea(textArea.id)
                                      }
                                    >
                                      {textArea.value}
                                    </div>
                                  </>
                                )}
                              </div>
                            </Draggable>
                          ))}
                        </>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <img
                    style={{
                      width: "400px",
                      padding: "10px",
                    }}
                    src={Frame}
                    onError={(event) => (event.target.src = "")}
                    alt="noImage"
                  />
                )}
              </div>
            </Col>
            <Col lg={1} xs={0} sm={0} className="desktop-div">
              <DescriptionButton
                value={desc}
                setValue={setProductColorDescription}
              />
              <ClearButton />
            </Col>
            <div className="mobile-tablet-div">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DescriptionButton
                  value={desc}
                  setValue={setProductColorDescription}
                />
                <ClearButton />
              </div>
            </div>
          </Row>
          <SuperBottomControls />
        </Col>
        <Col lg={3} md={3} xm={12} xs={12} className="mobile-margin">
          <div
            style={{
              display: "flex",

              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontSize: "16px",
                fontFamily: "Beatrice",
                color: "#595959",
              }}
            >
              Uploaded Image
            </Typography>
          </div>
          {refImage.link ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  height: "100px",
                  border: "2px solid #AFE9E0",
                  borderRadius: "24px",
                }}
              >
                <img
                  //   width="50px"
                  style={{
                    width: "100px",
                    height: "100px",
                    padding: "10px",
                  }}
                  src={refImage.link}
                  onError={(event) => (event.target.src = "")}
                  alt="noImage"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Beatrice",
                      fontSize: "18px",
                      fontWeight: "600",
                      height: "50px",
                      overflowY: "auto",
                      overflowX: "hidden",
                      wordBreak: "break-all",
                    }}
                  >
                    {refImage?.name}
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Beatrice",
                      fontSize: "12px",
                      color: "#03705E",
                    }}
                  >
                    {(refImage?.size / 1024).toFixed(2)} KB
                  </Typography>
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(setProductRefImageLink(null));
                    dispatch(setProductRefImageName(null));
                    dispatch(setProductRefImageSize(null));
                  }}
                >
                  <Close />
                </div>
              </div>
            </>
          ) : (
            <>
              <Typography
                style={{
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #AFE9E0",
                  borderRadius: "16px",
                  padding: "10px",
                }}
              >
                No image Uploaded
              </Typography>
            </>
          )}

          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontSize: "16px",
                fontFamily: "Beatrice",
                color: "#595959",
              }}
            >
              Selected font(s)
            </Typography>
          </div>
          {otherFonts.length > 0 ? (
            <>
              {otherFonts.map((font) => (
                <Card
                  key={font.id}
                  style={{
                    margin: "5px",
                    // height: "100px",
                    width: "98%",
                    borderRadius: "16px",
                    border: "2px solid #AFE9E0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      margin: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <img draggable="false" src={image} alt="" />
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "10px",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography>
                        {font.name}
                        <CustomWidthTooltip
                          title={
                            "Remove the fonts by pressing the minus button."
                          }
                        >
                          <MyComponent
                            style={{
                              marginLeft: "10px",
                              display: "inline-block",
                            }}
                          />
                        </CustomWidthTooltip>
                      </Typography>
                    </div>
                  </div>
                </Card>
              ))}
            </>
          ) : (
            <>
              <Typography
                style={{
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #AFE9E0",
                  borderRadius: "16px",
                  padding: "10px",
                }}
              >
                No Font Selected
              </Typography>
            </>
          )}
          <div
            style={{
              marginTop: "20px",
              display: "flex",

              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontSize: "16px",
                fontFamily: "Beatrice",
                color: "#595959",
              }}
            >
              Selected color(s)
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                fontFamily: "Beatrice",
                color: "#03705E",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setShowColors(true)}
            >
              Add More
            </Typography>
          </div>
          {otherColors.length > 0 ? (
            <div
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                height: "58vh",
              }}
            >
              {otherColors.map((color) => (
                <Card
                  key={color.id}
                  style={{
                    margin: "5px",
                    height: "100px",
                    width: "98%",
                    borderRadius: "16px",
                    border: "2px solid #AFE9E0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      margin: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      draggable="false"
                      style={{
                        width: "150px",
                        height: "80px",
                      }}
                      src={color.imageOn}
                      onClick={() => {
                        handleColorSelection(color.code);
                      }}
                      alt=""
                    />
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "10px",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <Typography>
                          {color.name}
                          <CustomWidthTooltip
                            title={
                              "Remove the colors by pressing the minus button."
                            }
                          >
                            <MyComponent
                              style={{
                                marginLeft: "10px",
                                display: "inline-block",
                              }}
                            />
                          </CustomWidthTooltip>
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Beatrice",
                            fontSize: "10px",
                            color: "#03705E",
                          }}
                        >
                          {color.code}
                        </Typography>
                      </div>
                      <div
                        style={{
                          // height: "20px",
                          // width: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleColorClick(color)}
                      >
                        <Close />
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <>
              <Typography
                style={{
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #AFE9E0",
                  borderRadius: "16px",
                  padding: "10px",
                }}
              >
                No Color Selected
              </Typography>
            </>
          )}
        </Col>
      </Row>

      <ChooseColors
        setSelectedOption={setSelectedOption}
        show={showColors}
        close={() => setShowColors(false)}
      />
      <SplitColors
        textAreas={textAreas}
        setTextAreas={setTextAreas}
        selectedLetter={selectedLetter}
        handleMergeButtonClick={handleMergeButtonClick}
        setSelectedLetter={setSelectedLetter}
        selectedTextArea={selectedTextArea}
        show={showSplit}
        close={() => setShowSplit(false)}
      />
      <CustomColor
        selectedTextArea={selectedTextArea}
        textAreas={textAreas}
        show={showCustom}
        close={() => setShowCustom(false)}
        handleColorSelection={handleColorSelection}
      />
      <ColorNavigation show={show} close={setShow} />
      <SplitNavigation show={showPopUp} close={setShowPopUp} />
      {showExamples && <ExampleModal />}
    </>
  );
};

export default SuperColor;
