import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Close from "../../Assets/Close";
import WhatsApp from "../../Assets/WhatsApp";
import Facebook from "../../Assets/Facebook";
import Instagram from "../../Assets/Instagram";
import { BiCopy } from "react-icons/bi";

const style = {
  position: "absolute",
  outline: "none",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 1500,
  // height: "95vh",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const handleClick = () => {
  var copyText = document.getElementById("copy");

  // Select the text field
  copyText.select();
  copyText.setSelectionRange(0, 99999); // For mobile devices

  // Copy the text inside the text field
  navigator.clipboard.writeText(copyText.value);
};

const Share = ({ link, showShare, closeShare }) => {
  return (
    <Modal
      open={showShare}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: "40px",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Share your design!
            </Typography>
          </div>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={closeShare}
          >
            <Close />
          </div>
        </div>
        <div
          style={
            {
              // height: "250px",
              // overflow: "scroll",
              // paddingLeft: "30px",
              // paddingRight: "30px",
            }
          }
        >
          <Row
            style={{
              marginBottom: "10px",
            }}
          >
            <Col xs={12}>
              <label
                style={{
                  marginBottom: "10px",
                }}
              >
                Copy Link
              </label>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  id="copy"
                  readOnly
                  style={{
                    border: "2px solid #AFE9E0",
                    outline: "none",
                    width: "100%",
                    backgroundColor: "#F9F9F9",
                    borderRadius: "8px",
                    padding: "10px",
                  }}
                  value={link}
                  // name="address"
                  // placeholder="URL here"
                />
                <div
                  style={{
                    margin: "5px",
                    cursor: "pointer",
                    backgroundColor: "#36cdb4",
                    padding: "10px",
                    border: "2px solid #36cdb4",
                    borderRadius: "24px",
                  }}
                  onClick={handleClick}
                >
                  <BiCopy
                    size={20}
                    style={{
                      color: "white",
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              // marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <a
                rel="noreferrer"
                href="https://web.whatsapp.com/"
                target="_blank"
              >
                <WhatsApp />
              </a>
              <a
                rel="noreferrer"
                href="https://www.facebook.com/sharer/sharer.php?u=example.org"
                target="_blank"
              >
                <Facebook />
              </a>
              <a
                rel="noreferrer"
                href="https://www.instagram.com/"
                target="_blank"
              >
                <Instagram />
              </a>
            </div>
          </Row>
        </div>
      </Box>
    </Modal>
  );
};

export default Share;
