import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import Close from "../../Assets/Close";

const SaveSuccess = ({ show, close }) => {
  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 768 ? "30%" : "80%",
    // height: "95vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };
  return (
    <Modal
      open={show}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: window.innerWidth > 768 ? "40px" : "2rem",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Design saved
            </Typography>
          </div>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={close}
          >
            <Close />
          </div>
        </div>
        <Typography
          style={{
            color: "#03705E",
            fontFamily: "Beatrice",
            fontWeight: "400",
            fontSize: window.innerWidth > 768 ? "1rem" : "0.75rem",
          }}
        >
          You have successfully saved your design! An email of your custom
          creation has been sent to you. Feel free to tinker and ponder until
          you&apos;re ready to proceed :)
        </Typography>
      </Box>
    </Modal>
  );
};

export default SaveSuccess;
