import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBackingColors,
  setCustomBacking,
  setProductBackingColor,
} from "../../Redux/Slice";
import { Col, Row } from "react-bootstrap";

import { Typography } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import { getOSInfo } from "../../utils/client/calculations";
import { debounce } from "../../utils/client/helpers";

const BackingColorList = ({ setBG, setCustom, custom }) => {
  const backingColors = useSelector((state) => state.activeStep.backingColors);
  const product = useSelector((state) => state.activeStep.product);
  const [hoveredStates, setHoveredStates] = useState([]); // Initialize an array of hover states
  // const product1 = useSelector((state) => state.activeStep);
  // console.log(product1.product.backing)

  const handleHoverChange = (index, isHovered) => {
    const newHoveredStates = Array(hoveredStates.length).fill(false);

    // Set the specified index to the provided isHovered value
    newHoveredStates[index] = isHovered;

    // Update the state
    setHoveredStates(newHoveredStates);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (product.backing.name === "wood") {
      dispatch(
        fetchBackingColors(`name/${product.source}/${product.backing.name}`)
      );
    } else {
      dispatch(fetchBackingColors(`name/${product.source}/acrylic`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedHandleHoverChange = debounce(handleHoverChange, 300);

  useEffect(() => {
    // Attach debounced function to scroll event
    window.addEventListener("scroll", debouncedHandleHoverChange);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", debouncedHandleHoverChange);
    };
  }, [debouncedHandleHoverChange]);
  return (
    <>
      {backingColors.length > 0 ? (
        <>
          <Row draggable="false">
            {backingColors.map((backingColor, index) => {
              const isSelectedBackingColor =
                product.backing.color === backingColor.code;
              return (
                <Col key={index} md={4} lg={12} xs={6} sm={6}>
                  <div
                    className={`border-2 border-[#AFE9E0] m-3 font-black text-center items-center text-3xl cursor-pointer rounded-2xl ${
                      isSelectedBackingColor ? "selected" : "hover-effect"
                    }`}
                  >
                    <div
                      style={{
                        borderRadius: "16px",
                        // border: "2px solid #AFE9E0",
                        width: "100%",
                        position: "relative",
                        //   width: "100%" /* Adjust as needed */,
                        //   height: "100%" /* Adjust as needed */,
                        overflow: "hidden",
                      }}
                      onTouchStart={() => {
                        document.documentElement.style.setProperty(
                          "--custom-background-color",
                          backingColor.code
                        );
                        setBG(backingColor.code);
                        setCustom(false);
                        dispatch(setProductBackingColor(backingColor?.code));
                        dispatch(setCustomBacking(false));
                        handleHoverChange(index, true);
                      }}
                      onTouchEnd={() => {
                        handleHoverChange(index, false);
                      }}
                      onMouseEnter={() => {
                        let userPlatform = getOSInfo();
                        if (
                          userPlatform === "iPhone" ||
                          userPlatform === "Android"
                        ) {
                          // Handle iPhone-specific logic here
                        } else {
                          handleHoverChange(index, true);
                        }
                      }}
                      onMouseLeave={() => {
                        let userPlatform = getOSInfo();
                        if (
                          userPlatform === "iPhone" ||
                          userPlatform === "Android"
                        ) {
                          // Handle iPhone-specific logic here
                        } else {
                          handleHoverChange(index, false);
                        }
                      }}
                      onClick={() => {
                        document.documentElement.style.setProperty(
                          "--custom-background-color",
                          backingColor.code
                        );
                        setBG(backingColor.code);
                        setCustom(false);
                        dispatch(setProductBackingColor(backingColor?.code));
                        dispatch(setCustomBacking(false));
                      }}
                    >
                      <img
                        loading="lazy"
                        style={{
                          opacity: hoveredStates[index] ? 0 : 1,
                          width: "100%" /* Adjust as needed */,
                          height: "100%" /* Adjust as needed */,
                          transition: "opacity 0.3s ease",
                          borderRadius: "16px",
                        }}
                        draggable="false"
                        src={backingColor?.image}
                        alt={backingColor?.name}
                      />
                      {hoveredStates[index] && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",

                            transition: "opacity 0.3s ease",
                          }}
                        >
                          <Typography style={{ fontWeight: "bold" }}>
                            {backingColor.name}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              );
            })}
            <div className=" mt-3 cursor-pointer rounded-2xl font-black text-center flex justify-center text-3xl">
              <Typography
                onClick={() => {
                  setBG("white");
                  setCustom(true);
                  dispatch(setProductBackingColor("white"));
                  dispatch(setCustomBacking(true));
                }}
                style={{
                  // width: "100%",
                  fontWeight: "600",
                  fontFamily: "Beatrice",
                  display: "flex",
                  padding: "10px",
                }}
                className={`button-style ${
                  custom ? "selected" : ""
                } heading hover-effect border-2 border-[#AFE9E0]  cursor-pointer rounded-3xl font-black text-center items-center text-xl`}
              >
                Custom Colors
              </Typography>
            </div>
          </Row>
        </>
      ) : (
        <>
          <ThreeDots
            // height="100%"
            width="100%"
            radius="9"
            color="#36CDB4"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </>
      )}
    </>
  );
};

export default BackingColorList;
