import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../../styles/ShippingInfo.css";
import { setProductDeliveryDetails } from "../../../Redux/Slice";
import { useDispatch, useSelector } from "react-redux";

const ShippingInfo = ({ errors }) => {
  const [states, setStates] = useState(null);
  const details = useSelector(
    (state) => state.activeStep.product.delivery.details
  );

  const dispatch = useDispatch();

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    const selectedCountryStates = getStatesForCountry(selectedCountry);
    setStates(selectedCountryStates);
  };

  const getStatesForCountry = (country) => {
    switch (country) {
      case "US":
        return [
          { name: "Alabama", code: "AL" },
          { name: "Alaska", code: "AK" },
          { name: "Arizona", code: "AZ" },
          { name: "Arkansas", code: "AR" },
          { name: "California", code: "CA" },
          { name: "Colorado", code: "CO" },
          { name: "Connecticut", code: "CT" },
          { name: "Delaware", code: "DE" },
          { name: "Florida", code: "FL" },
          { name: "Georgia", code: "GA" },
          { name: "Hawaii", code: "HI" },
          { name: "Idaho", code: "ID" },
          { name: "Illinois", code: "IL" },
          { name: "Indiana", code: "IN" },
          { name: "Iowa", code: "IA" },
          { name: "Kansas", code: "KS" },
          { name: "Kentucky", code: "KY" },
          { name: "Louisiana", code: "LA" },
          { name: "Maine", code: "ME" },
          { name: "Maryland", code: "MD" },
          { name: "Massachusetts", code: "MA" },
          { name: "Michigan", code: "MI" },
          { name: "Minnesota", code: "MN" },
          { name: "Mississippi", code: "MS" },
          { name: "Missouri", code: "MO" },
          { name: "Montana", code: "MT" },
          { name: "Nebraska", code: "NE" },
          { name: "Nevada", code: "NV" },
          { name: "New Hampshire", code: "NH" },
          { name: "New Jersey", code: "NJ" },
          { name: "New Mexico", code: "NM" },
          { name: "New York", code: "NY" },
          { name: "North Carolina", code: "NC" },
          { name: "North Dakota", code: "ND" },
          { name: "Ohio", code: "OH" },
          { name: "Oklahoma", code: "OK" },
          { name: "Oregon", code: "OR" },
          { name: "Pennsylvania", code: "PA" },
          { name: "Rhode Island", code: "RI" },
          { name: "South Carolina", code: "SC" },
          { name: "South Dakota", code: "SD" },
          { name: "Tennessee", code: "TN" },
          { name: "Texas", code: "TX" },
          { name: "Utah", code: "UT" },
          { name: "Vermont", code: "VT" },
          { name: "Virginia", code: "VA" },
          { name: "Washington", code: "WA" },
          { name: "West Virginia", code: "WV" },
          { name: "Wisconsin", code: "WI" },
          { name: "Wyoming", code: "WY" },
        ];

      case "UK":
        return ["England", "Scotland", "Wales"];
      case "CAN":
        return ["Ontario", "Quebec", "British Columbia"];
      default:
        return [];
    }
  };
  useEffect(() => {
    if (details?.country) {
      const selectedCountryStates = getStatesForCountry(details?.country);
      setStates(selectedCountryStates);
    }
  }, [details]);

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "#000",
              marginTop: window.innerWidth > 768 ? "40px" : "",
              fontFamily: "Beatrice",
              marginBottom: "20px",
            }}
          >
            Tell us where your piece is shipping to:
          </Typography>
        </div>
        <Row style={{ marginBottom: "20px" }}>
          <Col xs={12}>
            <label style={{ marginBottom: "5px", color: "#8F8F8F" }}>
              Enter your shipping address here
            </label>
            <br />
            <input
              style={{
                border: "2px solid #AFE9E0",
                outline: "none",
                width: "100%",
                backgroundColor: "#F9F9F9",
                borderRadius: "8px",
                padding: "10px",
              }}
              name="shippingAddress"
              value={details?.address}
              onChange={(e) => {
                dispatch(
                  setProductDeliveryDetails({
                    ...details,
                    address: e.target.value,
                  })
                );
              }}
              placeholder="Please enter your address here"
            />
            {errors.address && (
              <span className="text-red-500 text-sm mt-1">
                {errors.address}
              </span>
            )}
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col xs={12} sm={12} md={6} lg={6} style={{ marginBottom: "10px" }}>
            <label style={{ marginBottom: "5px", color: "#8F8F8F" }}>
              City
            </label>
            <br />
            <input
              style={{
                border: "2px solid #AFE9E0",
                outline: "none",
                width: "100%",
                backgroundColor: "#F9F9F9",
                borderRadius: "8px",
                padding: "10px",
              }}
              name="shippingCity"
              value={details?.city}
              onChange={(e) => {
                dispatch(
                  setProductDeliveryDetails({
                    ...details,
                    city: e.target.value,
                  })
                );
              }}
              placeholder="Please enter your city"
            />
            {errors.city && (
              <span className="text-red-500 text-sm mt-1">{errors.city}</span>
            )}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <label style={{ marginBottom: "5px", color: "#8F8F8F" }}>Zip</label>
            <br />
            <input
              type="number"
              style={{
                border: "2px solid #AFE9E0",
                outline: "none",
                width: "100%",
                backgroundColor: "#F9F9F9",
                borderRadius: "8px",
                padding: "10px",
              }}
              name="shippingZip"
              value={details?.zip}
              onChange={(e) => {
                dispatch(
                  setProductDeliveryDetails({
                    ...details,
                    zip: e.target.value,
                  })
                );
              }}
              placeholder="Enter zip"
            />
            {errors.zip && (
              <span className="text-red-500 text-sm mt-1">{errors.zip}</span>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} style={{ marginBottom: "10px" }}>
            <label style={{ marginBottom: "5px", color: "#8F8F8F" }}>
              Country
            </label>
            <br />
            <select
              type="country"
              id="country"
              style={{
                border: "2px solid #AFE9E0",
                outline: "none",
                color: details?.country ? "#000000" : "#8F8F8F",
                width: "100%",
                backgroundColor: "#F9F9F9",
                borderRadius: "8px",
                padding: "10px",
              }}
              name="shippingCountry"
              value={details?.country}
              onChange={(e) => {
                handleCountryChange(e);
                dispatch(
                  setProductDeliveryDetails({
                    ...details,
                    country: e.target.value,
                  })
                );
              }}
            >
              <option value="">-- Select Country</option>

              <option value="US">United States</option>

              {/* <option value="UK">United Kingdom</option> */}

              {/* <option value="CAN">Canada</option> */}
            </select>
            {errors.country && (
              <span className="text-red-500 text-sm mt-1">
                {errors.country}
              </span>
            )}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <label style={{ marginBottom: "5px", color: "#8F8F8F" }}>
              State
            </label>
            <br />
            <select
              style={{
                border: "2px solid #AFE9E0",
                outline: "none",
                width: "100%",
                color: details?.state ? "#000000" : "#8F8F8F",
                backgroundColor: "#F9F9F9",
                borderRadius: "8px",
                padding: "10px",
              }}
              name="shippingState"
              value={details?.state}
              onChange={(e) => {
                dispatch(
                  setProductDeliveryDetails({
                    ...details,
                    state: e.target.value,
                  })
                );
              }}
            >
              <option value="">-- Select State</option>

              {states?.map((state) => (
                <option key={state.code} value={state.code}>
                  {state.name}
                </option>
              ))}
            </select>
            {errors.state && (
              <span className="text-red-500 text-sm mt-1">{errors.state}</span>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ShippingInfo;
