import React from "react";

const TransformerBox = ({ color }) => {
  return (
    <svg
      draggable="true"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="182"
      viewBox="0 0 21 182"
      fill="none"
    >
      <g filter="url(#filter0_i_1768_48826)">
        <path
          d="M3.10484 180.966C3.19323 174.305 6.64615 167.782 9.04855 161.223C10.2463 157.954 11.1849 154.66 11.2691 151.349C11.3543 148.042 10.5411 144.739 9.41883 141.462C7.40393 135.578 4.36526 129.736 3.5478 123.798C2.72921 117.859 4.92703 112.003 7.29726 106.166C9.6174 100.451 11.9658 94.7052 11.4252 88.8875C10.8727 82.9472 7.90733 77.0971 5.78981 71.2242C4.73496 68.2959 3.87858 65.3515 3.64454 62.3948C3.41047 59.4453 3.90012 56.5013 4.76735 53.5762C6.5144 47.6804 9.69169 41.8674 11.0837 35.9524C12.4792 30.0186 11.0808 24.1796 8.8167 18.3003C6.55933 12.4404 3.85103 6.58128 3.81934 0.638672C4.32663 0.64035 4.83504 0.642031 5.34234 0.643709C5.75493 0.645074 6.16866 0.646442 6.58125 0.647807C6.88676 0.648817 7.19226 0.649827 7.49776 0.650838C7.83257 0.651945 8.16626 0.653049 8.50107 0.654156C8.53276 6.59676 11.2411 12.4559 13.4984 18.3153C15.7625 24.1951 17.162 30.0336 15.7654 35.9675C14.3734 41.8828 11.1961 47.6955 9.44908 53.5912C8.58185 56.5168 8.0922 59.4603 8.32627 62.4098C8.56031 65.3665 9.41669 68.3109 10.4715 71.2392C12.5879 77.1122 15.5544 82.9627 16.107 88.9025C16.6487 94.7198 14.3003 100.466 11.979 106.181C9.60877 112.018 7.41095 117.874 8.22953 123.813C9.04812 129.751 12.0857 135.594 14.1006 141.477C15.2228 144.754 16.036 148.056 15.9508 151.364C15.8655 154.675 14.9281 157.969 13.7303 161.238C11.3279 167.797 7.87497 174.32 7.78657 180.981C7.45176 180.98 7.11807 180.979 6.78326 180.978C6.47776 180.977 6.17226 180.976 5.86676 180.975C5.45416 180.973 5.04043 180.972 4.62784 180.971C4.12054 180.969 3.61213 180.968 3.10484 180.966Z"
          fill={color}
        />
      </g>
      <rect
        x="0.5"
        y="114.5"
        width="19.7852"
        height="44.0552"
        fill={color}
        stroke="black"
      />
      <defs>
        <filter
          id="filter0_i_1768_48826"
          x="3.10449"
          y="0.638672"
          width="13.2188"
          height="184.342"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1768_48826"
          />
        </filter>
      </defs>
    </svg>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="22"
    //   height="218"
    //   viewBox="0 0 22 218"
    //   fill="none"
    // >
    //   <g opacity="0.8">
    //     <g filter="url(#filter0_d_1574_48386)">
    //       <path
    //         d="M10.7773 112.305C10.7773 143.42 10.7773 174.486 10.7773 205.544"
    //         stroke={color}
    //         stroke-width="3"
    //         stroke-linecap="round"
    //       />
    //     </g>
    //     <g filter="url(#filter1_d_1574_48386)">
    //       <path
    //         d="M10.7773 1.54297C10.7773 23.4697 10.7773 45.3619 10.7773 67.2485"
    //         stroke={color}
    //         stroke-width="3"
    //         stroke-linecap="round"
    //       />
    //     </g>
    //     <rect
    //       x="0.884766"
    //       y="67.75"
    //       width="19.7852"
    //       height="44.0552"
    //       fill={color}
    //       stroke="black"
    //     />
    //   </g>
    //   <defs>
    //     <filter
    //       id="filter0_d_1574_48386"
    //       x="4.27734"
    //       y="110.805"
    //       width="13"
    //       height="106.238"
    //       filterUnits="userSpaceOnUse"
    //       color-interpolation-filters="sRGB"
    //     >
    //       <feFlood flood-opacity="0" result="BackgroundImageFix" />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset dy="5" />
    //       <feGaussianBlur stdDeviation="2.5" />
    //       <feComposite in2="hardAlpha" operator="out" />
    //       <feColorMatrix
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in2="BackgroundImageFix"
    //         result="effect1_dropShadow_1574_48386"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in="SourceGraphic"
    //         in2="effect1_dropShadow_1574_48386"
    //         result="shape"
    //       />
    //     </filter>
    //     <filter
    //       id="filter1_d_1574_48386"
    //       x="4.27734"
    //       y="0.0429688"
    //       width="13"
    //       height="78.707"
    //       filterUnits="userSpaceOnUse"
    //       color-interpolation-filters="sRGB"
    //     >
    //       <feFlood flood-opacity="0" result="BackgroundImageFix" />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset dy="5" />
    //       <feGaussianBlur stdDeviation="2.5" />
    //       <feComposite in2="hardAlpha" operator="out" />
    //       <feColorMatrix
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in2="BackgroundImageFix"
    //         result="effect1_dropShadow_1574_48386"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in="SourceGraphic"
    //         in2="effect1_dropShadow_1574_48386"
    //         result="shape"
    //       />
    //     </filter>
    //   </defs>
    // </svg>
  );
};

export default TransformerBox;
