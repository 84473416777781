import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { addDays } from "date-fns";
import { setProductDeliveryDetails } from "../../../Redux/Slice";

const SuperMessenger = () => {
  const details = useSelector(
    (state) => state.activeStep.product.delivery.details
  );
  const [needDate, setNeedDate] = useState(
    details?.needDate ? details?.needDate : null
  );
  const [states, setStates] = useState(null);

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    const selectedCountryStates = getStatesForCountry(selectedCountry);
    setStates(selectedCountryStates);
  };

  const dispatch = useDispatch();

  const getStatesForCountry = (country) => {
    switch (country) {
      case "US":
        return ["New York", "California", "Texas"];
      case "UK":
        return ["England", "Scotland", "Wales"];
      case "CAN":
        return ["Ontario", "Quebec", "British Columbia"];
      default:
        return [];
    }
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date().getDate();
  const year = new Date().getFullYear();
  const month = monthNames[new Date().getMonth()];
  useEffect(() => {
    if (details?.country) {
      const selectedCountryStates = getStatesForCountry(details?.country);
      setStates(selectedCountryStates);
    }
  }, [details]);
  return (
    <>
      <div
        className="shipping-bg"
        style={{
          display: "flex",
          height: "280px",
          flexDirection: "column",
          textAlign: "center",
          color: "#07AB90",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Typography
            style={{
              fontSize: "21px",
              fontFamily: "Beatrice",
              width: "500px",
            }}
          >
            Order today with the{" "}
            <Typography
              style={{
                display: "inline-block",
                fontSize: "21px",
                fontFamily: "Beatrice",
                color: "#BF6BE3",
              }}
            >
              “NYC Messenger”{" "}
            </Typography>
            method, and your estimated delivery window will be:
          </Typography>
        </div>
        <Typography
          style={{
            fontSize: "52px",
            fontFamily: "Beatrice",
          }}
        >
          {month} {date} - {month} {date + 10}, {year} + Messenger time
        </Typography>
      </div>
      <div className="messenger-main">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Beatrice",
          }}
        >
          If you’re in NYC we can deliver by messenger:
        </Typography>
        <Typography
          style={{
            fontSize: "12px",
            fontFamily: "Beatrice",
            color: "#595959",
          }}
        >
          If you&apos;re located in the NYC area, NYC Messenger is the most cost
          effective option. Swing by our studio to grab your piece!
        </Typography>
      </div>
      <div
        style={{
          marginTop: "20px",
        }}
      >
        <Row
          style={{
            marginBottom: "20px",
          }}
        >
          <Col xs={12} sm={6}>
            <label
              style={{
                marginBottom: "15px",
                marginTop: "15px",
                color: "#8F8F8F",
              }}
            >
              Enter your address here
            </label>
            <br />
            <input
              style={{
                border: "2px solid #AFE9E0",
                outline: "none",
                width: "100%",
                backgroundColor: "#F9F9F9",
                borderRadius: "8px",
                padding: "10px",
              }}
              name="billingAddress"
              value={details?.address}
              onChange={(e) => {
                dispatch(
                  setProductDeliveryDetails({
                    ...details,
                    address: e.target.value,
                  })
                );
              }}
              placeholder="Please enter your address here"
            />
          </Col>
          <Col xs={12} sm={6} style={{ marginTop: "15px" }}>
            <label
              style={{
                marginBottom: "5px",
                color: "#8F8F8F",
              }}
            >
              Need it by
            </label>
            <br />
            <ReactDatePicker
              className="date-picker"
              selected={needDate}
              minDate={addDays(new Date(), 14)}
              onChange={(date) => {
                setNeedDate(date);
                dispatch(
                  setProductDeliveryDetails({
                    ...details,
                    needDate: date,
                  })
                );
              }}
              placeholderText="-- Please select"
            />
          </Col>
        </Row>
        <Row
          style={{
            marginBottom: "20px",
          }}
        >
          <Col xs={12} sm={3} style={{ marginBottom: "15px" }}>
            <label
              style={{
                marginBottom: "5px",
                color: "#8F8F8F",
              }}
            >
              City
            </label>
            <br />
            <input
              style={{
                border: "2px solid #AFE9E0",
                outline: "none",
                width: "100%",
                backgroundColor: "#F9F9F9",
                borderRadius: "8px",
                padding: "10px",
              }}
              name="billingCity"
              value={details.city}
              onChange={(e) => {
                dispatch(
                  setProductDeliveryDetails({
                    ...details,
                    city: e.target.value,
                  })
                );
              }}
              placeholder="Please enter city"
            />
          </Col>
          <Col xs={12} sm={3} style={{ marginBottom: "15px" }}>
            <label
              style={{
                marginBottom: "5px",
                color: "#8F8F8F",
              }}
            >
              Zip
            </label>
            <br />
            <input
              style={{
                border: "2px solid #AFE9E0",
                outline: "none",
                width: "100%",
                backgroundColor: "#F9F9F9",
                borderRadius: "8px",
                padding: "10px",
              }}
              name="billingZip"
              value={details?.zip}
              onChange={(e) => {
                dispatch(
                  setProductDeliveryDetails({
                    ...details,
                    zip: e.target.value,
                  })
                );
              }}
              placeholder="Enter zip"
            />
          </Col>
          <Col xs={12} sm={3} style={{ marginBottom: "15px" }}>
            <label
              style={{
                marginBottom: "5px",
                color: "#8F8F8F",
              }}
            >
              Country
            </label>
            <br />
            <select
              type="country"
              id="country"
              style={{
                height: "48px",
                border: "2px solid #AFE9E0",
                color: details.country ? "#000000" : "#8F8F8F",
                outline: "none",
                width: "100%",
                backgroundColor: "#F9F9F9",
                borderRadius: "8px",
                padding: "10px",
              }}
              name="billingCountry"
              value={details?.country}
              onChange={(e) => {
                handleCountryChange(e);
                dispatch(
                  setProductDeliveryDetails({
                    ...details,
                    country: e.target.value,
                  })
                );
              }}
            >
              <option value="">-- Select Country</option>
              <option value="US">United States</option>
              <option value="UK">United Kingdom</option>
              <option value="CAN">Canada</option>
            </select>
          </Col>
          <Col xs={12} sm={3} style={{ marginBottom: "15px" }}>
            <label
              style={{
                marginBottom: "5px",
                color: "#8F8F8F",
              }}
            >
              State
            </label>
            <br />
            <select
              style={{
                height: "48px",
                border: "2px solid #AFE9E0",
                color: details.state ? "#000000" : "#8F8F8F",
                outline: "none",
                width: "100%",
                backgroundColor: "#F9F9F9",
                borderRadius: "8px",
                padding: "10px",
              }}
              name="billingState"
              value={details?.state}
              onChange={(e) => {
                dispatch(
                  setProductDeliveryDetails({
                    ...details,
                    state: e.target.value,
                  })
                );
              }}
            >
              <option value="">-- Select State</option>
              {states?.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SuperMessenger;
