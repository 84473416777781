import { Typography } from "@mui/material";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import EditModal from "./EditModal";

const SelectedCard = ({ heading, selection }) => {
  const [fontFamily, setFontFamily] = useState(selection ? selection[0] : "");

  const [show, setShow] = useState(false);

  return (
    <div
      style={{
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Beatrice",
            color: "#000000",
            marginBottom: "5px",
          }}
        >
          {heading}
        </Typography>

      </div>
      <Card
        style={{
          height: "100px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "2px solid #AFE9E0",
          borderRadius: "16px",
        }}
      >
        <Typography
          style={{
            margin: "20px",
            fontSize: "32px",
            fontWeight: "400",
            fontFamily: "Beatrice",
            color: "#000000",
          }}
        >
          {Array.isArray(selection) ? (
            <>
              {selection.length > 0 ? (
                  <span className="select-toggle">
                    <select
                      value={fontFamily}
                      className="toggle-input toggle-input-user"
                      style={{
                        backgroundColor: "",
                        fontFamily: fontFamily,
                        outline: "none",
                      }}
                      onChange={(e) => setFontFamily(e.target.value)}
                    >
                      {selection.map((select, index) => (
                        <option key={index} value={select}>
                          {select}
                        </option>
                      ))}
                    </select>
                    <span className="toggle-icon"></span>
                  </span>
              ) : (
                <>N/A</>
              )}
            </>
          ) : (
            <>{selection ? selection : "N/A"}</>
          )}
        </Typography>
      </Card>
      <EditModal edit="" show={show} close={() => setShow(false)} />
    </div>
  );
};

export default SelectedCard;
