import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import RushDetails from "../SuperCustom/SuperDelivery/SuperRush/RushDetails";
import ShippingAddress from "../SuperCustom/SuperDelivery/SuperRush/ShippingAddress";
import RushPopUp from "../SuperCustom/SuperDelivery/SuperRush/RushPopup";
import axios from "axios";
import { useSelector } from "react-redux";

const Rush = ({ setSelected, warningTwo }) => {
  const product = useSelector((state) => state.activeStep.product);
  const details = useSelector(
    (state) => state.activeStep.product.delivery.details
  );
  const [dateAdded, setDateAdded] = useState(details?.mustDate ? true : false);
  const initialMustDate = details?.mustDate
    ? new Date(details.mustDate)
    : new Date();

  function isValidDate(date) {
    // Check if the date is a valid Date object
    return date instanceof Date && !isNaN(date);
  }
  const [mustDate, setMustDate] = useState(
    isValidDate(initialMustDate) ? initialMustDate : new Date()
  );

  const [showRush, setShowRush] = useState(true);

  const [productionDays, setProductionDays] = useState(0);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];


  const [finalTime, setFinalTime] = useState("");

  const currentDate = new Date();
  const getProductionDays = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}delivery/rush/${product.source}/${product.backing.name}`
      );

      if (response?.data[0]) {
        const maximumEstimatedTime = response?.data[0]?.maximumEstimatedTime;
        const minimumEstimatedTime = response?.data[0]?.minimumEstimatedTime;
        setProductionDays(maximumEstimatedTime);

        const maxDate = new Date(currentDate);
        const minDate = new Date(currentDate);
        maxDate.setDate(maxDate.getDate() + maximumEstimatedTime);
        minDate.setDate(minDate.getDate() + minimumEstimatedTime);

        const newYear = maxDate.getFullYear();
        const maxMonth = monthNames[maxDate.getMonth()];
        const minMonth = monthNames[minDate.getMonth()];
        const maxDay = maxDate.getDate();
        const minDay = minDate.getDate();
        setFinalTime(
          `${minMonth} ${minDay} - ${maxMonth} ${maxDay}, ${newYear}`
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProductionDays();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionDays]);

  return (
    <>
      <div
        // className={dateAdded ? "rush-bg" : ""}
        className="rush-bg"
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",

          color: "#D42678",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontFamily: "Beatrice-Display",
              color: "#25CCB0",
              fontSize: window.innerWidth > 768 ? "40px" : "2rem",
              fontWeight: "700",
              fontStyle: "italic",
              letterSpacing: "0.05em",
              textAlign: "left",
            }}
          >
            Enter your details
          </Typography>

          <div
            style={{
              width: "30%",
              height: "100px",
              display: warningTwo?.warningStatus ? "" : "none",
              zIndex: 9,
            }}
            className="warning"
          >
            <Typography className="warning-title">
              {warningTwo?.title}
            </Typography>
            <div
              className="enable-listing-style warning-desc"
              dangerouslySetInnerHTML={{
                __html: warningTwo?.warningContent,
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            color: "#D42678",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            marginTop: "180px",
            marginBottom: "180px",
          }}
        >
          <Typography
            style={{
              fontSize: window.innerWidth > 768 ? "21px" : "1rem",
              fontFamily: "Beatrice",
              width: "80%", // Full width on smaller screens
              maxWidth: "800px", // Limit width on larger screens
              textAlign: "center", // Center text
            }}
          >
            <Typography
              component="span"
              style={{
                fontSize: window.innerWidth > 768 ? "21px" : "1rem",
                fontFamily: "Beatrice",
                fontWeight: "600",
              }}
            >
              We get it, sometimes due dates sneak up on you fast!
            </Typography>
            Tell us your due date below and more about your needs. We’ll circle
            back ASAP with news of feasibility and a quote.
          </Typography>
          <Typography
            hidden={dateAdded ? false : true}
            style={{
              fontSize: window.innerWidth > 768 ? "52px" : "2rem",
              fontFamily: "Beatrice",
            }}
          >
            Need by: {finalTime}
          </Typography>
        </div>
      </div>
      <RushDetails
        mustDate={mustDate}
        setMustDate={setMustDate}
        setDateAdded={setDateAdded}
      />
      <ShippingAddress />
      {/* <BillingAddress formik={formik} /> */}
      <RushPopUp
        setSelected={setSelected}
        show={showRush}
        close={() => setShowRush(false)}
      />
    </>
  );
};

export default Rush;
