import { Typography } from "@mui/material";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { setProductDeliveryDetails } from "../../../../Redux/Slice";

const RushDetails = ({ mustDate, setMustDate, setDateAdded }) => {
  const details = useSelector(
    (state) => state.activeStep.product.delivery.details
  );
  const dispatch = useDispatch();

  const [deliveryType, setDeliveryType] = useState(
    details?.delivery ? details?.delivery : null
  );
  return (
    <div>
      <Typography
        style={{
          fontSize: "20px",
          fontWeight: "600",
          color: "#595959",
          marginTop: "0px",
          fontFamily: "Beatrice",
          marginBottom: "20px",
        }}
      >
        Order Contact Info
      </Typography>
      <Row>
        <Col xs={12} md={8} lg={7} sm={12}>
          <Row
            style={{
              marginBottom: "20px",
            }}
          >
            <Col xs={12} sm={12} md={6} lg={6} style={{ marginBottom: "0px" }}>
              <label
                style={{
                  marginBottom: "5px",
                  color: "#8F8F8F",
                }}
              >
                Must have by
              </label>

              <ReactDatePicker
                className="date-picker"
                selected={mustDate}
                minDate={new Date()}
                onChange={(date) => {
                  setMustDate(date);
                  setDateAdded(true);
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      mustDate: date,
                    })
                  );
                }}
                placeholderText="Please select a date"
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <label
                style={{
                  marginBottom: "5px",
                  color: "#8F8F8F",
                }}
              >
                Preferred Delivery type
              </label>
              <br />
              <select
                type="country"
                id="country"
                style={{
                  height: "48px",
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                  color: deliveryType ? "#000000" : "#8F8F8F",
                }}
                value={details?.delivery}
                onChange={(e) => {
                  setDeliveryType(e.target.value);
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      delivery: e.target.value,
                    })
                  );
                }}
                name="shippingCountry"
              >
                <option value="">-- Select Delivery method</option>
                <option value="NYC pickup">NYC pickup</option>
                <option value="Tri-state area installation">
                  Tri-state area installation
                </option>
                <option value="Shipping">Shipping</option>
                <option value="Other">Other</option>
              </select>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: "20px",
            }}
          >
            <Col xs={12}>
              <label
                style={{
                  marginBottom: "5px",
                  color: "#8F8F8F",
                }}
              >
                Name
              </label>
              <br />
              <input
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                name="name"
                value={details?.name}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      name: e.target.value,
                    })
                  );
                }}
                placeholder="Please enter your full name here"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} style={{ marginBottom: "15px" }}>
              <label
                style={{
                  marginBottom: "5px",
                  color: "#8F8F8F",
                }}
              >
                Email
              </label>
              <br />
              <input
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                name="email"
                value={details?.email}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      email: e.target.value,
                    })
                  );
                }}
                placeholder="Please enter your email here"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={5}>
          <Row
            style={{
              marginBottom: "20px",
            }}
          >
            <Col xs={12}>
              <label
                style={{
                  marginBottom: "5px",
                  color: "#8F8F8F",
                }}
              >
                Information
              </label>
              <br />
              <textarea
                style={{
                  height: "137px",
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                  width: "100%",
                  marginBottom: "12px",
                }}
                value={details?.info}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      info: e.target.value,
                    })
                  );
                }}
                placeholder="Please share any information we should know here. Is this for an event? Is there any flexibility with the timeline? The more info the better."
              ></textarea>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label
                style={{
                  marginBottom: "5px",

                  color: "#8F8F8F",
                }}
              >
                Phone #
              </label>
              <input
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                name="phone"
                value={details?.phone}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      phone: e.target.value,
                    })
                  );
                }}
                placeholder="Please enter your phone # here"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default RushDetails;
