import EditFont from "./EditPages/EditFont";
import EditColor from "./EditPages/EditColor";
import EditBackingStyle from "./EditPages/EditBackingStyle";
import EditBackingColor from "./EditPages/EditBackingColor";
import EditAccessories from "./EditPages/EditAccessories";

import "./styles/EditModal.css";
import EditSize from "./EditPages/EditSize";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Row } from "react-bootstrap";
import Close from "../../Assets/Close";

const style = {
  position: "absolute",
  outline: "none",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "95vh",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

export default function EditModal({ edit, show, close }) {
  return (
    <>
      <Modal
        open={show}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: "40px",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Update {edit}
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                close();
              }}
            >
              <Close />
            </div>
          </div>
          {edit === "Content" && <EditFont />}
          {edit === "Colors" && <EditColor />}
          {edit === "Backing Style" && <EditBackingStyle />}
          {edit === "Backing Color" && <EditBackingColor />}
          {edit === "Size" && <EditSize />}
          {edit === "Accessories" && <EditAccessories />}
          {edit === "Controllers" && <EditAccessories />}
          {edit === "Add-ons" && <EditAccessories />}
          {edit === "Reference Image" && <EditBackingColor />}
          <Row
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Button
              sx={{
                color: "#03705E",
                fontSize: "20px",
                fontFamily: "Beatrice",
                fontWeight: "600",
                textTransform: "uppercase",
                letterSpacing: "2px",
                bgcolor: "#D8F4F1",
                borderRadius: "16px",
                paddingRight: "30px",
                paddingLeft: "30px",
              }}
              className=" hover-effect continue-mobile"
              onClick={close}
            >
              Continue
            </Button>
          </Row>
        </Box>
      </Modal>
    </>
  );
}
