import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addPrice,
  fetchColors,
  fetchExamples,
  fetchHelp,
  nextStep,
  setAccessoriesCost,
  setBackingCost,
  setInstallationPrice,
  setNeonCost,
  setProductColorDescription,
  setProductFont,
  setTotalCost,
  updatePositions,
} from "../../../Redux/Slice";
import { Popover, Typography } from "@mui/material";
import ColorsList from "../../../Components/Colors/ColorsList";
import BottomControls from "../../../Components/BottomControls";
import { ReactComponent as DescriptionIcon } from "../../../Assets/DescriptionIcon.svg";
import RGBIcon from "../../../Assets/RGB.png";
import Send from "../../../Assets/Send";
import Close from "../../../Assets/Close";
import {
  calculateFontArea,
  calculateLetterLength,
} from "../../../utils/client/calculations";
import RainbowColor from "../../../Components/Colors/RainbowColor";
import Draggable from "react-draggable";
import SuperColor from "../../../Pages/SuperCustom/SuperColor";
import "../../../Pages/styles/Colors.css";
import ExampleModal from "../../ExampleModal";

const EditColor = () => {
  const product = useSelector((state) => state.activeStep.product);
  const colorDesc = useSelector(
    (state) => state.activeStep.product.colorDescription
  );
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showRGB, setShowRGB] = useState(false);
  const showExamples = useSelector((state) => state.activeStep.showExamples);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [textAreas, setTextAreas] = useState(product?.fonts);
  const [selectedTextArea, setSelectedTextArea] = useState(textAreas[0]?.id);
  const [desc, setDesc] = useState(colorDesc ? colorDesc : "");
  const [examples] = useState(false);
  const dispatch = useDispatch();
  const positions = useSelector((state) => state.activeStep.positions);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDescriptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDescriptionClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const theme = useSelector((state) => state.activeStep.theme);

  const textareaRefs = useRef(Array(textAreas.length).fill(null));

  const updateTextareaHeight = (index) => {
    const textarea = textareaRefs.current[index];
    textarea.style.height = "auto";
    textarea.style.height = `${textarea?.scrollHeight + 10}px`;
  };

  const handleTextAreaChange = (id, event) => {
    const updatedTextAreas = textAreas.map((textarea) => {
      if (textarea.id === id) {
        const value = event.target.value;
        const lines = value.split("\n");
        const longestLine = lines.reduce(
          (acc, line) => (line.length > acc ? line.length : acc),
          0
        );
        const newWidth = (longestLine + 1) * 30;
        return {
          ...textarea,
          value: value,
          width: newWidth,
        };
      }
      return textarea;
    });
    setTextAreas(updatedTextAreas);
    dispatch(setProductFont(updatedTextAreas));
  };

  const handleColorSelection = (color) => {
    // console.log("Textarea change");

    if (selectedTextArea !== null) {
      // console.log("color");
      setTextAreas((prevState) => {
        const updatedTextAreas = prevState.map((textArea) => {
          // console.log(selectedTextArea);
          if (textArea.id === selectedTextArea) {
            // console.log(" matched");
            return { ...textArea, color: color };
          }
          return textArea;
        });
        dispatch(setProductFont(updatedTextAreas));
        return updatedTextAreas;
      });
    }
  };

  const handleTextAreaClick = (id) => {
    // console.log(id);
    setSelectedTextArea(id);
  };

  const handleLetterColorSelection = (color) => {

    if (selectedLetter !== null) {
      setTextAreas((prevState) => {
        const updatedTextAreas = prevState.map((textArea) => {
          if (textArea.id === selectedTextArea) {
            // console.log("TextArea Matched");
            const updatedLetters = textArea.letters.map((letter) => {
              if (letter.id === selectedLetter) {
                // console.log("Added Color to letter");
                return { ...letter, color: color };
              }
              return letter;
            });
            const updatedTextArea = { ...textArea, letters: updatedLetters };
            return updatedTextArea;
          }
          return textArea;
        });
        dispatch(setProductFont(updatedTextAreas));
        return updatedTextAreas;
      });
    }
  };

  const handleSplitButtonClick = () => {
    // console.log(selectedTextArea);
    if (selectedTextArea !== null) {
      setTextAreas((prevTextAreas) => {
        const updatedTextAreas = prevTextAreas.map((textArea) => {
          if (textArea.id === selectedTextArea) {
            const letters = textArea.value.split("");
            const coloredLetters = letters.map((letter, index) => ({
              id: index,
              letter,
              color: textArea.color, // default color
            }));
            return {
              ...textArea,
              // color: "black",
              isSplit: true,
              letters: coloredLetters,
            };
          }
          return textArea;
        });
        dispatch(setProductFont(updatedTextAreas));
        return updatedTextAreas;
      });
    }
  };

  const handleMergeButtonClick = () => {
    // console.log(textAreas);
    if (selectedTextArea !== null) {
      setTextAreas((prevTextAreas) => {
        const updatedTextAreas = prevTextAreas.map((textArea) => {
          if (textArea.id === selectedTextArea) {
            // Remove the letters array and set isSplit to false
            return {
              ...textArea,
              color: textArea?.color,
              isSplit: false,
              letters: undefined, // Remove the letters array
            };
          }
          return textArea;
        });
        dispatch(setProductFont(updatedTextAreas));
        return updatedTextAreas;
      });
    }
  };

  const handleDragDrop = (e, data, id) => {
    const { x, y } = positions[id] || { x: 0, y: 0 };
    const newX = x + data.deltaX;
    const newY = y + data.deltaY;

    const updatedPositions = {
      ...positions,
      [id]: { x: newX, y: newY },
    };
    // console.log(updatedPositions);

    dispatch(updatePositions(updatedPositions));
  };

  const allFonts = useSelector((state) => state.activeStep.fonts);
  const colors = useSelector((state) => state.activeStep.colors);

  useEffect(() => {
    dispatch(fetchColors(product.source));
    dispatch(fetchHelp(`Colors/${product.source}`));
    dispatch(fetchExamples("Colors", product.source));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bendingCost = useSelector((state) => state.activeStep.bendingCostPerFt);
  const colorCost = useSelector((state) => state.activeStep.colorCost);
  const tech22Cost = useSelector((state) => state.activeStep.tech22Cost);
  const blackAccessoryCost = useSelector(
    (state) => state.activeStep.blackAccessoryCost
  );
  const cables = useSelector((state) => state.activeStep.cables);
  // const flatRateAdded = useSelector(
  //   (state) => state.activeStep.backingFlatRateAdded
  // );
  const addedAddOns = useSelector(
    (state) => state.activeStep.product.accessories.addOns
  );
  const controllers = useSelector((state) => state.activeStep.controllers);
  // const backingFlatRate = useSelector(
  //   (state) => state.activeStep.backingFlatRate
  // );
  const clearPlexiCost = useSelector(
    (state) => state.activeStep.clearPlexiCost
  );
  // const installations = useSelector((state) => state.activeStep.installations);
  const baseInstallValues = useSelector(
    (state) => state.activeStep.baseInstallValues
  );
  const backings = useSelector((state) => state.activeStep.backingStyles);
  const installationCost = useSelector(
    (state) => state.activeStep.installationCost
  );
  const backingColors = useSelector((state) => state.activeStep.backingColors);
  const isCustomColor = useSelector(
    (state) => state.activeStep.product.customBacking
  );
  const factor = useSelector((state) => state.activeStep.materialLengthFactor);
  const cost = useSelector((state) => state.activeStep.product.cost);

  // const shippingCost = useSelector(
  //   (state) => state.activeStep.product.shippingCost
  // );
  const customBackingCost = useSelector(
    (state) => state.activeStep.customBackingCost
  );
  const backingWidth = useSelector(
    (state) => state.activeStep.product.backing.width
  );

  const backingHeight = useSelector(
    (state) => state.activeStep.product.backing.height
  );
  const blackFranceCost = useSelector(
    (state) => state.activeStep.blackFranceRate
  );
  const whiteFranceCost = useSelector(
    (state) => state.activeStep.whiteFranceRate
  );
  const Hmax = useSelector((state) => state.activeStep.plexiHeightMax);
  const Wmax = useSelector((state) => state.activeStep.plexiWidthMax);
  const markUp = useSelector((state) => state.activeStep.markup);
  const discount = useSelector((state) => state.activeStep.discount);
  const materialLengthPerTech = useSelector(
    (state) => state.activeStep.materialLengthPerTech
  );
  const getColorPriceForLetter = (colorCode) => {
    const color = colors.find((color) => color.code === colorCode);
    if (color) {
      return color.price;
    } else {
      return colorCost; // Use the default color cost if color is not found
    }
  };

  const calculateBackingPrice = async () => {
    // console.log("Calculating Price");
    // console.log(selectedFont);

    if (textAreas[0]?.value || textAreas[1]?.value || textAreas[2]?.value) {
      // console.log(selectedFont);

      let baseValue = 0;
      let backingPrice;
      let wireCost = blackAccessoryCost;
      let backingPerFt = clearPlexiCost;
      let colorPricePerFt = 0;
      let fontPricePerFt = 0;
      let materialLength = 0;
      let neonWidth = 0;
      let neonHeight = 0;
      let signWidth = 0;
      let signHeight = 0;
      let glassCost = 0;
      let glassCost1 = 0;
      let glassCost2 = 0;
      let glassCost3 = 0;
      let unitNumber = 0;
      let segments = 0;

      // console.log("Bending Cost :", bendingCost);
      // console.log("EGL White Cost :", colorCost);
      // console.log("Max Plexi Height :", Hmax);
      // console.log("Max Plexi Width :", Wmax);

      if (textAreas.length === 1) {
        // console.log("Single Font");
        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          neonWidth = result?.materialLength;
          neonHeight = result?.maxHeight;
          signWidth = result?.totalWidth + backingWidth;
          signHeight = result?.maxHeight + backingHeight;

          allFonts.map((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt = font.price;
            }
          });
          textAreas[0].letters.forEach(async (letter) => {
            colorPricePerFt = getColorPriceForLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            glassCost +=
              Number(fontPricePerFt + bendingCost + colorPricePerFt) *
              (letterLength / 12);
          });
          // console.log(glassCost, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          // console.log(result);
          materialLength = result?.materialLength;
          neonWidth = result?.totalWidth;
          neonHeight = result?.maxHeight;
          signWidth = result?.totalWidth + backingWidth;
          signHeight = result?.maxHeight + backingHeight;

          materialLength *= factor;

          allFonts.map((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt = font.price;
            }
          });
          colors.map((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt = color.price;
            }
          });

          colorPricePerFt = colorPricePerFt ? colorPricePerFt : colorCost;

          // console.log(colorPricePerFt);
          glassCost =
            Number(fontPricePerFt + bendingCost + colorPricePerFt) *
            (materialLength / 12);
          // console.log(glassCost);
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        }
        // console.log("Material Length : ", materialLength);

        // console.log("Neon Width : ", neonWidth);
        // console.log("Neon Height : ", neonHeight);
        // console.log("Sign Width : ", signWidth);
        // console.log("Sign Height : ", signHeight);

        unitNumber = Number(
          Math.ceil(neonWidth / 24) + Math.ceil(neonHeight / 10)
        );
        console.log("Unit Numbers : ", unitNumber);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        console.log("Segments : ", segments);
      } else if (textAreas.length === 2) {
        // console.log("Multiple Fonts");
        let fontPricePerFt1 = 0;
        let fontPricePerFt2 = 0;
        let colorPricePerFt1 = 0;
        let colorPricePerFt2 = 0;

        let materialLength1 = 0;
        let neonWidth1 = 0;
        let neonHeight1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let neonWidth2 = 0;
        let neonHeight2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        if (textAreas[0]?.isSplit) {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;
          allFonts.map((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          textAreas[0].letters.forEach(async (letter) => {
            colorPricePerFt1 = getColorPriceForLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            glassCost1 +=
              Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
              (letterLength / 12);
          });
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.map((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          colors.map((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt1 = color.price;
            }
          });

          colorPricePerFt1 = colorPricePerFt1 ? colorPricePerFt1 : colorCost;

          // console.log(fontPricePerFt);
          glassCost1 =
            Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
            (materialLength1 / 12);
          // console.log(glassCost);
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          allFonts.map((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          textAreas[1].letters.forEach(async (letter) => {
            colorPricePerFt2 = getColorPriceForLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            glassCost2 +=
              Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
              (letterLength / 12);
          });
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.map((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          colors.map((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPricePerFt2 = color.price;
            }
          });

          colorPricePerFt2 = colorPricePerFt2 ? colorPricePerFt2 : colorCost;

          // console.log(fontPricePerFt);
          glassCost2 =
            Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
            (materialLength2 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }

        // console.log(fontPricePerFt);
        glassCost = Number(glassCost1 + glassCost2);
        // console.log(glassCost);
        signHeight = Number(signHeight1 + signHeight2) + backingHeight;
        signWidth = Math.max(signWidth1, signWidth2) + backingWidth;
        materialLength = materialLength1 + materialLength2;
        // console.log("Sign Height :", signHeight);
        // console.log("Sign Width :", signWidth);
        // console.log("Total Material Length :", materialLength);

        unitNumber = Number(
          Math.ceil(Math.max(neonWidth1, neonWidth2) / 24) +
          Math.ceil((neonHeight1 + neonHeight2) / 10)
        );
        // console.log(unitNumber);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        // console.log(segments);
      } else if (textAreas.length === 3) {
        // console.log("Multiple Fonts");
        let fontPricePerFt1 = 0;
        let fontPricePerFt2 = 0;
        let fontPricePerFt3 = 0;
        let colorPricePerFt1 = 0;
        let colorPricePerFt2 = 0;
        let colorPricePerFt3 = 0;

        let materialLength1 = 0;
        let neonWidth1 = 0;
        let neonHeight1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let neonWidth2 = 0;
        let neonHeight2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        let materialLength3 = 0;
        let neonWidth3 = 0;
        let neonHeight3 = 0;
        let signWidth3 = 0;
        let signHeight3 = 0;

        if (textAreas[0]?.isSplit) {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;
          allFonts.map((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          textAreas[0].letters.forEach(async (letter) => {
            colorPricePerFt1 = getColorPriceForLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            glassCost1 +=
              Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
              (letterLength / 12);
          });
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.map((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          colors.map((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt1 = color.price;
            }
          });

          colorPricePerFt1 = colorPricePerFt1 ? colorPricePerFt1 : colorCost;

          // console.log(fontPricePerFt);
          glassCost1 =
            Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
            (materialLength1 / 12);
          // console.log(glassCost);
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          allFonts.map((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          textAreas[1].letters.forEach(async (letter) => {
            colorPricePerFt2 = getColorPriceForLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            glassCost2 +=
              Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
              (letterLength / 12);
          });
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.map((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          colors.map((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPricePerFt2 = color.price;
            }
          });

          colorPricePerFt2 = colorPricePerFt2 ? colorPricePerFt2 : colorCost;

          // console.log(fontPricePerFt);
          glassCost2 =
            Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
            (materialLength2 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[2]?.isSplit) {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink
          );
          // console.log(result2);
          materialLength3 = result3?.materialLength;
          neonWidth3 = result3?.totalWidth;
          neonHeight3 = result3?.maxHeight;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;
          allFonts.map((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt3 = font.price;
            }
          });
          textAreas[2].letters.forEach(async (letter) => {
            colorPricePerFt3 = getColorPriceForLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[2]?.fontFamily,
              textAreas[2]?.jsonLink
            );
            glassCost3 +=
              Number(fontPricePerFt3 + bendingCost + colorPricePerFt3) *
              (letterLength / 12);
          });
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink
          );
          // console.log(result2);
          materialLength3 = result3?.materialLength;
          neonWidth3 = result3?.totalWidth;
          neonHeight3 = result3?.maxHeight;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.map((font) => {
            if (font.name === textAreas[2].fontFamily) {
              // console.log(font.name);
              fontPricePerFt3 = font.price;
            }
          });
          colors.map((color) => {
            if (color.code === textAreas[2].color) {
              // console.log(color.code);
              colorPricePerFt3 = color.price;
            }
          });

          colorPricePerFt3 = colorPricePerFt3 ? colorPricePerFt3 : colorCost;

          // console.log(fontPricePerFt);
          glassCost3 =
            Number(fontPricePerFt3 + bendingCost + colorPricePerFt3) *
            (materialLength3 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }

        // console.log(fontPricePerFt);
        glassCost = Number(glassCost1 + glassCost2 + glassCost3);
        // console.log(glassCost);
        signHeight = signHeight1 + signHeight2 + signHeight3 + backingHeight;
        signWidth = Math.max(signWidth1, signWidth2, signWidth3) + backingWidth;
        materialLength = materialLength1 + materialLength2 + materialLength3;

        unitNumber = Number(
          Math.ceil(Math.max(neonWidth1, neonWidth2, neonWidth3) / 24) +
          Math.ceil((neonHeight1 + neonHeight2 + neonHeight3) / 10)
        );
        // console.log(unitNumber);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        // console.log(segments);
      }

      console.log("Glass Cost : ", glassCost);
      dispatch(setNeonCost(glassCost));

      let techs = Number(
        Math.ceil((materialLength / 12 + unitNumber) / materialLengthPerTech) +
        segments -
        1
      );
      console.log("Techs:", techs);

      let transformerCost = Number(techs * tech22Cost);
      console.log("Transformer Cost:", transformerCost);

      if (product.delivery.install.name) {
        if (product.delivery.install.name === "Concealed Install") {
          transformerCost = Number(techs * whiteFranceCost);
          console.log("Transformer Cost When Concealed:", transformerCost);
        }
        if (product.delivery.install.name === "Surface Install") {
          transformerCost = Number(techs * blackFranceCost);
          console.log("Transformer Cost When Surface:", transformerCost);
        }

        baseInstallValues.map((installValue) => {
          if (installValue.name === product?.delivery?.install?.name) {
            baseValue = installValue?.value;
          }
        });
        // console.log(installationCost, baseValue, unitNumber);
        backingPrice = Number(baseValue + installationCost * unitNumber);
        let installationPrice =
          signWidth * signHeight * product.delivery.install.installPerSq;
        dispatch(setInstallationPrice(installationPrice));
        console.log("Installation Cost:", installationPrice);
      } else {
        dispatch(setInstallationPrice(0));
        backings.map((backing) => {
          if (backing.type === product.backing.name) {
            // console.log(color.code);
            backingPerFt = backing.BackingPricePerSqInch;
          }
        });

        if (isCustomColor) {
          backingPerFt = customBackingCost;
        } else {
          backingColors.map((backing) => {
            if (backing.code === product.backing.color) {
              // console.log(color.code);
              backingPerFt = backing.price;
            }
          });
        }
        backingPrice = Number(signWidth * signHeight * backingPerFt);
        if (product.backing.name === "") {
          backingPrice = 0;
        } else {
          if (
            product.backing.name === "wood" ||
            product.backing.name === "box"
          ) {
            backingPrice = Number(
              (2 * signWidth * signHeight + signHeight * signHeight) *
              backingPerFt
            );
          } else if (product.backing.name === "skeleton") {
            backingPrice = Number(materialLength * backingPerFt);
          }
        }
        // console.log(backingPrice);
      }
      console.log("BackingCost:", backingPrice);
      dispatch(setBackingCost(backingPrice));

      cables.map((cable) => {
        if (cable.color === product?.accessories?.wireColor) {
          // console.log(cable);
          wireCost = cable.price;
        }
      });
      let accessoriesCost = Number(unitNumber * wireCost);
      console.log("Accessories Cost:", accessoriesCost);

      controllers.map((controller) => {
        if (controller.name === product?.accessories?.dimmer?.name) {
          // console.log(cable);
          accessoriesCost += controller.price;
        }
      });
      if (product?.accessories?.addOns.length > 0) {
        addedAddOns.map((addOn) => {
          accessoriesCost += addOn.price;
        });
      }
      console.log("Total Accessories Cost:", accessoriesCost);
      dispatch(setAccessoriesCost(accessoriesCost));

      let totalCost = Number(
        glassCost + transformerCost + backingPrice + accessoriesCost
      );
      console.log("Total Cost:", totalCost);

      dispatch(setTotalCost(totalCost));
      console.log("Mark Up Percentage :", markUp);
      console.log("Discount Percentage :", discount);
      let decimalMarkUp = markUp / 100;
      let decimalDiscount = discount / 100;

      let markupAmount = decimalMarkUp * totalCost;
      console.log("MarkUp Amount:", markupAmount);
      console.log("Decimal Discount:", decimalDiscount);
      let markUpPrice = totalCost + markupAmount;
      let discountFactor = Number(1 - decimalDiscount);
      console.log("MarkUp Price :", markUpPrice);
      console.log("Discounted Factor :", discountFactor);

      let price = Number(markUpPrice * discountFactor);
      console.log("Price:", price);
      dispatch(addPrice(Math.ceil(price)));
    }
  };

  const RGBColor = useSelector((state) => state.activeStep.product.RGBColor);
  const SpecialColorFee = useSelector(
    (state) => state.activeStep.SpecialColorFee
  );
  const LEDCostPerMaterial = useSelector((state) => state.activeStep.colorCost);
  const InterShippingCost = useSelector(
    (state) => state.activeStep.InterShippingCost
  );
  const AcrylicInterShippingCost = useSelector(
    (state) => state.activeStep.woodInterShippingCost
  );
  const WoodInterShippingCost = useSelector(
    (state) => state.activeStep.acrylicInterShippingCost
  );
  const standardRGBCost = useSelector(
    (state) => state.activeStep.standardRGBCost
  );
  const magicRGBCost = useSelector((state) => state.activeStep.magicRGBCost);

  const getColorPriceForLEDLetter = (colorCode) => {
    const color = colors.find((color) => color.code === colorCode);
    if (color) {
      return color.price;
    } else {
      return LEDCostPerMaterial; // Use the default color cost if color is not found
    }
  };
  const calculateLEDFontPrice = async () => {
    // console.log("Calculating Price");
    if (textAreas[0]?.value || textAreas[1]?.value) {
      // console.log(selectedFont);

      let colorPrice = colorCost;
      let backingPerFt = customBackingCost;
      let baseValue = 0;
      let materialLength = 0;
      let signWidth = 0;
      let signHeight = 0;
      let backingArea = 0;
      let LEDCost = 0;
      let segments = 0;
      // console.log("Custom Color Backing Cost :", customBackingCost);
      // console.log("Special Color Cost :", SpecialColorFee);
      // console.log("Magic RGB Cost :", magicRGBCost);
      // console.log("Standard RGB Cost :", standardRGBCost);
      // console.log("Max Plexi Height :", Hmax);
      // console.log("Max Plexi Width :", Wmax);
      if (textAreas.length === 1 && textAreas[0].value) {
        console.log("------------------Single Font");
        if (textAreas[0]?.isSplit) {
          console.log("------------------Is Split");
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          // console.log(result);
          materialLength = result?.materialLength;
          signWidth = result?.totalWidth + backingWidth;
          signHeight = result?.maxHeight + backingHeight;

          const lettersColors = textAreas[0]?.letters?.map(
            (letter) => letter.color
          );
          // console.log(lettersColors);
          const customColorExists = lettersColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          console.log("Custom Color Present :", customColorExists);

          const letterPromises = textAreas[0].letters.map(async (letter) => {
            console.log(letter);
            let colorPrice = await getColorPriceForLEDLetter(letter.color);
            let letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            console.log(`Material length of ${letter.letter} :`, letterLength);
            console.log(`Color Cost of ${letter.letter} :`, colorPrice);
            let letterLEDCost;
            if (customColorExists) {
              letterLEDCost = Number(
                (SpecialColorFee + letterLength) * colorPrice
              );
            } else {
              letterLEDCost = Number(colorPrice * letterLength);
            }
            console.log(`LED Cost of ${letter.letter} :`, letterLEDCost);
            return letterLEDCost;
          });
          console.log(letterPromises);

          Promise.all(letterPromises)
            .then((letterCosts) => {
              const LEDCost = letterCosts.reduce(
                (total, cost) => total + cost,
                0
              );
              console.log("Total LED Cost:", LEDCost);
            })
            .catch((error) => {
              console.error("Error calculating LED cost:", error);
            });
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          // console.log(result);
          materialLength = result?.materialLength;
          materialLength *= factor;
          signWidth = result?.totalWidth + backingWidth;
          signHeight = result?.maxHeight + backingHeight;

          console.log(`Material length :`, materialLength);
          colors.map((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice = color.price;
            }
          });

          console.log(`Color Cost :`, colorPrice);

          const textAreaColors = textAreas.map((textArea) => textArea.color);
          // console.log(textAreaColors);
          const customColorExists = textAreaColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          console.log("Custom Color Present :", customColorExists);
          if (customColorExists) {
            LEDCost = Number((colorPrice + SpecialColorFee) * materialLength);
          } else {
            LEDCost = Number(colorPrice) * materialLength;
          }
          // console.log("LED Cost :", LEDCost);
        }

        console.log("Material Length : ", materialLength);
        console.log("Sign Width : ", signWidth);
        console.log("Sign Height : ", signHeight);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        console.log("Segments :", segments);

        backingArea = Number(signHeight * signWidth);
        console.log("Backing Area :", backingArea);
      } else if (textAreas.length === 2) {
        console.log("------------------Double Font");
        let colorPrice1 = 0;
        let colorPrice2 = 0;
        let LEDCost1 = 0;
        let LEDCost2 = 0;

        let materialLength1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;

          textAreas[0].letters.forEach(async (letter) => {
            colorPrice1 = getColorPriceForLEDLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            LEDCost1 += Number(colorPrice1 * letterLength);
          });
          const lettersColors = textAreas[0]?.letters?.map(
            (letter) => letter.color
          );
          const customColorExists = lettersColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          if (customColorExists) {
            LEDCost1 += SpecialColorFee;
          }
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;

          console.log(
            `Material length of ${textAreas[0].value}:`,
            materialLength1
          );
          colors.map((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice1 = color.price;
            }
          });

          console.log(`Color Cost of ${textAreas[0].value}:`, colorPrice1);

          const textAreaColors = textAreas.map((textArea) => textArea.color);
          // console.log(textAreaColors);
          const customColorExists = textAreaColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          // console.log(customColorExists);
          if (customColorExists) {
            LEDCost1 = Number(
              (colorPrice1 + SpecialColorFee) * materialLength1
            );
          } else {
            LEDCost1 = Number(colorPrice1) * materialLength1;
          }
          console.log(`LED Cost of ${textAreas[0].value} :`, LEDCost1);
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          textAreas[1].letters.forEach(async (letter) => {
            colorPrice2 = getColorPriceForLEDLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            LEDCost2 += Number(colorPrice2 * (letterLength / 12));
          });
          const lettersColors = textAreas[1]?.letters?.map(
            (letter) => letter.color
          );
          const customColorExists = lettersColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          if (customColorExists) {
            LEDCost2 += SpecialColorFee;
          }
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          console.log(
            `Material length of ${textAreas[1].value}:`,
            materialLength2
          );
          colors.map((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPrice2 = color.price;
            }
          });

          console.log(`Color Cost of ${textAreas[1].value}:`, colorPrice2);

          // console.log(LEDCost);
          const textAreaColors = textAreas.map((textArea) => textArea.color);
          // console.log(textAreaColors);
          const customColorExists = textAreaColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          // console.log(customColorExists);
          if (customColorExists) {
            LEDCost2 = Number(
              (colorPrice2 + SpecialColorFee) * materialLength2
            );
          } else {
            LEDCost2 = Number(colorPrice2) * materialLength2;
          }
          console.log(`LED Cost of ${textAreas[1].value} :`, LEDCost2);
        }

        LEDCost = LEDCost1 + LEDCost2;

        // console.log(signWidth);
        // console.log(materialLength / 12);

        // console.log(fontPricePerFt);

        // console.log(LEDCost);
        materialLength = materialLength1 + materialLength2;
        materialLength *= factor;
        signHeight = Number(signHeight1 + signHeight2) + backingHeight;
        signWidth = Math.max(signWidth1, signWidth2) + backingWidth;
        console.log("Material Length : ", materialLength);
        console.log("Sign Width : ", signWidth);
        console.log("Sign Height : ", signHeight);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        console.log("Segments :", segments);

        backingArea = Number(signHeight * signWidth);
        console.log("Backing Area :", backingArea);
      } else if (textAreas.length === 3) {
        console.log("------------------Triple Font");
        let colorPrice1 = 0;
        let colorPrice2 = 0;
        let colorPrice3 = 0;
        let LEDCost1 = 0;
        let LEDCost2 = 0;
        let LEDCost3 = 0;

        let materialLength1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        let materialLength3 = 0;
        let signWidth3 = 0;
        let signHeight3 = 0;

        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;

          textAreas[0].letters.forEach(async (letter) => {
            colorPrice1 = getColorPriceForLEDLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            LEDCost1 += Number(colorPrice1 * letterLength);
          });
          const lettersColors = textAreas[0]?.letters?.map(
            (letter) => letter.color
          );
          const customColorExists = lettersColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          if (customColorExists) {
            LEDCost1 += SpecialColorFee;
          }
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;

          console.log(
            `Material length of ${textAreas[0].value}:`,
            materialLength1
          );
          colors.map((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice1 = color.price;
            }
          });

          console.log(`Color Cost of ${textAreas[0].value}:`, colorPrice1);

          const textAreaColors = textAreas.map((textArea) => textArea.color);
          // console.log(textAreaColors);
          const customColorExists = textAreaColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          // console.log(customColorExists);
          if (customColorExists) {
            LEDCost1 = Number(
              (colorPrice1 + SpecialColorFee) * materialLength1
            );
          } else {
            LEDCost1 = Number(colorPrice1) * materialLength1;
          }
          console.log(`LED Cost of ${textAreas[0].value} :`, LEDCost1);
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          textAreas[1].letters.forEach(async (letter) => {
            colorPrice2 = getColorPriceForLEDLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            LEDCost2 += Number(colorPrice2 * letterLength);
          });
          const lettersColors = textAreas[1]?.letters?.map(
            (letter) => letter.color
          );
          const customColorExists = lettersColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          if (customColorExists) {
            LEDCost2 += SpecialColorFee;
          }
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          console.log(
            `Material length of ${textAreas[1].value}:`,
            materialLength2
          );
          // console.log(materialLength / 12);
          colors.map((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPrice2 = color.price;
            }
          });

          // console.log(LEDCost);
          const textAreaColors = textAreas.map((textArea) => textArea.color);
          // console.log(textAreaColors);
          const customColorExists = textAreaColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          // console.log(customColorExists);
          if (customColorExists) {
            LEDCost2 = Number(
              (colorPrice2 + SpecialColorFee) * materialLength2
            );
          } else {
            LEDCost2 = Number(colorPrice2) * materialLength2;
          }
        }
        if (textAreas[2]?.isSplit) {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink
          );
          materialLength3 = result3?.materialLength;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;

          textAreas[2].letters.forEach(async (letter) => {
            colorPrice3 = getColorPriceForLEDLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[2]?.fontFamily,
              textAreas[2]?.jsonLink
            );
            LEDCost3 += Number(colorPrice3 * letterLength);
          });
          const lettersColors = textAreas[2]?.letters?.map(
            (letter) => letter.color
          );
          const customColorExists = lettersColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          if (customColorExists) {
            LEDCost3 += SpecialColorFee;
          }
        } else {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink
          );
          materialLength3 = result3?.materialLength;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;

          console.log(
            `Material length of ${textAreas[2].value}:`,
            materialLength3
          );
          // console.log(materialLength / 12);
          colors.map((color) => {
            if (color.code === textAreas[2].color) {
              // console.log(color.code);
              colorPrice3 = color.price;
            }
          });

          console.log(`Color Cost of ${textAreas[2].value}:`, colorPrice3);

          // console.log(LEDCost);
          const textAreaColors = textAreas.map((textArea) => textArea.color);
          // console.log(textAreaColors);
          const customColorExists = textAreaColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          // console.log(customColorExists);
          if (customColorExists) {
            LEDCost3 = Number(
              (colorPrice3 + SpecialColorFee) * materialLength3
            );
          } else {
            LEDCost3 = Number(colorPrice3) * materialLength3;
          }
          console.log(`LED Cost of ${textAreas[2].value} :`, LEDCost3);
        }

        LEDCost = LEDCost1 + LEDCost2 + LEDCost3;

        // console.log(signWidth);
        // console.log(materialLength / 12);

        // console.log(fontPricePerFt);

        // console.log(LEDCost);
        materialLength = materialLength1 + materialLength2 + materialLength3;
        materialLength *= factor;
        signHeight =
          Number(signHeight1 + signHeight2 + signHeight3) + backingHeight;
        signWidth = Math.max(signWidth1, signWidth2, signWidth3) + backingWidth;
        console.log("Material Length : ", materialLength);
        console.log("Sign Width : ", signWidth);
        console.log("Sign Height : ", signHeight);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        console.log("Segments :", segments);
        backingArea = Number(signHeight * signWidth);
        console.log("Backing Area :", backingArea);
      }

      console.log("Total LED Cost :", LEDCost);
      dispatch(setNeonCost(LEDCost));

      let powerSupplies = Number(
        Math.ceil(materialLength / 12 / materialLengthPerTech)
      );
      console.log("Number of Power Supply :", powerSupplies);

      let powerSupplyCost = Number(powerSupplies * tech22Cost);
      console.log("Power Supply Cost :", powerSupplyCost);

      let backingCost;
      if (product.delivery.install.name) {
        baseInstallValues.map((installValue) => {
          if (installValue.name === product?.delivery?.install?.name) {
            baseValue = installValue?.value;
          }
        });
        // console.log(installationCost, baseValue, unitNumber);
        backingCost = Number(baseValue + installationCost);
        // console.log("BackingCost:",backingCost);
        let installationPrice =
          signWidth * signHeight * product.delivery.install.installPerSq;
        dispatch(setInstallationPrice(Math.ceil(installationPrice)));
        console.log("Installation Cost:", installationPrice);
      } else {
        dispatch(setInstallationPrice(0));
        backings.map((backing) => {
          if (backing.type === product.backing.name) {
            // console.log(color.code);
            backingPerFt = backing.BackingPricePerSqInch;
          }
        });

        if (isCustomColor) {
          backingPerFt = customBackingCost;
        } else {
          backingColors.map((backing) => {
            if (backing.code === product.backing.color) {
              // console.log(color.code);
              backingPerFt = backing.price;
            }
          });
        }

        console.log("Backing Color Cost :", backingPerFt);
        backingCost = Number(backingArea * backingPerFt);
        if (product.backing.name === "") {
          backingCost = 0;
        }
        console.log("Backing Cost :", backingCost);
        dispatch(setBackingCost(backingCost));
      }

      let accessoriesCost = Number(segments * blackAccessoryCost);
      // console.log(accessoriesCost);

      // console.log(accessoriesCost);
      controllers.map((controller) => {
        if (controller.name === product?.accessories?.dimmer?.name) {
          console.log("Controller Price :", controller.price);
          accessoriesCost =
            Number(segments * blackAccessoryCost) +
            powerSupplies * controller.price;
        }
      });
      // console.log(accessoriesCost);
      if (product.accessories.addOns.length > 0) {
        addedAddOns.map((addOn) => {
          // console.log(accessoriesCost, addOn.price);
          accessoriesCost += addOn.price;
        });
      }

      // console.log("Accessories Cost :", accessoriesCost);

      console.log("RGB Style Selected :", RGBColor);
      if (RGBColor) {
        if (RGBColor === "standardRGB") {
          accessoriesCost = Number(
            segments * (blackAccessoryCost + standardRGBCost)
          );
          console.log("Accessories Cost with Standard RGB :", accessoriesCost);
        }
        if (RGBColor === "magicRGB") {
          accessoriesCost = Number(
            segments * (blackAccessoryCost + magicRGBCost)
          );
          console.log("Accessories Cost with Magic RGB :", accessoriesCost);
        }
      }
      console.log(accessoriesCost);
      dispatch(setAccessoriesCost(accessoriesCost));

      let shippingCost;
      shippingCost = Number(backingArea * InterShippingCost);
      if (product.backing.name === "wood") {
        shippingCost = Number(backingArea * WoodInterShippingCost);
      }
      if (product.backing.name === "box") {
        shippingCost = Number(backingArea * AcrylicInterShippingCost);
      }
      console.log("Shipping Cost :", shippingCost);

      let totalCost = Number(
        LEDCost + powerSupplyCost + backingCost + accessoriesCost + shippingCost
      );
      console.log("Total Cost:", totalCost);
      dispatch(setTotalCost(totalCost));

      console.log("Mark Up Percentage :", markUp);
      console.log("Discount Percentage :", discount);
      let decimalMarkUp = markUp / 100;
      let decimalDiscount = discount / 100;

      let markupAmount = decimalMarkUp * totalCost;
      console.log("MarkUp Amount:", markupAmount);
      console.log("Decimal Discount:", decimalDiscount);
      let markUpPrice = totalCost + markupAmount;
      let discountFactor = Number(1 - decimalDiscount);
      console.log("MarkUp Price :", markUpPrice);
      console.log("Discounted Factor :", discountFactor);

      let price = Number(markUpPrice * discountFactor);
      console.log("Price:", price);
      dispatch(addPrice(Math.ceil(price)));
    }
  };

  useEffect(() => {
    // textAreas.forEach((_, index) => updateTextareaHeight(index));
  }, []);

  useEffect(() => {
    if (product.source === "Glass") {
      calculateBackingPrice();
    } else if (product.source === "LED") {
      calculateLEDFontPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreas]);

  useEffect(() => {
    if (product.format === "custom") {
      const updatedTextAreas = textAreas.map((textArea) => {
        let updatedTextArea = { ...textArea };

        // Check if the fontFamily is not in the fonts array
        if (
          !allFonts?.some((font) => font?.name === updatedTextArea?.fontFamily)
        ) {
          // console.log("Switching Font");
          const randomFontIndex = Math.floor(Math.random() * allFonts?.length);
          updatedTextArea.fontFamily = allFonts[randomFontIndex]?.name;
        }

        // Check if the color is not in the colors array
        if (!colors?.some((color) => color?.code === updatedTextArea?.color)) {
          // console.log("Switching Color");
          const randomColorIndex = Math.floor(Math.random() * colors?.length);
          updatedTextArea.color = colors[randomColorIndex]?.code;
        }

        return updatedTextArea;
      });

      dispatch(setProductFont(updatedTextAreas));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFonts, colors, textAreas, dispatch]);
  // console.log(RGBColor);
  return (
    <>
      <div className="mt-3 margin-adjust parent-container">
        {product.format === "super-custom" ? (
          <>
            <SuperColor />
          </>
        ) : (
          <>
            <Row>
              <Col md={8} lg={2} xs={12} sm={12}>
                <div
                  className="colors-list-container"
                  style={{
                    height: "70vh",
                    overflowY: "auto",
                    paddingRight: "10px",
                  }}
                >
                  <ColorsList
                    selectedTextArea={selectedTextArea}
                    handleLetterColorSelection={handleLetterColorSelection}
                    handleColorSelection={handleColorSelection}
                    textAreas={textAreas}
                  />
                </div>
                <Typography
                  className=" heading border-2 border-[#AFE9E0] m-2 py-4 cursor-pointer rounded-3xl font-black text-center items-center text-3xl hover-effect"
                  style={{
                    fontFamily: "Beatrice-Display",
                    fontStyle: "italic",
                  }}
                  onClick={() => {
                    handleSplitButtonClick();
                  }}
                >
                  Split into letters
                </Typography>
              </Col>
              <Col xs={12} md={12} lg={10} sm={12}>
                <div
                  draggable="false"
                  className="border-2 border-[#AFE9E0] rounded-3xl p-4"
                  style={{
                    backgroundColor: `${theme}`,
                  }}
                >
                  <div className="price-container">
                    <Typography
                      style={{
                        fontFamily: "Beatrice-Display",
                        color: "#25CCB0",
                        fontSize: "40px",
                        fontWeight: "700",
                        fontStyle: "italic",
                        letterSpacing: "0.05em",
                      }}
                    >
                      {examples ? (
                        <>Examples</>
                      ) : (
                        <>
                          {textAreas[0]?.isSplit ? (
                            <>Combine Colors</>
                          ) : (
                            <>Pick a Color</>
                          )}
                        </>
                      )}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start", // Align items to the right for both mobile and desktop
                        justifyContent: "flex-start", // Align content to the bottom of the container
                        marginTop: "10px", // Add space between text and price
                      }}
                      className="text-end"
                    >
                      <Typography
                        style={{ fontFamily: "Beatrice" }}
                        className="text-[#07AB90]"
                      >
                        Price Estimate
                      </Typography>
                      <Typography
                        style={{ fontFamily: "Beatrice" }}
                        className="text-[#07AB90] text-md-end text-3xl"
                      >
                        ${cost}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Container>
                      <Row>
                        {examples ? (
                          <>
                            <Card
                              onClick={() => dispatch(nextStep())}
                              className="border-none rounded-3xl m-2"
                              style={{
                                height: "450px",
                              }}
                            >
                              <Card.Body>
                                <Card.Text className="text-[#8F8F8F]">
                                  No examples
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </>
                        ) : (
                          <>
                            <Col
                              xs={12}
                              md={11}
                              style={{
                                backgroundColor: `${theme}`,
                                color: !theme,
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: `${theme}`,
                                  display: "flex",
                                  flexDirection: `${product.fontsAlignment}`,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "68vh",
                                  width: "100%",
                                  position: "relative",
                                  overflow: "hidden",
                                }}
                              >
                                {textAreas?.map((textArea, index) => (
                                  <Draggable
                                    // disabled

                                    bounds="parent"
                                    key={index}
                                    defaultPosition={
                                      positions[textArea?.id] || {
                                        x: 0,

                                        y: 0,
                                      }
                                    }
                                    onDrag={(e, data) =>
                                      handleDragDrop(e, data, textArea?.id)
                                    }
                                    onStop={(e, data) =>
                                      handleDragDrop(e, data, textArea?.id)
                                    }
                                  >
                                    <div
                                      style={
                                        {
                                          // display: "flex",
                                          // flexDirection: "row",
                                          // justifyContent: "center",
                                        }
                                      }
                                      onClick={() =>
                                        setSelectedTextArea(textArea.id)
                                      }
                                    >
                                      {textArea?.isSplit ? (
                                        <>
                                          <button
                                            style={{
                                              color: "#4EBFFF",

                                              height: "10px",

                                              fontSize: "10px",
                                            }}
                                            onClick={handleMergeButtonClick}
                                          >
                                            Cancel Split
                                          </button>

                                          <div
                                            // className="led color"

                                            style={{
                                              display: "flex",

                                              flexDirection: "row",

                                              justifyContent: "center",
                                            }}
                                          >
                                            {textArea?.letters?.map(
                                              (letterObj, letterIndex) => (
                                                <Card
                                                  key={letterIndex}
                                                  style={{
                                                    background: "none",
                                                    fontSize: "50px",
                                                    padding: "5px",
                                                    margin: "5px",
                                                    fontFamily:
                                                      textArea.fontFamily,
                                                    color: letterObj.color,
                                                    border: `1px solid ${selectedLetter ===
                                                        letterIndex
                                                        ? "#4EBFFF"
                                                        : "#AFE9E0"
                                                      }`,
                                                    borderRadius: "0px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    setSelectedLetter(
                                                      letterIndex
                                                    );

                                                    console.log(letterIndex);
                                                  }}
                                                >
                                                  {letterObj.letter}
                                                </Card>
                                              )
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="led color"
                                            style={{
                                              // display: "flex",

                                              // flexDirection: "column",

                                              // justifyContent: "center",

                                              display: "inline-block",

                                              position: "relative",

                                              // left: positions[textArea.id]?.x,

                                              // top: positions[textArea.id]?.y,

                                              color: `${textArea.color}`,

                                              textShadow: `1px 2px 10px ${textArea.color}, 0 0 50px ${textArea.color}, 0 0 50px ${textArea.color}`,
                                            }}
                                          >
                                            <div
                                              contentEditable={false}
                                              // rows="1"

                                              maxLength={100}
                                              // ref={(ref) =>

                                              //   (textareaRefs.current[index] = ref)

                                              // }

                                              key={textArea.inputWidth}
                                              placeholder={"Type Something"}
                                              onChange={(event) => {
                                                handleTextAreaChange(
                                                  textArea.id,

                                                  event
                                                );

                                                updateTextareaHeight(index);
                                              }}
                                              value={textArea.value}
                                              style={{
                                                // color: `${textArea.color}`,

                                                // opacity: "0.35",

                                                // backgroundColor: `${textArea.color}35`,

                                                // boxShadow: `0 0 20px 20px ${textArea.color}35`,

                                                // borderRadius: "25px",

                                                // textShadow: `1px 2px 10px rgba(1, 31, 117, 0.25), 0 0 50px ${textArea.color}, 0 0 50px ${textArea.color}`,

                                                fontFamily: `${textArea.fontFamily}`,

                                                outline: "none",

                                                fontSize: "50px",

                                                wordWrap: "break-word",

                                                whiteSpace: "pre-line",

                                                wordBreak: "break-word",

                                                // overflowWrap: "break-word",

                                                resize: "none",

                                                position: "relative",

                                                cursor: "pointer",

                                                verticalAlign: `${textArea.verticalAlign}`,

                                                textAlign: `${textArea.horizontalAlign}`,

                                                border:
                                                  selectedTextArea ===
                                                    textArea.id
                                                    ? "1px solid #4EBFFF"
                                                    : "none",

                                                zIndex: 2,
                                              }}
                                              onClick={(textArea) => {
                                                handleTextAreaClick(
                                                  textArea.id
                                                );
                                              }}
                                            >
                                              {textArea.value}
                                            </div>

                                            {/* <div className="display square"></div> */}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </Draggable>
                                ))}
                              </div>
                            </Col>
                            <Col
                              className="flex flex-col justify-end items-center"
                              xs={12}
                              md={1}
                            >
                              {product.source === "LED" ? (
                                <>
                                  <button onClick={() => setShowRGB(true)}>
                                    <img src={RGBIcon} alt="" />
                                  </button>

                                  <RainbowColor
                                    textAreas={textAreas}
                                    handleLetterColorSelection={
                                      handleLetterColorSelection
                                    }
                                    handleColorSelection={handleColorSelection}
                                    selectedColor={textAreas[0]?.color}
                                    setSelectedColor={setTextAreas}
                                  />
                                </>
                              ) : (
                                <></>
                              )}

                              <button onClick={handleDescriptionClick}>
                                <DescriptionIcon />
                              </button>

                              <Popover
                                sx={{
                                  "& .MuiPaper-root": {
                                    borderRadius: "16px",

                                    backgroundColor: "#F4F4F4",
                                  },
                                }}
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleDescriptionClose}
                                anchorOrigin={{
                                  vertical: "top",

                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "bottom",

                                  horizontal: "right",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#F4F4F4",

                                    margin: "10px",

                                    borderRadius: "16px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",

                                      justifyContent: "space-between",

                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        color: "#595959",

                                        fontFamily: "Beatrice",

                                        fontWeight: "600",

                                        fontSize: "15px",
                                      }}
                                    >
                                      Anything we should know?
                                    </Typography>

                                    <button
                                      style={{}}
                                      onClick={handleDescriptionClose}
                                    >
                                      <Close />
                                    </button>
                                  </div>

                                  <div
                                    style={{
                                      background: "white",

                                      borderRadius: "16px",

                                      paddingBottom: "15px",
                                    }}
                                  >
                                    <textarea
                                      style={{
                                        padding: "10px",

                                        borderRadius: "16px",

                                        height: "200px",

                                        width: "100%",

                                        color: "#8F8F8F",

                                        outline: "none",

                                        resize: "none",
                                      }}
                                      value={desc}
                                      placeholder="Add Description"
                                      onChange={(e) => setDesc(e.target.value)}
                                    ></textarea>

                                    <div
                                      style={{
                                        margin: "0px",

                                        padding: "0px",

                                        position: "relative",

                                        float: "right",

                                        bottom: "20px",

                                        right: "15px",
                                      }}
                                    >
                                      <button
                                        onClick={() => {
                                          dispatch(
                                            setProductColorDescription(desc)
                                          );

                                          handleDescriptionClose();
                                        }}
                                      >
                                        <Send />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Popover>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        <BottomControls />
                      </Row>
                    </Container>
                  </div>
                </div>
              </Col>
            </Row>
            {showExamples && <ExampleModal show={true} setShow={setShow} />}
          </>
        )}
      </div>
    </>
  );
};

export default EditColor;
