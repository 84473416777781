import React from "react";
import { useSelector } from "react-redux";

const Dimmer = ({ color }) => {
  const product = useSelector((state) => state.activeStep.product);

  return (
    <>
      {product.source === "LED" ? (
        <svg
          width="35"
          height="182"
          viewBox="0 0 35 182"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_i_3516_47757)">
            <path
              d="M13.4612 161.372C11.0588 167.931 7.60584 174.454 7.51744 181.115C8.02473 181.116 8.53315 181.118 9.04044 181.119L10.2794 181.123L11.1959 181.126L12.1992 181.13C12.2876 174.469 15.7405 167.945 18.1429 161.387C19.3407 158.118 20.2781 154.824 20.3634 151.513C20.4486 148.205 19.6354 144.903 18.5132 141.626C16.4983 135.742 13.4607 129.899 12.6421 123.962C11.8236 118.022 14.0214 112.166 16.3916 106.33L16.4254 106.246C18.0923 102.143 19.7659 98.0227 20.3662 93.8717L15.6817 93.8717C15.0795 98.0152 13.4098 102.128 11.7467 106.224L11.7099 106.315C9.33964 112.151 7.14182 118.007 7.9604 123.947C8.77786 129.884 11.8165 135.727 13.8314 141.611C14.9537 144.888 15.7669 148.19 15.6817 151.498C15.5975 154.809 14.6589 158.102 13.4612 161.372Z"
              fill="black"
            />
          </g>
          <g filter="url(#filter1_i_3516_47757)">
            <path
              d="M10.2068 71.4505C12.3243 77.3235 15.2897 83.1735 15.8422 89.1138C16.027 91.102 15.8743 93.0817 15.5007 95.0547L20.186 95.0547C20.5575 93.0866 20.7086 91.1119 20.524 89.1288C19.9714 83.189 17.0049 77.3385 14.8885 71.4656C13.8337 68.5372 12.9773 65.5929 12.7433 62.6361C12.5092 59.6866 12.9988 56.7431 13.8661 53.8175C15.6131 47.9218 18.7904 42.1092 20.1824 36.1938C21.579 30.26 20.1795 24.4214 17.9154 18.5416C15.6581 12.6822 12.9498 6.82308 12.9181 0.880475L11.9148 0.877156L10.9982 0.874125L9.75933 0.870027L8.23633 0.86499C8.26802 6.8076 10.9763 12.6667 13.2337 18.5266C15.4977 24.4059 16.8962 30.2449 15.5007 36.1788C14.1087 42.0937 10.9314 47.9068 9.18434 53.8025C8.31711 56.7276 7.82746 59.6716 8.06153 62.6211C8.29557 65.5778 9.15195 68.5222 10.2068 71.4505Z"
              fill="#E5E1E1"
            />
          </g>
          <rect
            x="0.572266"
            y="95.0715"
            width="33.5986"
            height="46.8242"
            rx="10"
            fill="black"
          />
          <defs>
            <filter
              id="filter0_i_3516_47757"
              x="7.51758"
              y="93.8716"
              width="12.8516"
              height="91.2583"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_3516_47757"
              />
            </filter>
            <filter
              id="filter1_i_3516_47757"
              x="8.00391"
              y="0.86499"
              width="12.7363"
              height="98.1897"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_3516_47757"
              />
            </filter>
          </defs>
        </svg>
      ) : (
        <svg
          draggable="true"
          xmlns="http://www.w3.org/2000/svg"
          width="71"
          height="182"
          viewBox="0 0 71 182"
          fill="none"
        >
          <g filter="url(#filter0_i_1550_47183)">
            <path
              d="M28.2855 180.966C28.3739 174.305 31.8268 167.782 34.2292 161.223C35.427 157.954 36.3656 154.66 36.4497 151.349C36.535 148.042 35.7217 144.739 34.5995 141.462C32.5846 135.578 29.5459 129.736 28.7285 123.798C27.9099 117.859 30.1077 112.003 32.4779 106.166C34.7981 100.451 37.1465 94.7052 36.6059 88.8875C36.0534 82.9472 33.088 77.0971 30.9705 71.2242C29.9156 68.2959 29.0592 65.3515 28.8252 62.3948C28.5911 59.4453 29.0808 56.5013 29.948 53.5762C31.6951 47.6804 34.8724 41.8674 36.2644 35.9524C37.6598 30.0186 36.2614 24.1796 33.9974 18.3003C31.74 12.4404 29.0317 6.58128 29 0.638672C29.5073 0.64035 30.0157 0.642031 30.523 0.643709C30.9356 0.645074 31.3493 0.646442 31.7619 0.647807C32.0674 0.648817 32.3729 0.649827 32.6784 0.650838C33.0132 0.651945 33.3469 0.653049 33.6817 0.654156C33.7134 6.59676 36.4217 12.4559 38.6791 18.3153C40.9431 24.1951 42.3427 30.0336 40.9461 35.9675C39.5541 41.8828 36.3768 47.6955 34.6297 53.5912C33.7625 56.5168 33.2729 59.4603 33.5069 62.4098C33.741 65.3665 34.5974 68.3109 35.6522 71.2392C37.7686 77.1122 40.7351 82.9627 41.2876 88.9025C41.8294 94.7198 39.4809 100.466 37.1597 106.181C34.7894 112.018 32.5916 117.874 33.4102 123.813C34.2288 129.751 37.2663 135.594 39.2812 141.477C40.4035 144.754 41.2167 148.056 41.1315 151.364C41.0462 154.675 40.1087 157.969 38.9109 161.238C36.5086 167.797 33.0556 174.32 32.9672 180.981C32.6324 180.98 32.2987 180.979 31.9639 180.978C31.6584 180.977 31.3529 180.976 31.0474 180.975C30.6348 180.973 30.2211 180.972 29.8085 180.971C29.3012 180.969 28.7928 180.968 28.2855 180.966Z"
              fill={color}
            />
          </g>
          <rect
            y="112"
            width="70.5254"
            height="46.8242"
            fill={color}
            stroke="black"
          />
          <defs>
            <filter
              id="filter0_i_1550_47183"
              x="28.2852"
              y="0.638672"
              width="13.2188"
              height="184.342"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_1550_47183"
              />
            </filter>
          </defs>
        </svg>
      )}
    </>
  );
};

export default Dimmer;
