import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const opentype = require("opentype.js");


import { getBrowserInfo } from "../utils/client/calculations";



const useCheckout = () => {
    // Redux
    const InstallationPrice = useSelector(
        (state) => state.activeStep.product.installationPrice
      );

    const product = useSelector((state) => state.activeStep.product);
    const colors = useSelector((state) => state.activeStep.colors);

    const backingColors = useSelector((state) => state.activeStep.backingColors);
    const cables = useSelector((state) => state.activeStep.cables);

    const power = useSelector((state) => state.activeStep.power);
    const powerQuantity = useSelector((state) => state.activeStep.powerQuantity);

    const shippingCost = useSelector(
        (state) => state.activeStep.product.shippingCost
    );

    const cost = useSelector((state) => state.activeStep.product.cost);

    const fonts = useSelector((state) => state.activeStep.product.fonts);
    const backing = useSelector((state) => state.activeStep.product.backing);

    const size = useSelector((state) => state.activeStep.product.size);

    const deliveryDetails = useSelector(
        (state) => state.activeStep.product.delivery.details
    );

    const factor = useSelector((state) => state.activeStep.materialLengthFactor);
    const lockedWidth = useSelector(
        (state) => state.activeStep.product.backing.lockedWidth
    );

    const lockedHeight = useSelector(
        (state) => state.activeStep.product.backing.lockedHeight
    );

    const fontColors = fonts.flatMap((font) =>
        font.isSplit ? font.letters.map((letter) => letter.color) : font.color
    );

    // Other
    const date = new Date();


    const matchingColors = colors.filter((color) =>
        fontColors.includes(color.code)
    );

    const fontFamilyArray = fonts.map((font) => font.fontFamily);

    const colorImages = matchingColors.map((color) => color.imageOn);

    const WH = `W${Number(Math.ceil(size?.width)?.toFixed(0)) +
        Number(product.backing.width) +
        product.backing.lockedWidth
        }xH${Number(Math.ceil(size?.height)?.toFixed(0)) +
        Number(product.backing.height) +
        product.backing.lockedHeight
        }`;

    let backingStyle = "";
    let wireColor = "black";

    const savedDrawingData =
        JSON.parse(localStorage.getItem("savedDrawing")) || "";

    // State
    const [weightPerCubicInch, setWeightPerCubicInch] = useState(0);

    // Methods

    const getWeight = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}delivery/shippings/${product.source}/${product.backing.name}`
          );
          if (response?.data[0]) {
            setWeightPerCubicInch(response?.data[0]?.signWeightPerSqinch);
          }
        } catch (err) {
          console.log(err);
        }
      };

    const calculateFontArea = async (value, font, jsonLink) => {
        let totalWidth = 0;
        let materialLength = 0;
        let maxHeight = 0;
        let currentMaxHeight = 0;

        if (font) {
            try {
                const response = await fetch(jsonLink);
                const fontData = await response.json();

                if (value) {
                    for (const char of value) {
                        if (char === " ") {
                            const spaceCharacter = fontData.find(
                                (data) => data.char === "space"
                            );
                            if (spaceCharacter) {
                                const width = spaceCharacter.width / 96;
                                totalWidth += width;
                                materialLength += spaceCharacter.length / 96;
                            }
                        } else if (char === "\n") {
                            maxHeight += currentMaxHeight;
                            currentMaxHeight = 0;
                        } else {
                            const character = fontData.find((data) => data.char === char);
                            if (character) {
                                const width = character.width / 96;
                                totalWidth += width;

                                const height = character.height / 96;
                                materialLength += character.length / 96;
                                if (height > currentMaxHeight) {
                                    currentMaxHeight = height;
                                }
                            }
                        }
                    }
                }

                maxHeight += currentMaxHeight;
                materialLength *= factor;
                return { materialLength, totalWidth, maxHeight };
            } catch (error) {
                console.error("Error fetching font data:", error);
            }
        }
    };

    const calculateKerning = async (woffFile, text) => {
        try {
            const response = await axios.get(woffFile, {
                responseType: "arraybuffer",
            });
            const fontDataArrayBuffer = response.data;
            const fontData = new Uint8Array(fontDataArrayBuffer);
            const font = opentype.parse(fontData.buffer);
            // Convert the text into an array of glyphs
            const glyphs = [];
            for (let i = 0; i < text.length; i++) {
                const char = text[i];
                const glyph = font.charToGlyph(char);
                glyphs.push(glyph);
            }

            // Calculate kerning for each character
            let totalKerningValue = 0;
            for (let i = 0; i < glyphs.length - 1; i++) {
                const pair1 = glyphs[i];
                const pair2 = glyphs[i + 1];
                const kerningValue = font.getKerningValue(pair1, pair2);
                totalKerningValue += kerningValue;
            }

            if (totalKerningValue !== 0) {
                return totalKerningValue / 96;
            } else {
                return totalKerningValue;
            }
        } catch (error) {
            console.error("Error in calculateKerning:", error);
            throw error;
        }
    };

    const calculateLetterLength = async (letter, font, jsonLink) => {
        if (!font) {
            // Handle error when font is not selected
            return 0;
        }

        try {
            const response = await fetch(jsonLink);
            const fontData = await response.json();

            let width = 0;
            let height = 0;
            let materialLength = 0;

            if (letter === " ") {
                const spaceCharacter = fontData.find((data) => data.char === "space");
                if (spaceCharacter) {
                    width = spaceCharacter.width / 96;
                    height = spaceCharacter.height / 96;
                    materialLength = spaceCharacter.length / 96;
                }
            } else {
                const character = fontData.find((data) => data.char === letter);
                if (character) {
                    width = character.width / 96;
                    height = character.height / 96;
                    materialLength = character.length / 96;
                }
            }
            materialLength *= factor;

            return { width, height, materialLength };
        } catch (error) {
            console.error("Error fetching font data:", error);
            return 0; // Return 0 or handle the error in an appropriate way
        }
    };

    const calculateTotalKerning = async (fonts) => {
        const kerningPromises = fonts.map(async (font) => {
            const kerning = await calculateKerning(font?.woffFile, font?.value);
            return kerning;
        });

        const kerningValues = await Promise.all(kerningPromises);

        const totalKerning = kerningValues.reduce((sum, value) => sum + value, 0);

        return totalKerning;
    };

    const getProductForOrder = async (orderType, svgLink, png) => {

        let concatenatedValues = "";

        product.fonts.forEach((font) => {
            concatenatedValues += font.value + " ";
        });

        let colorNames = fontColors.map((code) => {
            let found = colors.find((color) => color.code === code);
            return found ? found.name : "custom";
        });

        if (savedDrawingData) {
            const colorCodes = savedDrawingData.map((item) => item.strokeColor);
            const matchingColors = colors.filter((color) =>
                colorCodes.includes(color.code)
            );

            matchingColors.forEach((color) => {
                if (!colorNames.includes(color.name)) {
                    colorNames.push(color.name);
                }
            });
        }

        let uniqueColorNamesSet = new Set(colorNames);
        let uniqueColorNamesArray = Array.from(uniqueColorNamesSet);

        let backingColor = backingColors.find(
            (color) => color.code === product.backing.color
        );
        let accessoryColor = cables.find(
            (cable) => cable.color === product.accessories.wireColor
        );

        let fontsDimensions = [];
        for (const font of product.fonts) {
            const dimensions = await calculateFontArea(
                font.value,
                font.fontFamily,
                font.jsonLink
            );
            fontsDimensions.push({
                fontFamily: font.fontFamily,
                value: font.value,
                ...dimensions,
            });
        }

        let letterDimensions = {};
        for (const font of product.fonts) {
            if (!letterDimensions[font.value]) {
                // Initialize the nested object for the font.value
            }

            for (const char of font.value) {
                const key = `${font.value}_${font.fontFamily}`; // Create a unique key for each font
                if (!letterDimensions[key]) {
                    letterDimensions[key] = {}; // Initialize the nested object for the font
                }

                const letterDimension = await calculateLetterLength(
                    char,
                    font.fontFamily,
                    font.jsonLink
                );

                letterDimensions[key][char] = letterDimension;
            }
        }

        // Maybe not on submit
        let totalKerning = 0;

        const userAgent = getBrowserInfo();
        if (userAgent !== "Apple Safari") {
            totalKerning = await await calculateTotalKerning(product?.fonts);
        }

        let weight = 0;

        if (product.source === "LED") {
            weight =
                (Number(size.width) + backing.width + backing.lockedWidth + 3) *
                (Number(size.height) + backing.height + backing.lockedHeight + 3) *
                (Number(size.depth) + 3) *
                weightPerCubicInch;
        } else {
            weight =
                (Number(size.width) + backing.width + backing.lockedWidth + 5) *
                (Number(size.height) + backing.height + backing.lockedHeight + 5) *
                (Number(size.depth) + 5) *
                weightPerCubicInch;
        }



        concatenatedValues = concatenatedValues.trim();


        let updatedProduct = {
            ...product,
            fontsColor: uniqueColorNamesArray,
            backingColor: backingColor ? backingColor?.name : "Custom Color",
            wireColor: accessoryColor?.name,
            widthInches: product.size.width + product.backing.width + lockedWidth,
            HeightInches:
                product.size.height + product.backing.height + lockedHeight,
            png: png,
            cost: cost + shippingCost + InstallationPrice,
            concatenatedValues: concatenatedValues,
            productionImage: svgLink ? svgLink : "N/A",
            userCreatedProductImage: png,
            customShapeImage: product.backing.image,
            deliveryDate: product.shippingTime ? product.shippingTime : "N/A",
            orderDate: date.toISOString(),
            customerName: deliveryDetails?.name,
            customerEmail: deliveryDetails?.email,
            customerContactNumber: deliveryDetails?.phoneNumber,
            customerAddress: deliveryDetails?.address,
            customerCountry: deliveryDetails?.country
                ? deliveryDetails?.country
                : "US",
            customerProvince: deliveryDetails?.state,
            customerCity: deliveryDetails?.city,
            customerZipCode: deliveryDetails?.zip,
            totalWeight: weight,
            fontsDimensions: fontsDimensions,
            WordDimensions: letterDimensions,
            power: power,
            powerQuantity: powerQuantity,
            kerning: totalKerning,
            drawLines: localStorage.getItem("savedDrawing"),
            orderType: orderType,
            fullfillmentStatus: orderType,

        };

        console.log(updatedProduct);
        return updatedProduct;
    };

    return {
        product,
        backing,
        backingColors,
        backingStyle,
        fonts,
        fontColors,
        colors,
        cables,
        power,
        cost,
        deliveryDetails,
        powerQuantity,
        shippingCost,
        weightPerCubicInch,
        fontFamilyArray,
        wireColor,
        colorImages,
        WH,
        getWeight,
        calculateFontArea,
        calculateKerning,
        calculateLetterLength,
        calculateTotalKerning,
        getProductForOrder
    }
};


export default useCheckout;