import { Button, Checkbox, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import SelectedCard from "../Components/Review/SelectedCard";
import Notes from "../Components/Review/Notes";
import BasicInfo from "../Components/Review/BasicInfo";
import DeliveryInfo from "../Components/Review/DeliveryInfo";
import Preview from "../Components/Review/Preview";
import PriceDetails from "../Components/Review/PriceDetails";
import Save from "../Components/Review/Save";
import Share from "../Components/Review/Share";
import ImageCard from "../Components/Review/ImageCard";
import BackingStyleCard from "../Components/Review/BackingStyleCard";
import BackingColorCard from "../Components/Review/BackingColorCard";
import AccessoriesCard from "../Components/Review/AccessoriesCard";
import SizeCard from "../Components/Review/SizeCard";
import AddOnCard from "../Components/Review/AddOnCard";
import ControllerCard from "../Components/Review/ControllerCard";
import { fetchHelp } from "../Redux/Slice";
import axios from "axios";
import RefImageCard from "../Components/Review/RefImageCard";
import "./styles/review.css";
import ThankYouModal from "../Components/Review/ThankYouModal";
import SuperPreview from "../Components/Review/SuperPreview";
import SubmitInquiry from "../Components/Review/SubmitInquiry";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";

import useCheckout from "../Hooks/useCheckout";
import useSVG from "../Hooks/useSVG";
import usePNG from "../Hooks/usePNG";


const Review = () => {

  const [openThankYou, setOpenThankYou] = useState(false);
  const [errors, setErrors] = useState({});
  const [toc, setTOC] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showSave, setShowSave] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showInquiry, setShowInquiry] = useState(false);

  const [warningOne, setWarningOne] = useState({});
  const [warningTwo, setWarningTwo] = useState({});

  const {
    product,
    fonts,
    colors,
    backing,
    backingColors,
    cables,
    fontColors,
    power,
    cost,
    deliveryDetails,
    powerQuantity,
    shippingCost,
    weightPerCubicInch,
    fontFamilyArray,
    backingStyle,
    wireColor,
    colorImages,
    WH,
    getWeight,
    calculateFontArea,
    calculateLetterLength,
    calculateTotalKerning,
    getProductForOrder,
  } = useCheckout();

  const {
    svgLink,
    fetchSVGs,
  } = useSVG();

  const {
    png,
    refImage,
    convertToPNG,
  } = usePNG();

  const fetchWarnings = async (type, number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}warning-pages/Review/${type}/${number}`
      );
      switch (number) {
        case 1:
          setWarningOne(response.data);
          break;
        case 2:
          setWarningTwo(response.data);
          break;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };


  const format = useSelector((state) => state.activeStep.product.format);
  const source = useSelector((state) => state.activeStep.product.source);
  const dimmer = useSelector(
    (state) => state.activeStep.product.accessories.dimmer
  );

  const dispatch = useDispatch();

  const validateForm = () => {
    const errors = {};
    if (!deliveryDetails?.name) {
      errors.name = "Name is required";
    }
    if (toc === false) {
      errors.toc = "Please check this box";
    }
    if (!deliveryDetails?.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(deliveryDetails?.email)) {
      errors.email = "Email is invalid";
    }
    if (!deliveryDetails?.phoneNumber) {
      errors.phoneNumber = "Contact is required";
    } else if (
      !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(
        deliveryDetails?.phoneNumber
      )
    ) {
      errors.phoneNumber = "Contact is invalid";
    }

    if (Object.keys(errors).length > 0) {
      console.log(errors);
      setErrors(errors);
    } else {
      setErrors({});
    }
    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      let updatedProduct = await getProductForOrder("submission", svgLink, png);
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}submission/${updatedProduct?.customerEmail}`,
        updatedProduct
      );
      if (response.data) {
        setOpenThankYou(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  const handleCheckout = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      return;
    }
    try {
      setLoading(true);
      const orderProduct = await getProductForOrder("draft", svgLink, png);

      let customerId;

      // Get / Create Shopify Customer
      const customers = await getShopifyCustomer(orderProduct.customerEmail);
      if (customers.length > 0) {
        customerId = customers[0].id;
      } else {
        // Create Shopify Customer
        const customer = await createShopifyCustomer(orderProduct);
        customerId = customer.id
      }

      // Create Shopify Product
      const { product: shopifyProduct } = await createShopifyProduct(orderProduct);
      const variant = shopifyProduct.variants[0];
      const { id: variantId, sku: orderNumber } = variant;

      // Create Shopify Draft Order
      const shopifyDraftOrder = await createShopifyDraftOrder(orderProduct, variantId, customerId);
      const { id: shopifyDraftOrderId, invoice_url } = shopifyDraftOrder;

      const orderType = "draftOrder";
      const parentType = "ShopifyOrder"

      // Create Order
      await createOrder(orderProduct, orderNumber, orderType, parentType, shopifyDraftOrderId);

      // Testing Create Draft Order
      // const draftOrder = await createDraftOrder(orderProduct, shopifyDraftOrder);

      setLoading(false);
      // Get Checkout Link and open
      window.open(invoice_url, "_self", "noreferrer");

    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error?.response?.data?.errors) {
        const errors = error?.response?.data?.errors;
        Object.keys(errors).map((key) => {
          const error = errors[key][0]
          toast.error(error);
        });
      } else {
        toast.error(error.message);
      }
    }
  };

  const getShopifyCustomer = async (email) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}shopify/customers/?email=${email}`,

    );
    return response.data;
  }

  const createOrder = async (orderProduct, orderNumber, orderType, parentType, parentOrderId) => {
    const order = {
      ...orderProduct,
      orderNumber,
      orderType,
      parentType,
      parentOrderId,
      active: true,
      paymentStatus: "pending",
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}orders`,
      order
    );
    return response.data;
  };

  // eslint-disable-next-line no-unused-vars
  const createDraftOrder = async (orderProduct, shopifyDraftOrder) => {
    const {
      concatenatedValues,
      customerEmail,
      customerName,
      deliveryDate,
      orderDate,
      customShapeImage,
      fontsDimensions,
      wordsDimensions,
      power,
      powerQuantity,
      refImage,
      totalWeight,
      fullfillmentStatus
    } = orderProduct;
    const draftOrder = {
      ...shopifyDraftOrder,
      concatenatedValues,
      customerEmail,
      customerName,
      deliveryDate,
      orderDate,
      customShapeImage,
      fontsDimensions,
      wordsDimensions,
      power,
      powerQuantity,
      refImage,
      totalWeight,
      fullfillmentStatus
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}orders/draft`,
      draftOrder
    );

    return response.data;
  }

  const createShopifyProduct = async (orderProduct) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}shopify/products`,
      orderProduct
    );
    return response.data;
  };

  const createShopifyCustomer = async (orderProduct) => {
    const customer = {
      first_name: orderProduct.customerName,
      last_name: "",
      email: orderProduct.customerEmail,
      phone: orderProduct.customerContactNumber,
      verifiedemail: false,
      addresses: []
    };

    if (orderProduct.customerAddress) {
      customer.addresses.push({
        address1: orderProduct.customerAddress,
        country: orderProduct.customerCountry,
        province: orderProduct.customerProvince,
        city: orderProduct.customerCity,
        zip: orderProduct.customerZipCode,
      })
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}shopify/customers`,
      customer
    );
    return response.data;
  }

  const createShopifyDraftOrder = async (orderProduct, variantId, customerId) => {
    const request = {
      product: orderProduct,
      variantId,
      customerId,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}shopify/draft-orders`,
      request
    );
    return response.data;
  }

  // Effects

  useEffect(() => {
    fonts.forEach((textArea) => {
      fetchSVGs(textArea);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    convertToPNG();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchHelp(`Review/${product.source}`));
    fetchWarnings(product.source, 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    getWeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mt-3 margin-adjust ">
        <div
          style={{
            boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.20)",
          }}
          className="border-2 border-[#AFE9E0] rounded-3xl  p-4"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: window.innerWidth > 768 ? "40px" : "2rem",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Review Your Inquiry
            </Typography>
            <div
              style={{
                width: "40%",
                height: "10vh",
                display: warningOne?.warningStatus ? "" : "none",
                zIndex: 9,
              }}
              className="warning"
            >
              <Typography className="warning-title">
                {warningOne?.title}
              </Typography>
              <div
                className="enable-listing-style warning-desc"
                dangerouslySetInnerHTML={{
                  __html: warningOne?.warningContent,
                }}
              ></div>
            </div>
          </div>
          <Row style={{ marginTop: "20px" }}>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <SelectedCard heading={"Format"} selection={source} />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <SelectedCard heading={"Content"} selection={fontFamilyArray} />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <ImageCard
                heading={"Colors"}
                selection={colorImages}
                fontColors={fontColors}
              />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <BackingStyleCard
                heading={"Backing Style"}
                selection={backingStyle}
              />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <BackingColorCard
                heading={"Backing Color"}
                selection={backing.color}
              />
            </Col>

            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <SizeCard heading={"Size"} selection={WH} />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <AccessoriesCard heading={"Accessories"} selection={wireColor} />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <ControllerCard heading={"Controllers"} selection={dimmer} />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <AddOnCard heading={"Add-ons"} />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <RefImageCard heading={"Reference Image"} selection={refImage} />
            </Col>
          </Row>
          <Notes />
          <Row>
            <Col
              xs={12}
              md={product.format === "custom" ? 8 : 9}
              lg={product.format === "custom" ? 8 : 9}
              sm={12}
              style={{ paddingRight: window.innerWidth > 762 ? "30px" : "" }}
            >
              <BasicInfo errors={errors} />

              {product?.delivery?.deliveryType === "pickup" ? (
                <></>
              ) : (
                <>
                  <DeliveryInfo />
                </>
              )}
              {product.format === "super-custom" ? (
                <SuperPreview
                  id={product?.format === "super-custom" ? "divToConvert" : ""}
                />
              ) : (
                <>
                  <Preview
                    id={product?.format === "custom" ? "divToConvert" : ""}
                  />
                </>
              )}
            </Col>
            <Col
              md={product.format === "custom" ? 4 : 3}
              lg={product.format === "custom" ? 4 : 3}
              xs={12}
              sm={12}
              style={{ paddingLeft: "30px" }}
            >
              {product.format === "super-custom" ||
                product.backing.name === "custom" ||
                product.delivery.deliveryType === "rush" ? (
                <></>
              ) : (
                <>
                  <PriceDetails />
                </>
              )}
              <div
                style={{
                  marginTop: "20px",
                  height: "auto",
                  display: warningTwo?.warningStatus ? "" : "none",
                }}
                className="warning"
              >
                <Typography className="warning-title">
                  {warningTwo?.title}
                </Typography>
                <div
                  className="enable-listing-style warning-desc"
                  dangerouslySetInnerHTML={{
                    __html: warningTwo?.warningContent,
                  }}
                ></div>
              </div>
              <Row
                style={{
                  margin: "0px",
                  marginTop: "50px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "0px",
                  }}
                >
                  <Checkbox
                    sx={{
                      color: "#AFE9E0",
                      "&.Mui-checked": {
                        color: "#9EA0DC",
                      },
                    }}
                    value={toc}
                    onChange={() => {
                      setTOC(!toc);
                    }}
                  />
                  <Typography
                    style={{
                      color: "black",
                      fontFamily: "Beatrice",
                      textAlign: "left",
                      fontWeight: "400",
                    }}
                  >
                    *I have read all the{" "}
                    <a
                      style={{
                        color: "#BF6BE3",
                        fontFamily: "Beatrice",
                        fontWeight: "400",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://glo-studio.com/policies/terms-of-service"
                    >
                      Terms of Service
                    </a>

                  </Typography>
                </div>
                {errors.toc && (
                  <div
                    style={{
                      color: "red",
                    }}
                  >
                    Please check this box to proceed
                  </div>
                )}
              </Row>
              <Row style={{ margin: "0px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "0px",
                  }}
                >
                  <Checkbox
                    sx={{
                      color: "#AFE9E0",
                      "&.Mui-checked": {
                        color: "#9EA0DC",
                      },
                    }}
                  />
                  <Typography
                    style={{
                      color: "black",
                      textAlign: "left",
                      fontFamily: "Beatrice",
                      fontWeight: "400",
                    }}
                  >
                    Yes, add me to your newsletter!
                  </Typography>
                </div>
              </Row>
              {product.format === "super-custom" ||
                product.backing.name === "custom" ||
                product.delivery.deliveryType === "rush" ? (
                <>
                  {png ? (
                    <>
                      <Button
                        className=" hover-effect"
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px solid #36CDB4",
                          borderRadius: "24px",
                          cursor: "pointer",
                          marginTop: "25px",
                          color: "#FFFFFF",
                          fontSize: window.innerWidth >= 768 ? "30px" : "1rem",
                          fontFamily: "Beatrice",
                          fontWeight: "600",
                          bgcolor: "#36CDB4",
                          padding: "25px",
                          ":hover": {
                            color: "#FFFFFF",
                            bgcolor: "#36CDB4",
                          },
                        }}
                        onClick={handleSubmit}
                      >
                        Proceed to Submission
                      </Button>
                    </>
                  ) : (
                    <>
                      <ThreeDots
                        width="100%"
                        radius="9"
                        color="#36CDB4"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </>
                  )}
                </>
              ) : (
                <>

                  {loading ? (
                    <>
                      <ThreeDots
                        width="100%"
                        radius="9"
                        color="#36CDB4"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        className="hover-effect"
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px solid #36CDB4",
                          borderRadius: "24px",
                          cursor: "pointer",
                          marginTop: "25px",
                          color: "#FFFFFF",
                          fontSize:
                            window.innerWidth >= 768 ? "30px" : "1rem",
                          fontFamily: "Beatrice",
                          fontWeight: "600",
                          bgcolor: "#36CDB4",
                          padding: "25px",
                          ":hover": {
                            color: "#FFFFFF",
                            bgcolor: "#36CDB4",
                          },
                        }}
                        onClick={handleCheckout}
                      >
                        Proceed to Checkout
                      </Button>
                      <Button
                        className=" hover-effect"
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px solid #AFE9E0",
                          borderRadius: "24px",
                          cursor: "pointer",
                          marginTop: "25px",
                          // fontSize: "32px",
                          // fontWeight: "400",
                          // fontFamily: "Beatrice",
                          // color: "#07AB90",
                          // textTransform: "none",
                          fontSize: "30px",
                          // width: "100%",
                          fontFamily: "Beatrice",
                          fontWeight: "600",
                          bgcolor: "#D8F4F1",

                          // borderRadius: "24px",
                          padding: "10px",

                          // marginTop: "25px",
                        }}
                        onClick={() => setShowInquiry(true)}
                      >
                        <Typography
                          style={{
                            color: "#07AB90",
                            fontSize:
                              window.innerWidth >= 768 ? "30px" : "1rem",
                            // width: "100%",
                            fontFamily: "Beatrice",
                            fontWeight: "600",

                            // borderRadius: "24px",
                          }}
                        >
                          Have Questions?
                        </Typography>
                        <Typography
                          style={{
                            color: "#07AB90",
                            fontSize:
                              window.innerWidth >= 768 ? "30px" : "1rem",
                            // width: "100%",
                            fontFamily: "Beatrice",
                            fontWeight: "400",
                            // borderRadius: "24px",
                          }}
                        >
                          Submit for a Quote
                        </Typography>
                      </Button>
                    </>
                  )}
                </>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  className=" hover-effect"
                  sx={{
                    width: "48%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid #AFE9E0",
                    borderRadius: "24px",
                    cursor: "pointer",
                    marginTop: "25px",
                    fontSize: window.innerWidth >= 768 ? "24px" : "0.75rem",
                    fontWeight: "400",
                    fontFamily: "Beatrice",
                    color: "#07AB90",
                    textTransform: "none",
                  }}
                  onClick={() => setShowSave(true)}
                >
                  Save Your Design
                </Button>
                <Button
                  className=" hover-effect"
                  sx={{
                    width: "48%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid #AFE9E0",
                    borderRadius: "24px",
                    cursor: "pointer",
                    marginTop: "25px",
                    fontSize: window.innerWidth >= 768 ? "24px" : "0.75rem",
                    fontWeight: "400",
                    fontFamily: "Beatrice",
                    color: "#07AB90",
                    textTransform: "none",
                  }}
                  onClick={() => setShowShare(true)}
                >
                  Share Your Design
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <SubmitInquiry
          calculateTotalKerning={calculateTotalKerning}
          errors={errors}
          setErrors={setErrors}
          backing={backing}
          backingColors={backingColors}
          cables={cables}
          calculateFontArea={calculateFontArea}
          calculateLetterLength={calculateLetterLength}
          colors={colors}
          cost={cost}
          deliveryDetails={deliveryDetails}
          fontColors={fontColors}
          fonts={fonts}
          format={format}
          png={png}
          power={power}
          powerQuantity={powerQuantity}
          shippingCost={shippingCost}
          source={source}
          weightPerCubicInch={weightPerCubicInch}
          svgLink={svgLink}
          link={png}
          showSave={showInquiry}
          closeSave={() => setShowInquiry(false)}
        />
        <Save
          weightPerCubicInch={weightPerCubicInch}
          svgLink={svgLink}
          link={png}
          showSave={showSave}
          closeSave={() => setShowSave(false)}
        />
        <Share
          link={png}
          showShare={showShare}
          closeShare={() => setShowShare(false)}
        />
        <ThankYouModal
          open={openThankYou}
          close={() => setOpenThankYou(false)}
        />
      </div>
    </>
  );
};

export default Review;
