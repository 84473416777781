import React from "react";
import Clear from "../Assets/Clear";

const ClearButton = () => {
  return (
    <button
      onClick={() => {
        // Clear local storage
        localStorage.clear();

        // Remove the class and change the button text back to "Clear Selection" after the delay
        setTimeout(() => {
          // Remove the scrolling class

          // Refresh the page after clearing
          window.location.reload();
        }, 3000); // Adjust the delay time as needed
      }}
    >
      <Clear />
    </button>
  );
};

export default ClearButton;
