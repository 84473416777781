import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import Close from "../../Assets/Close";

const BackingModal = ({ show, close }) => {
  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 768 ? "30%" : "80%",
    // height: "95vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };
  return (
    <>
      <Modal
        open={show}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: "40px",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            ></Typography>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={close}
            >
              <Close />
            </div>
          </div>
          <div
            style={
              {
                // height: "280px",
                // overflowY: "scroll",
                // overflowX: "hidden",
                // paddingLeft: "30px",
                // paddingRight: "30px",
              }
            }
          >
            <Typography
              style={{
                fontFamily: "Beatrice",
                fontSize: window.innerWidth > 768 ? "22px" : "1rem",
              }}
            >
              By choosing <b>“No Backing”</b>, you&apos;ll need to consider how you
              want to install your Neon.
            </Typography>
            <Typography
              style={{
                fontSize: window.innerWidth > 768 ? "36px" : "1.5rem",
                fontFamily: "Beatrice",
                color: "#71E4D1",
              }}
            >
              Ok, I’m ready :)
            </Typography>
            <div
              // className="continue-button-width-adjust"
              style={{
                // marginTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{
                  width: window.innerWidth > 768 ? "30%" : "100%",
                  color: "#ffffff",
                  fontSize: "16px",
                  padding: "10px",
                  fontWeight: "600",
                  bgcolor: "#36CDB4",
                  borderRadius: "16px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                  ":hover": {
                    color: "#36CDB4",
                    border: "1px solid #36CDB4",
                  },
                }}
                onClick={close}
              >
                Continue
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default BackingModal;
