import React from "react";

const Refresh = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.77196 3.98989C2.76381 2.20206 4.61211 1 6.72847 1C9.89221 1 12.4569 3.68629 12.4569 7C12.4569 10.3137 9.89221 13 6.72847 13C3.56472 13 1 10.3137 1 7"
        stroke="#D42678"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.58008 4H1.71584V1"
        stroke="#D42678"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Refresh;
