import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, styled, tooltipClasses } from "@mui/material";
import {
  addPrice,
  fetchColors,
  fetchExamples,
  fetchFonts,
  fetchHelp,
  nextStep,
  setAccessoriesCost,
  setBackingCost,
  setInstallationPrice,
  setNeonCost,
  setProductFont,
  setProductFontDescription,
  setTotalCost,
} from "../../../Redux/Slice";
import { Popover, Typography } from "@mui/material";
import { ReactComponent as LeftAlignIcon } from "../../../Assets/LeftAlign.svg";
import { ReactComponent as RightAlignIcon } from "../../../Assets/RightAlign.svg";
import { ReactComponent as DeleteIcon } from "../../../Assets/DeleteIcon.svg";
import { ReactComponent as DescriptionIcon } from "../../../Assets/DescriptionIcon.svg";

import BottomControls from "../../../Components/BottomControls";
import Center from "../../../Assets/Center";
import Send from "../../../Assets/Send";
import Close from "../../../Assets/Close";
import {
  calculateFontArea,
  calculateLetterLength,
} from "../../../utils/client/calculations";
import SuperContent from "../../../Pages/SuperCustom/SuperContent";
import "../../../Pages/styles/Content.css";
import OtherFonts from "../../Content/OtherFonts";
import ExampleModal from "../../ExampleModal";
import Info from "../../../Assets/SuperCustom/Info";
import TextBoxes from "../../TextBoxes";
import { MdOutlineDeselect } from "react-icons/md";

const getRandomFontFamily = (fontsArray) => {
  const randomIndex = Math.floor(Math.random() * fontsArray.length);
  const fontFamily = fontsArray[randomIndex].name;
  const jsonLink = fontsArray[randomIndex].jsonFile;
  return { fontFamily, jsonLink };
};
const EditFont = () => {
  const product = useSelector((state) => state.activeStep.product);
  const fontDesc = useSelector(
    (state) => state.activeStep.product.fontsDescription
  );
  const [desc, setDesc] = useState(fontDesc ? fontDesc : "");
  const [textAreas, setTextAreas] = useState(
    product.fonts.length !== 0
      ? product.fonts
      : [
          {
            id: 0,
            fontFamily: "",
            jsonLink: "",
            verticalAlign: "middle",
            horizontalAlign: "center",
            width: "450px",
            value: "Type Something",
            isSplit: false,
            html: "Type Something",
          },
        ]
  );

  // const [desc, setDesc] = useState("");
  const [selectedTextAreaId, setSelectedTextAreaId] = useState(
    textAreas ? textAreas[0]?.id : null
  );
  const [draggedFont, setDraggedFont] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedFont, setSelectedFont] = useState(
    textAreas[0]?.fontFamily ? textAreas[0]?.fontFamily : ""
  );
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const showExamples = useSelector((state) => state.activeStep.showExamples);

  const [examples] = useState(false);
  const [otherFonts] = useState(false);

  const textareaRefs = useRef(Array(textAreas.length).fill(null));

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      arrow
      placement="bottom-start"
      {...props}
      style={{
        backgroundColor: "",
      }}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "14px",
      maxWidth: 200,
      backgroundColor: "#D9FF9E",
      color: "#03705E",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#D9FF9E",
    },
  });

  const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref}>
        <Info />
      </div>
    );
  });
  const updateTextareaHeight = (index) => {
    const textarea = textareaRefs?.current[index];
    if (textarea?.style) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea?.scrollHeight + 10}px`;
    }
  };
  // educationla popup stuff

  const hasVisitedContentPage = localStorage.getItem("hasVisitedContentPage");

  // Handle drop event on the delete icon
  const handleDelete = (e) => {
    e.preventDefault();
    removeTextArea(parseInt(selectedTextAreaId));
    setSelectedTextAreaId(null);
  };

  const handleHorizontalAlignmentChange = (alignment) => {
    if (selectedTextAreaId != null) {
      const updatedTextAreas = textAreas.map((textarea) => {
        if (textarea.id === parseInt(selectedTextAreaId)) {
          return { ...textarea, horizontalAlign: alignment };
        }
        return textarea;
      });

      setTextAreas(updatedTextAreas);
      dispatch(setProductFont(updatedTextAreas));
    }
    setSelectedTextAreaId(null);
  };

  const handleTextAreaDragStart = (e, textArea) => {
    e.dataTransfer.setData("text/plain", textArea.id);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDescriptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDescriptionClose = () => {
    setAnchorEl(null);
  };

  const handleDrop = (e, targetId) => {
    // console.log(targetId);
    e.preventDefault();
    const fontFamily = e.dataTransfer.getData("text/plain");
    addSameTextArea(targetId, fontFamily);
    setSelectedTextAreaId(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const dispatch = useDispatch();
  const addTextArea = (selectedFont) => {
    if (selectedTextAreaId !== null) {
      const updatedTextAreas = textAreas.map((textarea) => {
        if (textarea.id === selectedTextAreaId) {
          return {
            ...textarea,
            fontFamily: selectedFont.name,
            jsonLink: selectedFont.jsonFile,
          };
        }
        return textarea;
      });

      setTextAreas(updatedTextAreas);
      dispatch(setProductFont(updatedTextAreas));
    } else {
      let fontFamily, jsonLink;
      fonts.map((font) => {
        if (font.name === selectedFont.name) {
          fontFamily = font.name;
          jsonLink = font.jsonFile;
        }
      });
      if (textAreas.length < 3) {
        let newId = 0;
        if (textAreas.length > 0) {
          newId = Math.max(...textAreas.map((textarea) => textarea.id)) + 1;
        }

        const newTextArea = {
          id: newId,
          fontFamily,
          jsonLink,
          verticalAlign: "middle",
          horizontalAlign: "center",
          width: "450px",
          isSplit: false,
          value: "Type Something",
          html: "Type Something",
          color: "#36CDB4",
        };
        const updatedTextAreas = [...textAreas, newTextArea];
        setTextAreas(updatedTextAreas);
      }
    }
  };

  const addSameTextArea = (id, fontFamily) => {
    if (draggedFont) {
      // console.log(fontFamily);
      let jsonLink;
      fonts.map((font) => {
        if (font.name === fontFamily) {
          jsonLink = font.jsonFile;
        }
      });
      const updatedTextAreas = textAreas.map((textarea) => {
        if (textarea.id === parseInt(id)) {
          return { ...textarea, fontFamily: fontFamily, jsonLink };
        }
        return textarea;
      });

      setTextAreas(updatedTextAreas);
      dispatch(setProductFont(updatedTextAreas));
    }
    setSelectedTextAreaId(null);
  };

  const removeTextArea = (id) => {
    const updatedTextAreas = textAreas.filter((textarea) => textarea.id !== id);
    setTextAreas(updatedTextAreas);
    dispatch(setProductFont(updatedTextAreas));
    // console.log("removed");
    setSelectedTextAreaId(null);
  };

  const handleTextAreaChange = (id, event) => {
    // console.log(event.target.value);
    const updatedTextAreas = textAreas.map((textarea) => {
      if (textarea.id === id) {
        // console.log(textarea.id);
        const inputHtml = event.target.value;

        const plainText = inputHtml
          .replace(/<div style="font-family: inherit;">/g, "\n")
          .replace(/<[^>]*>/g, "")
          .replace(/&nbsp;/g, " "); // Replace &nbsp; with a space

        const replacedHtml = inputHtml.replace(
          /<div>/g,
          '<div style="font-family: inherit;">'
        );

        const value = plainText;
        const lines = value?.split("\n");
        const longestLine = lines?.reduce(
          (acc, line) => (line?.length > acc ? line?.length : acc),
          0
        );
        const newWidth = (longestLine + 1) * 30;
        if (textarea?.isSplit) {
          // console.log("split handle");
          // Create the new letters array based on the new text
          const newLetters = value?.split("").map((letter, index) => {
            const existingLetter = textarea?.letters?.find(
              (l) => l.id === index
            );
            const color = existingLetter
              ? existingLetter?.color
              : textarea.color;
            return { id: index, letter, color };
          });
          // console.log(newLetters);

          return {
            ...textarea,
            value,
            html: replacedHtml,
            width: newWidth,
            letters: newLetters,
          };
        } else {
          return {
            ...textarea,
            value,
            html: replacedHtml,
            width: newWidth,
          };
        }
      }
      return textarea;
    });
    // console.log(updatedTextAreas);
    setTextAreas(updatedTextAreas);
    dispatch(setProductFont(updatedTextAreas));
  };

  const getColorPriceForLetter = (colorCode) => {
    const color = colors.find((color) => color.code === colorCode);
    if (color) {
      return color.price;
    } else {
      return colorCost; // Use the default color cost if color is not found
    }
  };

  const theme = useSelector((state) => state.activeStep.theme);
  const blackFranceCost = useSelector(
    (state) => state.activeStep.blackFranceRate
  );
  const whiteFranceCost = useSelector(
    (state) => state.activeStep.whiteFranceRate
  );
  const isCustomColor = useSelector(
    (state) => state.activeStep.product.customBacking
  );
  const factor = useSelector((state) => state.activeStep.materialLengthFactor);
  const cost = useSelector((state) => state.activeStep.product.cost);

  const fonts = useSelector((state) => state.activeStep.fonts);
  const colors = useSelector((state) => state.activeStep.colors);
  const cables = useSelector((state) => state.activeStep.cables);

  const installationCost = useSelector(
    (state) => state.activeStep.installationCost
  );
  const bendingCost = useSelector((state) => state.activeStep.bendingCostPerFt);
  const colorCost = useSelector((state) => state.activeStep.colorCost);
  const tech22Cost = useSelector((state) => state.activeStep.tech22Cost);
  const blackAccessoryCost = useSelector(
    (state) => state.activeStep.blackAccessoryCost
  );
  const clearPlexiCost = useSelector(
    (state) => state.activeStep.clearPlexiCost
  );
  const Hmax = useSelector((state) => state.activeStep.plexiHeightMax);
  // console.log(Hmax);
  const Wmax = useSelector((state) => state.activeStep.plexiWidthMax);
  const markUp = useSelector((state) => state.activeStep.markup);
  const discount = useSelector((state) => state.activeStep.discount);
  const materialLengthPerTech = useSelector(
    (state) => state.activeStep.materialLengthPerTech
  );
  const backingColors = useSelector((state) => state.activeStep.backingColors);

  const customBackingCost = useSelector(
    (state) => state.activeStep.customBackingCost
  );
  const backingWidth = useSelector(
    (state) => state.activeStep.product.backing.width
  );

  const backingHeight = useSelector(
    (state) => state.activeStep.product.backing.height
  );
  const addedAddOns = useSelector(
    (state) => state.activeStep.product.accessories.addOns
  );
  const controllers = useSelector((state) => state.activeStep.controllers);

  const allFonts = useSelector((state) => state.activeStep.fonts);
  const baseInstallValues = useSelector(
    (state) => state.activeStep.baseInstallValues
  );
  const backings = useSelector((state) => state.activeStep.backingStyles);

  const handleUnselect = () => {
    setSelectedTextAreaId(null);
  };

  const calculateBackingPrice = async () => {
    if (textAreas[0]?.value || textAreas[1]?.value || textAreas[2]?.value) {
      let baseValue = 0;
      let backingPrice;
      let wireCost = blackAccessoryCost;
      let backingPerFt = clearPlexiCost;
      let colorPricePerFt = 0;
      let fontPricePerFt = 0;
      let materialLength = 0;
      let neonWidth = 0;
      let neonHeight = 0;
      let signWidth = 0;
      let signHeight = 0;
      let glassCost = 0;
      let glassCost1 = 0;
      let glassCost2 = 0;
      let glassCost3 = 0;
      let unitNumber = 0;
      let segments = 0;

      // console.log("Bending Cost :", bendingCost);
      // console.log("EGL White Cost :", colorCost);
      // console.log("Max Plexi Height :", Hmax);
      // console.log("Max Plexi Width :", Wmax);

      if (textAreas.length === 1) {
        // console.log("Single Font");
        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          neonWidth = result?.materialLength;
          neonHeight = result?.maxHeight;
          signWidth = result?.totalWidth + backingWidth;
          signHeight = result?.maxHeight + backingHeight;

          allFonts.map((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt = font.price;
            }
          });
          textAreas[0].letters.forEach(async (letter) => {
            colorPricePerFt = getColorPriceForLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            glassCost +=
              Number(fontPricePerFt + bendingCost + colorPricePerFt) *
              (letterLength / 12);
          });
          // console.log(glassCost, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          // console.log(result);
          materialLength = result?.materialLength;
          neonWidth = result?.totalWidth;
          neonHeight = result?.maxHeight;
          signWidth = result?.totalWidth + backingWidth;
          signHeight = result?.maxHeight + backingHeight;

          materialLength *= factor;

          allFonts.map((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt = font.price;
            }
          });
          colors.map((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt = color.price;
            }
          });

          colorPricePerFt = colorPricePerFt ? colorPricePerFt : colorCost;

          // console.log(colorPricePerFt);
          glassCost =
            Number(fontPricePerFt + bendingCost + colorPricePerFt) *
            (materialLength / 12);
          // console.log(glassCost);
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        }
        console.log("Material Length : ", materialLength);

        console.log("Neon Width : ", neonWidth);
        console.log("Neon Height : ", neonHeight);
        console.log("Sign Width : ", signWidth);
        console.log("Sign Height : ", signHeight);

        unitNumber = Number(
          Math.ceil(neonWidth / 24) + Math.ceil(neonHeight / 10)
        );
        console.log("Unit Numbers : ", unitNumber);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        console.log("Segments : ", segments);
      } else if (textAreas.length === 2) {
        // console.log("Multiple Fonts");
        let fontPricePerFt1 = 0;
        let fontPricePerFt2 = 0;
        let colorPricePerFt1 = 0;
        let colorPricePerFt2 = 0;

        let materialLength1 = 0;
        let neonWidth1 = 0;
        let neonHeight1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let neonWidth2 = 0;
        let neonHeight2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        if (textAreas[0]?.isSplit) {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;
          allFonts.map((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          textAreas[0].letters.forEach(async (letter) => {
            colorPricePerFt1 = getColorPriceForLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            glassCost1 +=
              Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
              (letterLength / 12);
          });
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.map((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          colors.map((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt1 = color.price;
            }
          });

          colorPricePerFt1 = colorPricePerFt1 ? colorPricePerFt1 : colorCost;

          // console.log(fontPricePerFt);
          glassCost1 =
            Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
            (materialLength1 / 12);
          // console.log(glassCost);
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          allFonts.map((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          textAreas[1].letters.forEach(async (letter) => {
            colorPricePerFt2 = getColorPriceForLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            glassCost2 +=
              Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
              (letterLength / 12);
          });
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.map((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          colors.map((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPricePerFt2 = color.price;
            }
          });

          colorPricePerFt2 = colorPricePerFt2 ? colorPricePerFt2 : colorCost;

          // console.log(fontPricePerFt);
          glassCost2 =
            Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
            (materialLength2 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }

        // console.log(fontPricePerFt);
        glassCost = Number(glassCost1 + glassCost2);
        // console.log(glassCost);
        signHeight = Number(signHeight1 + signHeight2) + backingHeight;
        signWidth = Math.max(signWidth1, signWidth2) + backingWidth;
        materialLength = materialLength1 + materialLength2;
        // console.log("Sign Height :", signHeight);
        // console.log("Sign Width :", signWidth);
        // console.log("Total Material Length :", materialLength);

        unitNumber = Number(
          Math.ceil(Math.max(neonWidth1, neonWidth2) / 24) +
            Math.ceil((neonHeight1 + neonHeight2) / 10)
        );
        // console.log(unitNumber);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        // console.log(segments);
      } else if (textAreas.length === 3) {
        // console.log("Multiple Fonts");
        let fontPricePerFt1 = 0;
        let fontPricePerFt2 = 0;
        let fontPricePerFt3 = 0;
        let colorPricePerFt1 = 0;
        let colorPricePerFt2 = 0;
        let colorPricePerFt3 = 0;

        let materialLength1 = 0;
        let neonWidth1 = 0;
        let neonHeight1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let neonWidth2 = 0;
        let neonHeight2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        let materialLength3 = 0;
        let neonWidth3 = 0;
        let neonHeight3 = 0;
        let signWidth3 = 0;
        let signHeight3 = 0;

        if (textAreas[0]?.isSplit) {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;
          allFonts.map((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          textAreas[0].letters.forEach(async (letter) => {
            colorPricePerFt1 = getColorPriceForLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            glassCost1 +=
              Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
              (letterLength / 12);
          });
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.map((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          colors.map((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt1 = color.price;
            }
          });

          colorPricePerFt1 = colorPricePerFt1 ? colorPricePerFt1 : colorCost;

          // console.log(fontPricePerFt);
          glassCost1 =
            Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
            (materialLength1 / 12);
          // console.log(glassCost);
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          allFonts.map((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          textAreas[1].letters.forEach(async (letter) => {
            colorPricePerFt2 = getColorPriceForLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            glassCost2 +=
              Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
              (letterLength / 12);
          });
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.map((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          colors.map((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPricePerFt2 = color.price;
            }
          });

          colorPricePerFt2 = colorPricePerFt2 ? colorPricePerFt2 : colorCost;

          // console.log(fontPricePerFt);
          glassCost2 =
            Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
            (materialLength2 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[2]?.isSplit) {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink
          );
          // console.log(result2);
          materialLength3 = result3?.materialLength;
          neonWidth3 = result3?.totalWidth;
          neonHeight3 = result3?.maxHeight;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;
          allFonts.map((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt3 = font.price;
            }
          });
          textAreas[2].letters.forEach(async (letter) => {
            colorPricePerFt3 = getColorPriceForLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[2]?.fontFamily,
              textAreas[2]?.jsonLink
            );
            glassCost3 +=
              Number(fontPricePerFt3 + bendingCost + colorPricePerFt3) *
              (letterLength / 12);
          });
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink
          );
          // console.log(result2);
          materialLength3 = result3?.materialLength;
          neonWidth3 = result3?.totalWidth;
          neonHeight3 = result3?.maxHeight;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.map((font) => {
            if (font.name === textAreas[2].fontFamily) {
              // console.log(font.name);
              fontPricePerFt3 = font.price;
            }
          });
          colors.map((color) => {
            if (color.code === textAreas[2].color) {
              // console.log(color.code);
              colorPricePerFt3 = color.price;
            }
          });

          colorPricePerFt3 = colorPricePerFt3 ? colorPricePerFt3 : colorCost;

          // console.log(fontPricePerFt);
          glassCost3 =
            Number(fontPricePerFt3 + bendingCost + colorPricePerFt3) *
            (materialLength3 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }

        // console.log(fontPricePerFt);
        glassCost = Number(glassCost1 + glassCost2 + glassCost3);
        // console.log(glassCost);
        signHeight = signHeight1 + signHeight2 + signHeight3 + backingHeight;
        signWidth = Math.max(signWidth1, signWidth2, signWidth3) + backingWidth;
        materialLength = materialLength1 + materialLength2 + materialLength3;

        unitNumber = Number(
          Math.ceil(Math.max(neonWidth1, neonWidth2, neonWidth3) / 24) +
            Math.ceil((neonHeight1 + neonHeight2 + neonHeight3) / 10)
        );
        // console.log(unitNumber);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        // console.log(segments);
      }

      // console.log("Glass Cost : ", glassCost);
      dispatch(setNeonCost(glassCost));

      let techs = Number(
        Math.ceil((materialLength / 12 + unitNumber) / materialLengthPerTech) +
          segments -
          1
      );
      // console.log("Techs:", techs);

      let transformerCost = Number(techs * tech22Cost);
      // console.log("Transformer Cost:", transformerCost);

      if (product.delivery.install.name) {
        if (product.delivery.install.name === "Concealed Install") {
          transformerCost = Number(techs * whiteFranceCost);
          console.log("Transformer Cost When Concealed:", transformerCost);
        }
        if (product.delivery.install.name === "Surface Install") {
          transformerCost = Number(techs * blackFranceCost);
          console.log("Transformer Cost When Surface:", transformerCost);
        }

        baseInstallValues.map((installValue) => {
          if (installValue.name === product?.delivery?.install?.name) {
            baseValue = installValue?.value;
          }
        });
        // console.log(installationCost, baseValue, unitNumber);
        backingPrice = Number(baseValue + installationCost * unitNumber);
        let installationPrice =
          signWidth * signHeight * product.delivery.install.installPerSq;
        dispatch(setInstallationPrice(installationPrice));
        // console.log("Installation Cost:", installationPrice);
      } else {
        dispatch(setInstallationPrice(0));
        backings.map((backing) => {
          if (backing.type === product.backing.name) {
            // console.log(color.code);
            backingPerFt = backing.BackingPricePerSqInch;
          }
        });

        if (isCustomColor) {
          backingPerFt = customBackingCost;
        } else {
          backingColors.map((backing) => {
            if (backing.code === product.backing.color) {
              // console.log(color.code);
              backingPerFt = backing.price;
            }
          });
        }
        backingPrice = Number(signWidth * signHeight * backingPerFt);
        if (product.backing.name === "") {
          backingPrice = 0;
        } else {
          if (
            product.backing.name === "wood" ||
            product.backing.name === "box"
          ) {
            backingPrice = Number(
              (2 * signWidth * signHeight + signHeight * signHeight) *
                backingPerFt
            );
          } else if (product.backing.name === "skeleton") {
            backingPrice = Number(materialLength * backingPerFt);
          }
        }
        // console.log(backingPrice);
      }
      // console.log("BackingCost:", backingPrice);
      dispatch(setBackingCost(backingPrice));

      cables.map((cable) => {
        if (cable.color === product?.accessories?.wireColor) {
          // console.log(cable);
          wireCost = cable.price;
        }
      });
      let accessoriesCost = Number(unitNumber * wireCost);
      // console.log("Accessories Cost:", accessoriesCost);

      controllers.map((controller) => {
        if (controller.name === product?.accessories?.dimmer?.name) {
          // console.log(cable);
          accessoriesCost += controller.price;
        }
      });
      if (product?.accessories?.addOns.length > 0) {
        addedAddOns.map((addOn) => {
          accessoriesCost += addOn.price;
        });
      }
      // console.log("Total Accessories Cost:", accessoriesCost);
      dispatch(setAccessoriesCost(accessoriesCost));

      let totalCost = Number(
        glassCost + transformerCost + backingPrice + accessoriesCost
      );
      // console.log("Total Cost:", totalCost);

      dispatch(setTotalCost(totalCost));
      // console.log("Mark Up Percentage :", markUp);
      // console.log("Discount Percentage :", discount);
      let decimalMarkUp = markUp / 100;
      let decimalDiscount = discount / 100;

      let markupAmount = decimalMarkUp * totalCost;
      // console.log("MarkUp Amount:", markupAmount);
      // console.log("Decimal Discount:", decimalDiscount);
      let markUpPrice = totalCost + markupAmount;
      let discountFactor = Number(1 - decimalDiscount);
      // console.log("MarkUp Price :", markUpPrice);
      // console.log("Discounted Factor :", discountFactor);

      let price = Number(markUpPrice * discountFactor);
      // console.log("Price:", price);
      dispatch(addPrice(Math.ceil(price)));
    }
  };

  const RGBColor = useSelector((state) => state.activeStep.product.RGBColor);
  const SpecialColorFee = useSelector(
    (state) => state.activeStep.SpecialColorFee
  );
  const LEDCostPerMaterial = useSelector((state) => state.activeStep.colorCost);
  const InterShippingCost = useSelector(
    (state) => state.activeStep.InterShippingCost
  );
  const AcrylicInterShippingCost = useSelector(
    (state) => state.activeStep.woodInterShippingCost
  );
  const WoodInterShippingCost = useSelector(
    (state) => state.activeStep.acrylicInterShippingCost
  );
  const standardRGBCost = useSelector(
    (state) => state.activeStep.standardRGBCost
  );
  const magicRGBCost = useSelector((state) => state.activeStep.magicRGBCost);

  const getColorPriceForLEDLetter = (colorCode) => {
    const color = colors.find((color) => color.code === colorCode);
    if (color) {
      return color.price;
    } else {
      return LEDCostPerMaterial; // Use the default color cost if color is not found
    }
  };
  const calculateLEDFontPrice = async () => {
    // console.log("Calculating Price");
    if (textAreas[0]?.value || textAreas[1]?.value) {
      // console.log(selectedFont);

      let colorPrice = colorCost;
      let backingPerFt = customBackingCost;
      let baseValue = 0;
      let materialLength = 0;
      let signWidth = 0;
      let signHeight = 0;
      let backingArea = 0;

      let LEDCost = 0;
      let segments = 0;
      // console.log("Custom Color Backing Cost :", customBackingCost);
      // console.log("Special Color Cost :", SpecialColorFee);
      // console.log("Magic RGB Cost :", magicRGBCost);
      // console.log("Standard RGB Cost :", standardRGBCost);
      // console.log("Max Plexi Height :", Hmax);
      // console.log("Max Plexi Width :", Wmax);
      if (textAreas.length === 1 && textAreas[0].value) {
        console.log("------------------Single Font");
        if (textAreas[0]?.isSplit) {
          console.log("------------------Is Split");
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          // console.log(result);
          materialLength = result?.materialLength;
          signWidth = result?.totalWidth + backingWidth;
          signHeight = result?.maxHeight + backingHeight;

          const lettersColors = textAreas[0]?.letters?.map(
            (letter) => letter.color
          );
          // console.log(lettersColors);
          const customColorExists = lettersColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          console.log("Custom Color Present :", customColorExists);

          const letterPromises = textAreas[0].letters.map(async (letter) => {
            console.log(letter);
            let colorPrice = await getColorPriceForLEDLetter(letter.color);
            let letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            console.log(`Material length of ${letter.letter} :`, letterLength);
            console.log(`Color Cost of ${letter.letter} :`, colorPrice);
            let letterLEDCost;
            if (customColorExists) {
              letterLEDCost = Number(
                (SpecialColorFee + letterLength) * colorPrice
              );
            } else {
              letterLEDCost = Number(colorPrice * letterLength);
            }
            console.log(`LED Cost of ${letter.letter} :`, letterLEDCost);
            return letterLEDCost;
          });
          console.log(letterPromises);

          Promise.all(letterPromises)
            .then((letterCosts) => {
              const LEDCost = letterCosts.reduce(
                (total, cost) => total + cost,
                0
              );
              console.log("Total LED Cost:", LEDCost);
            })
            .catch((error) => {
              console.error("Error calculating LED cost:", error);
            });
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          // console.log(result);
          materialLength = result?.materialLength;
          materialLength *= factor;
          signWidth = result?.totalWidth + backingWidth;
          signHeight = result?.maxHeight + backingHeight;

          console.log(`Material length :`, materialLength);
          colors.map((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice = color.price;
            }
          });

          console.log(`Color Cost :`, colorPrice);

          const textAreaColors = textAreas.map((textArea) => textArea.color);
          // console.log(textAreaColors);
          const customColorExists = textAreaColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          console.log("Custom Color Present :", customColorExists);
          if (customColorExists) {
            LEDCost = Number((colorPrice + SpecialColorFee) * materialLength);
          } else {
            LEDCost = Number(colorPrice) * materialLength;
          }
          // console.log("LED Cost :", LEDCost);
        }

        console.log("Material Length : ", materialLength);
        console.log("Sign Width : ", signWidth);
        console.log("Sign Height : ", signHeight);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        console.log("Segments :", segments);

        backingArea = Number(signHeight * signWidth);
        console.log("Backing Area :", backingArea);
      } else if (textAreas.length === 2) {
        console.log("------------------Double Font");
        let colorPrice1 = 0;
        let colorPrice2 = 0;
        let LEDCost1 = 0;
        let LEDCost2 = 0;

        let materialLength1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;

          textAreas[0].letters.forEach(async (letter) => {
            colorPrice1 = getColorPriceForLEDLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            LEDCost1 += Number(colorPrice1 * letterLength);
          });
          const lettersColors = textAreas[0]?.letters?.map(
            (letter) => letter.color
          );
          const customColorExists = lettersColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          if (customColorExists) {
            LEDCost1 += SpecialColorFee;
          }
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;

          console.log(
            `Material length of ${textAreas[0].value}:`,
            materialLength1
          );
          colors.map((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice1 = color.price;
            }
          });

          console.log(`Color Cost of ${textAreas[0].value}:`, colorPrice1);

          const textAreaColors = textAreas.map((textArea) => textArea.color);
          // console.log(textAreaColors);
          const customColorExists = textAreaColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          // console.log(customColorExists);
          if (customColorExists) {
            LEDCost1 = Number(
              (colorPrice1 + SpecialColorFee) * materialLength1
            );
          } else {
            LEDCost1 = Number(colorPrice1) * materialLength1;
          }
          console.log(`LED Cost of ${textAreas[0].value} :`, LEDCost1);
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          textAreas[1].letters.forEach(async (letter) => {
            colorPrice2 = getColorPriceForLEDLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            LEDCost2 += Number(colorPrice2 * (letterLength / 12));
          });
          const lettersColors = textAreas[1]?.letters?.map(
            (letter) => letter.color
          );
          const customColorExists = lettersColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          if (customColorExists) {
            LEDCost2 += SpecialColorFee;
          }
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          console.log(
            `Material length of ${textAreas[1].value}:`,
            materialLength2
          );
          colors.map((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPrice2 = color.price;
            }
          });

          console.log(`Color Cost of ${textAreas[1].value}:`, colorPrice2);

          // console.log(LEDCost);
          const textAreaColors = textAreas.map((textArea) => textArea.color);
          // console.log(textAreaColors);
          const customColorExists = textAreaColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          // console.log(customColorExists);
          if (customColorExists) {
            LEDCost2 = Number(
              (colorPrice2 + SpecialColorFee) * materialLength2
            );
          } else {
            LEDCost2 = Number(colorPrice2) * materialLength2;
          }
          console.log(`LED Cost of ${textAreas[1].value} :`, LEDCost2);
        }

        LEDCost = LEDCost1 + LEDCost2;

        // console.log(signWidth);
        // console.log(materialLength / 12);

        // console.log(fontPricePerFt);

        // console.log(LEDCost);
        materialLength = materialLength1 + materialLength2;
        materialLength *= factor;
        signHeight = Number(signHeight1 + signHeight2) + backingHeight;
        signWidth = Math.max(signWidth1, signWidth2) + backingWidth;
        console.log("Material Length : ", materialLength);
        console.log("Sign Width : ", signWidth);
        console.log("Sign Height : ", signHeight);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        console.log("Segments :", segments);

        backingArea = Number(signHeight * signWidth);
        console.log("Backing Area :", backingArea);
      } else if (textAreas.length === 3) {
        console.log("------------------Triple Font");
        let colorPrice1 = 0;
        let colorPrice2 = 0;
        let colorPrice3 = 0;
        let LEDCost1 = 0;
        let LEDCost2 = 0;
        let LEDCost3 = 0;

        let materialLength1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        let materialLength3 = 0;
        let signWidth3 = 0;
        let signHeight3 = 0;

        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;

          textAreas[0].letters.forEach(async (letter) => {
            colorPrice1 = getColorPriceForLEDLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            LEDCost1 += Number(colorPrice1 * letterLength);
          });
          const lettersColors = textAreas[0]?.letters?.map(
            (letter) => letter.color
          );
          const customColorExists = lettersColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          if (customColorExists) {
            LEDCost1 += SpecialColorFee;
          }
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;

          console.log(
            `Material length of ${textAreas[0].value}:`,
            materialLength1
          );
          colors.map((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice1 = color.price;
            }
          });

          console.log(`Color Cost of ${textAreas[0].value}:`, colorPrice1);

          const textAreaColors = textAreas.map((textArea) => textArea.color);
          // console.log(textAreaColors);
          const customColorExists = textAreaColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          // console.log(customColorExists);
          if (customColorExists) {
            LEDCost1 = Number(
              (colorPrice1 + SpecialColorFee) * materialLength1
            );
          } else {
            LEDCost1 = Number(colorPrice1) * materialLength1;
          }
          console.log(`LED Cost of ${textAreas[0].value} :`, LEDCost1);
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          textAreas[1].letters.forEach(async (letter) => {
            colorPrice2 = getColorPriceForLEDLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            LEDCost2 += Number(colorPrice2 * letterLength);
          });
          const lettersColors = textAreas[1]?.letters?.map(
            (letter) => letter.color
          );
          const customColorExists = lettersColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          if (customColorExists) {
            LEDCost2 += SpecialColorFee;
          }
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          console.log(
            `Material length of ${textAreas[1].value}:`,
            materialLength2
          );
          // console.log(materialLength / 12);
          colors.map((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPrice2 = color.price;
            }
          });

          // console.log(LEDCost);
          const textAreaColors = textAreas.map((textArea) => textArea.color);
          // console.log(textAreaColors);
          const customColorExists = textAreaColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          // console.log(customColorExists);
          if (customColorExists) {
            LEDCost2 = Number(
              (colorPrice2 + SpecialColorFee) * materialLength2
            );
          } else {
            LEDCost2 = Number(colorPrice2) * materialLength2;
          }
        }
        if (textAreas[2]?.isSplit) {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink
          );
          materialLength3 = result3?.materialLength;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;

          textAreas[2].letters.forEach(async (letter) => {
            colorPrice3 = getColorPriceForLEDLetter(letter.color);
            const letterLength = await calculateLetterLength(
              letter.letter,
              textAreas[2]?.fontFamily,
              textAreas[2]?.jsonLink
            );
            LEDCost3 += Number(colorPrice3 * letterLength);
          });
          const lettersColors = textAreas[2]?.letters?.map(
            (letter) => letter.color
          );
          const customColorExists = lettersColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          if (customColorExists) {
            LEDCost3 += SpecialColorFee;
          }
        } else {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink
          );
          materialLength3 = result3?.materialLength;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;

          console.log(
            `Material length of ${textAreas[2].value}:`,
            materialLength3
          );
          // console.log(materialLength / 12);
          colors.map((color) => {
            if (color.code === textAreas[2].color) {
              // console.log(color.code);
              colorPrice3 = color.price;
            }
          });

          console.log(`Color Cost of ${textAreas[2].value}:`, colorPrice3);

          // console.log(LEDCost);
          const textAreaColors = textAreas.map((textArea) => textArea.color);
          // console.log(textAreaColors);
          const customColorExists = textAreaColors.some(
            (color) => !colors.some((c) => c.code === color)
          );
          // console.log(customColorExists);
          if (customColorExists) {
            LEDCost3 = Number(
              (colorPrice3 + SpecialColorFee) * materialLength3
            );
          } else {
            LEDCost3 = Number(colorPrice3) * materialLength3;
          }
          console.log(`LED Cost of ${textAreas[2].value} :`, LEDCost3);
        }

        LEDCost = LEDCost1 + LEDCost2 + LEDCost3;

        // console.log(signWidth);
        // console.log(materialLength / 12);

        // console.log(fontPricePerFt);

        // console.log(LEDCost);
        materialLength = materialLength1 + materialLength2 + materialLength3;
        materialLength *= factor;
        signHeight =
          Number(signHeight1 + signHeight2 + signHeight3) + backingHeight;
        signWidth = Math.max(signWidth1, signWidth2, signWidth3) + backingWidth;
        console.log("Material Length : ", materialLength);
        console.log("Sign Width : ", signWidth);
        console.log("Sign Height : ", signHeight);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        console.log("Segments :", segments);
        backingArea = Number(signHeight * signWidth);
        console.log("Backing Area :", backingArea);
      }

      console.log("Total LED Cost :", LEDCost);
      dispatch(setNeonCost(LEDCost));

      let powerSupplies = Number(
        Math.ceil(materialLength / 12 / materialLengthPerTech)
      );
      console.log("Number of Power Supply :", powerSupplies);

      let powerSupplyCost = Number(powerSupplies * tech22Cost);
      console.log("Power Supply Cost :", powerSupplyCost);

      let backingCost;
      if (product.delivery.install.name) {
        baseInstallValues.map((installValue) => {
          if (installValue.name === product?.delivery?.install?.name) {
            baseValue = installValue?.value;
          }
        });
        // console.log(installationCost, baseValue, unitNumber);
        backingCost = Number(baseValue + installationCost);
        // console.log("BackingCost:",backingCost);
        let installationPrice =
          signWidth * signHeight * product.delivery.install.installPerSq;
        dispatch(setInstallationPrice(Math.ceil(installationPrice)));
        console.log("Installation Cost:", installationPrice);
      } else {
        dispatch(setInstallationPrice(0));
        backings.map((backing) => {
          if (backing.type === product.backing.name) {
            // console.log(color.code);
            backingPerFt = backing.BackingPricePerSqInch;
          }
        });

        if (isCustomColor) {
          backingPerFt = customBackingCost;
        } else {
          backingColors.map((backing) => {
            if (backing.code === product.backing.color) {
              // console.log(color.code);
              backingPerFt = backing.price;
            }
          });
        }

        console.log("Backing Color Cost :", backingPerFt);
        backingCost = Number(backingArea * backingPerFt);
        if (product.backing.name === "") {
          backingCost = 0;
        }
        console.log("Backing Cost :", backingCost);
        dispatch(setBackingCost(backingCost));
      }

      let accessoriesCost = Number(segments * blackAccessoryCost);
      // console.log(accessoriesCost);

      // console.log(accessoriesCost);
      controllers.map((controller) => {
        if (controller.name === product?.accessories?.dimmer?.name) {
          console.log("Controller Price :", controller.price);
          accessoriesCost =
            Number(segments * blackAccessoryCost) +
            powerSupplies * controller.price;
        }
      });
      // console.log(accessoriesCost);
      if (product.accessories.addOns.length > 0) {
        addedAddOns.map((addOn) => {
          // console.log(accessoriesCost, addOn.price);
          accessoriesCost += addOn.price;
        });
      }

      // console.log("Accessories Cost :", accessoriesCost);

      console.log("RGB Style Selected :", RGBColor);
      if (RGBColor) {
        if (RGBColor === "standardRGB") {
          accessoriesCost = Number(
            segments * (blackAccessoryCost + standardRGBCost)
          );
          console.log("Accessories Cost with Standard RGB :", accessoriesCost);
        }
        if (RGBColor === "magicRGB") {
          accessoriesCost = Number(
            segments * (blackAccessoryCost + magicRGBCost)
          );
          console.log("Accessories Cost with Magic RGB :", accessoriesCost);
        }
      }
      console.log(accessoriesCost);
      dispatch(setAccessoriesCost(accessoriesCost));

      let shippingCost;
      shippingCost = Number(backingArea * InterShippingCost);
      if (product.backing.name === "wood") {
        shippingCost = Number(backingArea * WoodInterShippingCost);
      }
      if (product.backing.name === "box") {
        shippingCost = Number(backingArea * AcrylicInterShippingCost);
      }
      console.log("Shipping Cost :", shippingCost);

      let totalCost = Number(
        LEDCost + powerSupplyCost + backingCost + accessoriesCost + shippingCost
      );
      console.log("Total Cost:", totalCost);
      dispatch(setTotalCost(totalCost));

      console.log("Mark Up Percentage :", markUp);
      console.log("Discount Percentage :", discount);
      let decimalMarkUp = markUp / 100;
      let decimalDiscount = discount / 100;

      let markupAmount = decimalMarkUp * totalCost;
      console.log("MarkUp Amount:", markupAmount);
      console.log("Decimal Discount:", decimalDiscount);
      let markUpPrice = totalCost + markupAmount;
      let discountFactor = Number(1 - decimalDiscount);
      console.log("MarkUp Price :", markUpPrice);
      console.log("Discounted Factor :", discountFactor);

      let price = Number(markUpPrice * discountFactor);
      console.log("Price:", price);
      dispatch(addPrice(Math.ceil(price)));
    }
  };
  useEffect(() => {
    dispatch(fetchFonts(product.source));
    dispatch(fetchColors(product.source));
    dispatch(fetchHelp(`Content/${product.source}`));
    dispatch(fetchExamples("Content", product.source));

    // Mark the user as visited after the first visit
    if (!hasVisitedContentPage) {
      localStorage.setItem("hasVisitedContentPage", "true");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // textAreas.forEach((_, index) => updateTextareaHeight(index));

    if (product.source === "Glass") {
      calculateBackingPrice();
    } else if (product.source === "LED") {
      calculateLEDFontPrice();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreas]);

  useEffect(() => {
    if (product.source === "Glass") {
      calculateBackingPrice();
    } else if (product.source === "LED") {
      calculateLEDFontPrice();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreas]);

  useEffect(() => {
    // Ensure 'fonts' is non-empty before updating the state

    if (!textAreas[0]?.fontFamily) {
      if (fonts.length !== 0) {
        const randomFont = getRandomFontFamily(fonts);
        // console.log(randomFont);
        setTextAreas((prevTextAreas) => {
          return prevTextAreas.map((area) => ({
            ...area,
            fontFamily: randomFont.fontFamily,
            jsonLink: randomFont.jsonLink,
          }));
        });
      }
      if (colors.length !== 0) {
        setTextAreas((prevTextAreas) => {
          return prevTextAreas.map((area) => ({
            ...area,
            color: "#36CDB4",
          }));
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fonts, colors]);

  const [hoveredStates, setHoveredStates] = useState([]); // Initialize an array of hover states

  const handleHoverChange = (index, isHovered) => {
    const newHoveredStates = [...hoveredStates];
    newHoveredStates[index] = isHovered;
    setHoveredStates(newHoveredStates);
  };

  // eslint-disable-next-line no-unused-vars
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileOrTablet(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check
    handleResize();

    // Add event listener to update when the window is resized
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (product.format === "custom") {
      const updatedTextAreas = textAreas.map((textArea) => {
        let updatedTextArea = { ...textArea };

        // Check if the fontFamily is not in the fonts array
        if (
          !fonts?.some((font) => font?.name === updatedTextArea?.fontFamily)
        ) {
          // console.log("Switching Font");
          const randomFontIndex = Math.floor(Math.random() * fonts?.length);
          updatedTextArea.fontFamily = fonts[randomFontIndex]?.name;
        }

        // Check if the color is not in the colors array
        if (!colors?.some((color) => color?.code === updatedTextArea?.color)) {
          // console.log("Switching Color");
          const randomColorIndex = Math.floor(Math.random() * colors?.length);
          updatedTextArea.color = colors[randomColorIndex]?.code;
        }

        return updatedTextArea;
      });

      dispatch(setProductFont(updatedTextAreas));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fonts, colors, textAreas, dispatch]);



  return (
    <>
      <div className="mt-3 margin-adjust ">
        {product.format === "super-custom" ? (
          <>
            <SuperContent />
          </>
        ) : (
          <>
            <Row className="d-flex justify-content-center">
              <Col md={7} lg={2} xs={12} sm={12} className="fonts-container">
                <div
                  className="fontList font-list source-items12"
                  style={{
                    height: "70vh",
                    overflowY: "auto",
                    paddingRight: "10px",
                  }}
                >
                  {fonts?.map((font, index) => {
                    const isSelected = textAreas.some(
                      (textArea) => textArea.fontFamily === font.name
                    );

                    return (
                      <Card
                        key={index}
                        draggable
                        onDragStart={(e) => {
                          e.dataTransfer.setData("text/plain", font.name);
                          setDraggedFont(true);
                        }}
                        onDragEnd={() => {
                          setSelectedFont(font.name);
                          setDraggedFont(false);
                        }}
                        className={`source-item hover-effect border-2 border-[#AFE9E0] m-3 font-black text-center items-center text-3xl cursor-pointer rounded-3xl ${
                          isSelected ? "selected" : ""
                        }`}
                        style={{
                          fontFamily: font.name,
                          height: "70px",
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: "24px",
                        }}
                        onClick={() => {
                          // console.log(font);
                          addTextArea(font);
                        }}
                        onMouseEnter={() => handleHoverChange(index, true)}
                        onMouseLeave={() => handleHoverChange(index, false)}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={font.imageOn}
                            alt={font.name}
                            style={{
                              opacity: hoveredStates[index] ? 0 : 1,
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              width: "100%",
                              height: "100%",
                              backgroundSize: "100% 100%", // Set image to cover entire parent
                              transition: "opacity 0.3s ease",
                              borderRadius: "24px",
                              fontFamily: font.name,
                              backgroundImage: `url('${font.imageOn}')`,
                            }}
                          ></img>
                          {hoveredStates[index] && (
                            <img
                              src={font.imageOff}
                              alt={font.name}
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                width: "100%",
                                height: "100%",
                                borderRadius: "22px",
                                backgroundImage: `url('${
                                  hoveredStates[index]
                                    ? font.imageOff
                                    : font.imageOn
                                }')`,
                                backgroundSize: "contain",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                transition: "opacity 0.3s ease",
                              }}
                            ></img>
                          )}
                        </div>
                      </Card>
                    );
                  })}
                </div>

                <Typography
                  className=" otherFonts heading border-2 border-[#AFE9E0] m-2 py-4 px-3  cursor-pointer rounded-3xl font-black text-center items-center text-3xl hover-effect"
                  style={{
                    fontFamily: "Beatrice-Display",
                  }}
                  onClick={() => {
                    setShowOther(true);
                  }}
                >
                  Other Fonts
                </Typography>
              </Col>
              <Col xs={12} md={12} lg={10} sm={12}>
                <div
                  className="border-2 border-[#AFE9E0] rounded-3xl p-4"
                  style={{
                    backgroundColor: `${theme}`,
                  }}
                >
                  <div className="price-container">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Beatrice-Display",
                          color: "#25CCB0",
                          fontSize: "40px",
                          fontWeight: "700",
                          fontStyle: "italic",
                          letterSpacing: "0.05em",
                        }}
                      >
                        {examples ? (
                          <>Examples</>
                        ) : (
                          <>
                            {otherFonts ? <>Other Fonts</> : <>Pick a Font</>}
                          </>
                        )}
                      </Typography>
                      <CustomWidthTooltip
                        title={
                          "Choose upto 3 fonts. For more information, kindly check examples."
                        }
                      >
                        <MyComponent
                          style={{
                            marginLeft: "10px",
                            display: "inline-block",
                          }}
                        />
                      </CustomWidthTooltip>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start", // Align items to the right for both mobile and desktop
                        justifyContent: "flex-start", // Align content to the bottom of the container
                        marginTop: "10px", // Add space between text and price
                      }}
                      className="text-end"
                    >
                      <Typography
                        style={{ fontFamily: "Beatrice" }}
                        className="text-[#07AB90]"
                      >
                        Price Estimate
                      </Typography>
                      <Typography
                        style={{ fontFamily: "Beatrice" }}
                        className="text-[#07AB90] text-md-end text-3xl"
                      >
                        ${cost}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Container>
                      <Row>
                        {examples ? (
                          <>
                            <Card
                              onClick={() => dispatch(nextStep())}
                              className=" border-none rounded-3xl m-2"
                              style={{
                                height: "450px",
                              }}
                            >
                              <Card.Body>
                                <Card.Text className=" text-[#8F8F8F]">
                                  No examples
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </>
                        ) : (
                          <>
                            {otherFonts ? (
                              <></>
                            ) : (
                              <>
                                <Col
                                  xs={12}
                                  sm={12}
                                  lg={11}
                                  md={12}
                                  style={{
                                    backgroundColor: `${theme}`,
                                    color: !theme,
                                  }}
                                >
                                  <TextBoxes
                                    setTextAreas={setTextAreas}
                                    handleDrop={handleDrop}
                                    handleTextAreaChange={handleTextAreaChange}
                                    handleTextAreaDragStart={
                                      handleTextAreaDragStart
                                    }
                                    selectedTextAreaId={selectedTextAreaId}
                                    setSelectedTextAreaId={
                                      setSelectedTextAreaId
                                    }
                                    textAreas={textAreas}
                                    textareaRefs={textareaRefs}
                                    updateTextareaHeight={updateTextareaHeight}
                                  />
                                </Col>
                                <Col
                                  style={{
                                    display: "flex",
                                  }}
                                  lg={1}
                                  xs={0}
                                  sm={0}
                                  className="desktop-div"
                                >
                                  <span className="mobile-display">
                                    <button
                                      style={{
                                        color: "#25CCB0",
                                        border: "1px solid #25CCB0",
                                        borderRadius: "16px",
                                        padding: "11px",
                                        margin: "5px",
                                      }}
                                      className={
                                        selectedTextAreaId === null
                                          ? "selected"
                                          : ""
                                      }
                                      onClick={() => handleUnselect()}
                                    >
                                      <MdOutlineDeselect size={20} />
                                    </button>
                                    <button
                                      style={{
                                        margin: "5px",
                                      }}
                                      onClick={() =>
                                        handleHorizontalAlignmentChange("left")
                                      }
                                      className="LeftAlignIcon"
                                    >
                                      <LeftAlignIcon />
                                    </button>
                                    <button
                                      style={{
                                        margin: "5px",
                                      }}
                                      onClick={() =>
                                        handleHorizontalAlignmentChange(
                                          "center"
                                        )
                                      }
                                      className="Center"
                                    >
                                      <Center />
                                    </button>
                                    <button
                                      style={{
                                        margin: "5px",
                                      }}
                                      onClick={() =>
                                        handleHorizontalAlignmentChange("right")
                                      }
                                      className="RightAlignIcon"
                                    >
                                      <RightAlignIcon />
                                    </button>
                                    <button
                                      className="deleteText"
                                      style={{
                                        margin: "5px",
                                      }}
                                      onClick={handleDelete}
                                    >
                                      <DeleteIcon />
                                    </button>
                                    <button
                                      className="notes"
                                      onClick={handleDescriptionClick}
                                    >
                                      <DescriptionIcon />
                                    </button>
                                    <Popover
                                      sx={{
                                        "& .MuiPaper-root": {
                                          borderRadius: "16px",
                                          backgroundColor: "#F4F4F4",
                                        },
                                      }}
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleDescriptionClose}
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      transformOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                    >
                                      <div
                                        style={{
                                          backgroundColor: "#F4F4F4",
                                          margin: "10px",
                                          borderRadius: "16px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              color: "#595959",
                                              fontFamily: "Beatrice",
                                              fontWeight: "600",
                                              fontSize: "15px",
                                            }}
                                          >
                                            Anything we should know?
                                          </Typography>
                                          <button
                                            style={{}}
                                            onClick={handleDescriptionClose}
                                          >
                                            <Close />
                                          </button>
                                        </div>
                                        <div
                                          style={{
                                            background: "white",
                                            borderRadius: "16px",
                                            paddingBottom: "15px",
                                          }}
                                        >
                                          <textarea
                                            style={{
                                              padding: "10px",
                                              borderRadius: "16px",
                                              height: "200px",
                                              width: "100%",
                                              color: "#8F8F8F",
                                              outline: "none",
                                              resize: "none",
                                            }}
                                            value={desc}
                                            placeholder="Add Description"
                                            onChange={(e) =>
                                              setDesc(e.target.value)
                                            }
                                          ></textarea>
                                          <div
                                            style={{
                                              margin: "0px",
                                              padding: "0px",
                                              position: "relative",
                                              float: "right",
                                              bottom: "20px",
                                              right: "15px",
                                            }}
                                          >
                                            <button
                                              onClick={() => {
                                                dispatch(
                                                  setProductFontDescription(
                                                    desc
                                                  )
                                                );
                                                handleDescriptionClose();
                                              }}
                                            >
                                              <Send />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </Popover>
                                  </span>
                                </Col>

                                <div className="mobile-tablet-div">
                                  <button
                                    style={{
                                      color: "#25CCB0",
                                      border: "1px solid #25CCB0",
                                      borderRadius: "16px",
                                      padding: "11px",
                                      margin: "5px",
                                    }}
                                    className={
                                      selectedTextAreaId === null
                                        ? "selected"
                                        : ""
                                    }
                                    onClick={() => handleUnselect()}
                                  >
                                    <MdOutlineDeselect size={20} />
                                  </button>
                                  <button
                                    style={{
                                      margin: "5px",
                                    }}
                                    onClick={() =>
                                      handleHorizontalAlignmentChange("left")
                                    }
                                    className="LeftAlignIcon"
                                  >
                                    <LeftAlignIcon />
                                  </button>
                                  <button
                                    style={{
                                      margin: "5px",
                                    }}
                                    onClick={() =>
                                      handleHorizontalAlignmentChange("center")
                                    }
                                    className="Center"
                                  >
                                    <Center />
                                  </button>
                                  <button
                                    style={{
                                      margin: "5px",
                                    }}
                                    onClick={() =>
                                      handleHorizontalAlignmentChange("right")
                                    }
                                    className="RightAlignIcon"
                                  >
                                    <RightAlignIcon />
                                  </button>
                                  <button
                                    className="deleteText"
                                    style={{
                                      margin: "5px",
                                    }}
                                    onClick={handleDelete}
                                  >
                                    <DeleteIcon />
                                  </button>
                                  <button
                                    className="notes"
                                    onClick={handleDescriptionClick}
                                  >
                                    <DescriptionIcon />
                                  </button>
                                  <Popover
                                    sx={{
                                      "& .MuiPaper-root": {
                                        borderRadius: "16px",
                                        backgroundColor: "#F4F4F4",
                                      },
                                    }}
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleDescriptionClose}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: "#F4F4F4",
                                        margin: "10px",
                                        borderRadius: "16px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: "#595959",
                                            fontFamily: "Beatrice",
                                            fontWeight: "600",
                                            fontSize: "15px",
                                          }}
                                        >
                                          Anything we should know?
                                        </Typography>
                                        <button
                                          style={{}}
                                          onClick={handleDescriptionClose}
                                        >
                                          <Close />
                                        </button>
                                      </div>
                                      <div
                                        style={{
                                          background: "white",
                                          borderRadius: "16px",
                                          paddingBottom: "15px",
                                        }}
                                      >
                                        <textarea
                                          style={{
                                            padding: "10px",
                                            borderRadius: "16px",
                                            height: "200px",
                                            width: "100%",
                                            color: "#8F8F8F",
                                            outline: "none",
                                            resize: "none",
                                          }}
                                          value={desc}
                                          placeholder="Add Description"
                                          onChange={(e) =>
                                            setDesc(e.target.value)
                                          }
                                        ></textarea>
                                        <div
                                          style={{
                                            margin: "0px",
                                            padding: "0px",
                                            position: "relative",
                                            float: "right",
                                            bottom: "20px",
                                            right: "15px",
                                          }}
                                        >
                                          <button
                                            onClick={() => {
                                              dispatch(
                                                setProductFontDescription(desc)
                                              );
                                              handleDescriptionClose();
                                            }}
                                          >
                                            <Send />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </Popover>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </Row>
                      <Row className="nightmood">
                        <BottomControls />
                      </Row>
                    </Container>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
        <OtherFonts show={showOther} close={() => setShowOther(false)} />
        {showExamples && <ExampleModal show={true} setShow={setShow} />}
      </div>
    </>
  );
};

export default EditFont;
