import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Button, Typography } from "@mui/material";
import {
  addProductAccessoriesAddOns,
  emptyOtherColors,
  fetchExamples,
  fetchHelp,
  fetchSources,
  nextStep,
  setProductAccessoriesDimmer,
  setProductBacking,
  setProductBackingImage,
  setProductBackingPaddings,
  setProductFont,
  setProductSource,
  setShowExamples,
  updatePositions,
} from "../Redux/Slice";
import SourceList from "../Components/Source/SourceList";
import ExampleModal from "../Components/ExampleModal";
import "./styles/source.css";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";

const Source = () => {
  const product = useSelector((state) => state.activeStep.product);
  const showExamples = useSelector((state) => state.activeStep.showExamples);
  const [selectedSource, setSelectedSource] = useState(
    product.source ? product.source : ""
  );
  const sources = useSelector((state) => state.activeStep.sources);
  const dispatch = useDispatch();

  const [warningOne, setWarningOne] = useState({});
  const [warningTwo, setWarningTwo] = useState({});

  const fetchWarnings = async (type, number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}warning-pages/Light Source/${type}/${number}`
      );
      switch (number) {
        case 1:
          setWarningOne(response.data);
          break;
        case 2:
          setWarningTwo(response.data);
          break;
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(fetchSources());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (product.source) {
      dispatch(fetchHelp(`Light Source/${product.source}`));
      dispatch(fetchExamples("Light Source", product.source));
      // These warnings don't exist in the database
      // fetchWarnings(product.source, 1);
      // fetchWarnings(product.source, 2);
    } else {
      dispatch(fetchHelp(`Light Source/Glass`));
      dispatch(fetchExamples("Light Source", "Glass"));
      fetchWarnings("Glass", 1);
      fetchWarnings("Glass", 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.source]);

  return (
    <div className="enable-listing-style">
      <div className=" margin-adjust parent-container">
        <Row>
          <Col
            xs={12}
            md={12}
            xl={2}
            style={{
              height: window.innerWidth > 1198 ? "100%" : "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {sources.length > 0 ? (
              <>
                <Row
                  style={{
                    marginLeft: window.innerWidth > 1199 ? "" : "20px",
                    marginRight: window.innerWidth > 1199 ? "" : "20px",
                  }}
                >
                  {sources.map((source, index) => (
                    <Col
                      key={index}
                      xxl={12}
                      xl={12}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                    >
                      <Typography
                        className={` border-2 border-[#AFE9E0] cursor-pointer ${
                          selectedSource === `${source.type}`
                            ? "selected"
                            : "hover-effect"
                        }`}
                        style={{
                          display: source?.active ? "" : "none",
                          fontFamily: "Beatrice",
                          textAlign: "center",
                          fontWeight: "600",
                          border: "2px solid #AFE9E0",
                          cursor: "pointer",
                          borderRadius: "20px",
                          paddingTop: "12px",
                          marginBottom: "20px",
                          textTransform: "uppercase",
                          padding: "20px", // Added padding
                        }}
                        key={source.type}
                        onClick={() => {
                          setSelectedSource(source.type);
                          dispatch(setProductSource(source.type));
                          dispatch(setProductFont([]));
                          dispatch(setProductBackingImage(null));
                          dispatch(setProductBacking("square"));
                          dispatch(setProductAccessoriesDimmer({}));
                          dispatch(addProductAccessoriesAddOns([]));
                          dispatch(updatePositions({ x: 0, y: 0 }));
                          dispatch(emptyOtherColors());
                          dispatch(setProductBackingPaddings({}));
                          dispatch(nextStep());
                        }}
                      >
                        {source.type}
                      </Typography>
                    </Col>
                  ))}
                </Row>
              </>
            ) : (
              <>
                <ThreeDots
                  width="100%"
                  radius="9"
                  color="#36CDB4"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </>
            )}
            <div
              style={{
                height: window.innerWidth > 1199 ? "30%" : "100%",
                display: warningOne?.warningStatus ? "" : "none",
                marginTop: window.innerWidth > 1199 ? "50px" : "50px",
                marginBottom: window.innerWidth > 1199 ? "" : "50px",
              }}
              className="warning"
            >
              <Typography className="warning-title">
                {warningOne?.title}
              </Typography>
              <div
                className="enable-listing-style warning-desc"
                dangerouslySetInnerHTML={{ __html: warningOne?.warningContent }}
              ></div>
            </div>
          </Col>

          <Col xl={10} md={12} xs={12}>
            <div
              style={{
                boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.20)",
              }}
              className="border-2 border-[#AFE9E0] rounded-3xl p-4"
            >
              <Row>
                <Col xl={6} md={6} xs={12}>
                  <Typography
                    style={{
                      width: "100%",
                      fontFamily: "Beatrice-Display",
                      color: "#25CCB0",
                      fontWeight: "700",
                      fontStyle: "italic",
                      letterSpacing: "0.05em",
                      fontSize: window.innerWidth > 768 ? "40px" : "2rem",
                    }}
                  >
                    Pick a Light Source
                  </Typography>
                </Col>
                <Col
                  xl={6}
                  md={6}
                  xs={12}
                  style={{
                    marginTop: window.innerWidth > 768 ? "" : "50px",
                    marginBottom: window.innerWidth > 768 ? "" : "50px",
                  }}
                >
                  <div
                    style={{
                      height: window.innerWidth > 768 ? "60px" : "100%",
                      display: warningTwo?.warningStatus ? "" : "none",
                    }}
                    className="warning"
                  >
                    <Typography className="warning-title">
                      {warningTwo?.title}
                    </Typography>
                    <div
                      style={{
                        fontSize: "1rem",
                      }}
                      className="enable-listing-style warning-desc"
                      dangerouslySetInnerHTML={{
                        __html: warningTwo?.warningContent,
                      }}
                    ></div>
                  </div>
                </Col>
              </Row>
              <div>
                <SourceList
                  selectedSource={selectedSource}
                  setSelectedSource={setSelectedSource}
                />
              </div>
              <Button
                sx={{
                  color: "#25CCB0",
                  fontFamily: "Beatrice",
                  fontSize: "14px",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  bgcolor: "#D8F4F1",
                  borderRadius: "16px",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                }}
                className="examples hover-effect"
                onClick={() => {
                  dispatch(setShowExamples(!showExamples));
                }}
              >
                EXAMPLES
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      {showExamples && <ExampleModal />}
    </div>
  );
};

export default Source;
