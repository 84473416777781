import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import domtoimage from "dom-to-image";

const usePNG = () => {
    const [png, setPng] = useState(null);

    const refImage = useSelector((state) => state.activeStep.product.refImage);
    const product = useSelector((state) => state.activeStep.product);

    const convertToPNG = async () => {
        try {
            const divToConvert = document.getElementById("divToConvert");

            if (product.format === "super-custom" && refImage.link) {
                const backgroundImage = new Image();
                backgroundImage.src = refImage.link || ""; // Use the background image URL
                await new Promise((resolve) => {
                    backgroundImage.onload = resolve;
                });
            }

            // Wait for a short period (e.g., 500 milliseconds) to allow drawings to render
            await new Promise((resolve) => setTimeout(resolve, 500));

            domtoimage
                .toPng(divToConvert)
                .then(async (dataUrl) => {
                    const blob = await fetch(dataUrl).then((response) => response.blob());
                    const form = new FormData();

                    form.append("image", blob, "image.png");

                    // Upload the image to the S3 bucket or perform any other necessary action
                    const response = await axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}led-backing`,
                        form
                    );

                    // Log the response data from the server

                    // Set the image data in your state (assuming you have a state variable named 'setPng')
                    setPng(response.data);
                })
                .catch(function (error) {
                    console.error("oops, something went wrong!", error);
                });
        } catch (error) {
            console.error("Error converting to PNG:", error);
        }
    };

    return {
        png,
        refImage,
        convertToPNG
    }
};

export default usePNG;