import { Typography } from "@mui/material";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import EditModal from "./EditModal";

const RefImageCard = ({ heading, selection }) => {
  const [edit] = useState("");
  const [show, setShow] = useState(false);

  return (
    <div
      style={{
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Beatrice",
            color: "#000000",
            marginBottom: "5px",
          }}
        >
          {heading}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {selection?.link ? (
          <div
            style={{
              border: "2px solid #AFE9E0",
              width: "100%",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "center",
              height: "100px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <img src={selection.link} alt="" />
          </div>
        ) : (
          <Card
            style={{
              height: "100px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px solid #AFE9E0",
              borderRadius: "16px",
            }}
          >
            <Typography
              style={{
                margin: "20px",
                fontSize: "32px",
                fontWeight: "400",
                fontFamily: "Beatrice",
                color: "#000000",
              }}
            >
              N/A
            </Typography>
          </Card>
        )}
      </div>
      <EditModal edit={edit} show={show} close={() => setShow(false)} />
    </div>
  );
};

export default RefImageCard;
