import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, styled, tooltipClasses } from "@mui/material";
import {
  addPrice,
  fetchColors,
  fetchExamples,
  fetchFonts,
  fetchHelp,
  setLockedHeight,
  setLockedWidth,
  setPowerQuantity,
  setProductBackingHeight,
  setProductBackingWidth,
  setProductFont,
  // setProductFontAlignment,
  setProductFontDescription,
  setProductSizeHeight,
  setProductSizeWidth,
} from "../Redux/Slice";
import { Typography } from "@mui/material";
import { ReactComponent as LeftAlignIcon } from "../Assets/LeftAlign.svg";
import { ReactComponent as RightAlignIcon } from "../Assets/RightAlign.svg";
import { ReactComponent as DeleteIcon } from "../Assets/DeleteIcon.svg";

import BottomControls from "../Components/BottomControls";
import Center from "../Assets/Center";
import TextBoxes from "../Components/TextBoxes";
import { calculateFontArea, getOSInfo } from "../utils/client/calculations";
import OtherFonts from "../Components/Content/OtherFonts";
import SuperContent from "./SuperCustom/SuperContent";
// import Joyride, { STATUS } from "react-joyride";
import ExampleModal from "../Components/ExampleModal";
import "./styles/Content.css";
import Info from "../Assets/SuperCustom/Info";
import axios from "axios";
import Lock from "../Assets/Lock";
import Unlock from "../Assets/Unlock";
import DescriptionButton from "../Components/DescriptionButton";
import ClearButton from "../Components/ClearButton";
import { ThreeDots } from "react-loader-spinner";
import {
  debounce,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  handleTooltipClose,
} from "../utils/client/helpers";
import PriceEstimate from "../Components/PriceEstimate";

const getRandomFontFamily = (fontsArray) => {
  // console.log(fontsArray);
  const randomIndex = Math.floor(Math.random() * fontsArray.length);
  const fontFamily = fontsArray[randomIndex].name;
  const jsonLink = fontsArray[randomIndex].jsonFile;
  const woffFile = fontsArray[randomIndex].woffFile;
  return { fontFamily, jsonLink, woffFile };
};

const Content = () => {
  const fontSize = [
    { name: "Avery", desktopSize: "37", mobileSize: "21.1", type: "Glass" },
    { name: "Carter", desktopSize: "36", mobileSize: "20.6", type: "Glass" },
    { name: "Goldie", desktopSize: "95", mobileSize: "54.3", type: "Glass" },
    { name: "Wylie", desktopSize: "38", mobileSize: "21.7", type: "Glass" },
    { name: "Cleo", desktopSize: "50", mobileSize: "28.6", type: "Glass" },
    { name: "Charlie", desktopSize: "30", mobileSize: "17.1", type: "Glass" },
    { name: "Izzy", desktopSize: "55", mobileSize: "31.4", type: "Glass" },
    { name: "Dylan", desktopSize: "55", mobileSize: "31.4", type: "Glass" },
    { name: "Kyle", desktopSize: "40", mobileSize: "22.9", type: "Glass" },
    { name: "Logan", desktopSize: "28", mobileSize: "16", type: "Glass" },
    { name: "James", desktopSize: "33", mobileSize: "18.9", type: "Glass" },
    { name: "Hunter", desktopSize: "48", mobileSize: "27.4", type: "Glass" },
    { name: "Quinn", desktopSize: "42", mobileSize: "24", type: "Glass" },
    { name: "Micah", desktopSize: "90", mobileSize: "51.4", type: "Glass" },
    { name: "Parker", desktopSize: "40", mobileSize: "22.9", type: "Glass" },
    { name: "Lola", desktopSize: "75", mobileSize: "42.9", type: "Glass" },
    { name: "Spencer", desktopSize: "70", mobileSize: "40", type: "Glass" },
    { name: "Avery", desktopSize: "35", mobileSize: "20.0", type: "LED" },
    { name: "Carter", desktopSize: "38", mobileSize: "21.7", type: "LED" },
    { name: "Goldie", desktopSize: "95", mobileSize: "54.3", type: "LED" },
    { name: "Wylie", desktopSize: "45", mobileSize: "25.7", type: "LED" },
    { name: "Cleo", desktopSize: "77", mobileSize: "44", type: "LED" },
    { name: "Charlie", desktopSize: "34", mobileSize: "19.4", type: "LED" },
    { name: "Izzy", desktopSize: "58", mobileSize: "33.1", type: "LED" },
    { name: "Dylan", desktopSize: "55", mobileSize: "31.4", type: "LED" },
    { name: "Kyle", desktopSize: "45", mobileSize: "25.7", type: "LED" },
    { name: "Logan", desktopSize: "28", mobileSize: "16", type: "LED" },
    { name: "James", desktopSize: "37", mobileSize: "21.1", type: "LED" },
    { name: "Hunter", desktopSize: "45", mobileSize: "25.7", type: "LED" },
    { name: "Quinn", desktopSize: "47", mobileSize: "26.9", type: "LED" },
    { name: "Micah", desktopSize: "105", mobileSize: "60", type: "LED" },
    { name: "Parker", desktopSize: "45", mobileSize: "25.7", type: "LED" },
    { name: "Lola", desktopSize: "78", mobileSize: "44.6", type: "LED" },
    { name: "Spencer", desktopSize: "90", mobileSize: "51.4", type: "LED" },
  ];
  const product = useSelector((state) => state.activeStep.product);
  const showConsoles = useSelector((state) => state.activeStep.showConsoles);
 
  const fontDesc = useSelector(
    (state) => state.activeStep.product.fontsDescription
  );
  const desc = fontDesc ? fontDesc : "";
  const [warningOne, setWarningOne] = useState({});
  const [warningTwo, setWarningTwo] = useState({});
  const [warningThree, setWarningThree] = useState({});
  // const activeStep = useSelector((state) => state.activeStep);
  const [textAreas, setTextAreas] = useState(
    product.fonts.length !== 0
      ? product.fonts
      : [
          {
            id: 0,
            fontFamily: "",
            jsonLink: "",
            woffFile: "",
            verticalAlign: "middle",
            horizontalAlign: "center",
            fontSize: "50px",
            tracking: "",
            value: "Type Something",
            isSplit: false,
            html: "Type Something",
            customColor: false,
            color: "#36CDB4",
          },
        ]
  );

  const [selectedTextAreaId, setSelectedTextAreaId] = useState(
    textAreas ? textAreas[0]?.id : null
  );
  const [draggedFont, setDraggedFont] = useState(false);

  const [showOther, setShowOther] = useState(false);
  const showExamples = useSelector((state) => state.activeStep.showExamples);

  const [openHeadingInfo, setOpenHeadingInfo] = useState(false);

  const textareaRefs = useRef(Array(textAreas.length).fill(null));

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      arrow
      placement="bottom-start"
      {...props}
      style={{
        backgroundColor: "",
      }}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "14px",
      maxWidth: 200,
      backgroundColor: "#D9FF9E",
      color: "#03705E",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#D9FF9E",
    },
  });

  const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref}>
        <Info />
      </div>
    );
  });


  const updateTextareaHeight = (index) => {
    // console.log('updateTextAreaHeight');
    // console.log(textareaRefs);

    const textareaRef = textareaRefs?.current[index];
    if (textareaRef) {
      // console.log(textareaRef);
      //const rect = textareaRef?.getBoundingClientRect();

      // const rect1 = textareaRef?.node?.getBoundingClientRect();
      // const rect2 = textareaRef?.el?.getBoundingClientRect();
      // const rect = textareaRef.el.current.getBoundingClientRect()

      // console.log(rect);

      // textareaRef.style.height = "auto";
      // textareaRef.style.height = `${textareaRef?.scrollHeight + 10}px`;
    } else {
      console.log('no text area ref');
    }
  };

  function getFontSize(fontFamily, type) {
    // console.log(fontFamily, type);
    const fontSizeEntry = fontSize.find(
      (font) => font.name === fontFamily && font.type === type
    );
    // console.log(fontSizeEntry);
    if (window.innerWidth > 762) {
      return fontSizeEntry.desktopSize;
    } else {
      return fontSizeEntry.mobileSize;
    }
  }

  const hasVisitedContentPage = localStorage.getItem("hasVisitedContentPage");
  // Handle drop event on the delete icon
  const handleDelete = (e) => {
    e.preventDefault();
    removeTextArea(parseInt(selectedTextAreaId));
    setSelectedTextAreaId(null);
  };

  const handleHorizontalAlignmentChange = (alignment) => {
    // console.log(selectedTextAreaId);
    if (selectedTextAreaId != null) {
      const updatedTextAreas = textAreas.map((textarea) => {
        if (textarea.id === parseInt(selectedTextAreaId)) {
          return { ...textarea, horizontalAlign: alignment };
        }
        return textarea;
      });

      setTextAreas(updatedTextAreas);
      dispatch(setProductFont(updatedTextAreas));
    }
    setSelectedTextAreaId(null);
  };

  const handleTextAreaDragStart = (e, textArea) => {
    console.log('handleTextAreaDragStart');
    e.dataTransfer.setData("text/plain", textArea.id);
  };

  const handleDrop = (e, targetId) => {
    console.log('handleDrop');
    // console.log(targetId);
    e.preventDefault();
    const fontFamily = e.dataTransfer.getData("text/plain");
    addSameTextArea(targetId, fontFamily);
    setSelectedTextAreaId(null);
  };

  const dispatch = useDispatch();

  const addTextArea = (selectedFont) => {
    if (showConsoles) {
      console.log('Add Text Area');
      console.log(selectedTextAreaId);
    }
    if (selectedTextAreaId !== null) {
      // console.log("selected Font", selectedFont);
      const updatedTextAreas = textAreas.map((textarea) => {
        if (textarea.id === selectedTextAreaId) {
          return {
            ...textarea,
            fontFamily: selectedFont.name,
            jsonLink: selectedFont.jsonFile,
            woffFile: selectedFont.woffFile,
            fontSize: getFontSize(selectedFont.name, product.source),
            tracking: selectedFont.tracking,
          };
        }
        return textarea;
      });

      setTextAreas(updatedTextAreas);
      dispatch(setProductFont(updatedTextAreas));
    } else {
      // console.log(selectedFont);
      let fontFamily, jsonLink, woffFile, fontSize, tracking;
      fonts.forEach((font) => {
        if (font.name === selectedFont.name) {
          // console.log(font);
          fontFamily = font.name;
          jsonLink = font.jsonFile;
          woffFile = font.woffFile;
          fontSize = getFontSize(font.name, product.source);
          tracking = font.tracking;
        }
      });
      if (textAreas.length < 3) {
        let newId = 0;
        if (textAreas.length > 0) {
          newId = Math.max(...textAreas.map((textarea) => textarea.id)) + 1;
        }
        // console.log(newId);

        const newTextArea = {
          id: newId,
          fontFamily,
          jsonLink,
          woffFile,
          verticalAlign: "middle",
          horizontalAlign: "center",
          tracking,
          fontSize,
          isSplit: false,
          value: "Type Something",
          html: "Type Something",
          color: "#36CDB4",
          customColor: false,
        };
        document.documentElement.style.setProperty("--stroke-color", "#36CDB4");
        const updatedTextAreas = [...textAreas, newTextArea];
        setTextAreas(updatedTextAreas);
      }
    }
  };

  const addSameTextArea = (id, fontFamily) => {
    if (draggedFont) {
      // console.log(fontFamily);
      let jsonLink, woffFile, fontSize, tracking;
      fonts.forEach((font) => {
        if (font.name === fontFamily) {
          jsonLink = font.jsonFile;
          woffFile = font.woffFile;
          fontSize = getFontSize(font.name, product.source);
          tracking = font.tracking;
        }
      });
      const updatedTextAreas = textAreas.map((textarea) => {
        if (textarea.id === parseInt(id)) {
          return {
            ...textarea,
            fontFamily: fontFamily,
            jsonLink,
            woffFile,
            fontSize,
            tracking,
          };
        }
        return textarea;
      });

      setTextAreas(updatedTextAreas);
      dispatch(setProductFont(updatedTextAreas));
    }
    setSelectedTextAreaId(null);
  };

  const removeTextArea = (id) => {
    const updatedTextAreas = textAreas.filter((textarea) => textarea.id !== id);
    setTextAreas(updatedTextAreas);
    dispatch(setProductFont(updatedTextAreas));
    // console.log("removed");
    setSelectedTextAreaId(null);
  };

  const handleTextAreaChange = (id, event) => {
    // console.log("handleTextAreaChange :");
    const updatedTextAreas = textAreas.map((textarea) => {
      if (textarea.id === id) {
        // console.log(textarea.id);
        const inputHtml = event.target.value;
        console.log('inputHtml', inputHtml);

        let plainText = inputHtml
          .replace(/<div style="font-family: inherit;">/g, "\n")
          .replace(/<[^>]*>/g, "")
          // .replace(/<\/div>/g, "") // Remove HTML tags
          .replace(/&nbsp;/g, " "); // Replace &nbsp; with a space

        plainText = plainText
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&amp;/g, "&");


        const replacedHtml = inputHtml
          .replace(/<span[^>]*>(.*?)<\/span>/g, "$1")
          .replace(/<div>/g, '<div style="font-family: inherit;">');

        // console.log(replacedText);
        // console.log(plainText);
        const value = plainText;
        const lines = value?.split("\n");
        const longestLine = lines?.reduce(
          (acc, line) => (line?.length > acc ? line?.length : acc),
          0
        );
        // Ok so calculating width by number of characters
        const newWidth = (longestLine + 1) * 30;
        // console.log('lines', lines);
        // console.log('longestLine', longestLine);
        // console.log('newWidth', newWidth);
        if (textarea?.isSplit) {
          // console.log("split handle");
          // Create the new letters array based on the new text
          const newLetters = value?.split("").map((letter, index) => {
            const existingLetter = textarea?.letters?.find(
              (l) => l.id === index
            );
            const color = existingLetter
              ? existingLetter?.color
              : textarea.color;
            return { id: index, letter, color };
          });
          // console.log(newLetters);

          return {
            ...textarea,
            value,
            html: replacedHtml,
            width: newWidth,
            letters: newLetters,
          };
        } else {
          return {
            ...textarea,
            value,
            html: replacedHtml,
            width: newWidth,
          };
        }
      }
      return textarea;
    });
    // console.log(updatedTextAreas);
    setTextAreas(updatedTextAreas);
    dispatch(setProductFont(updatedTextAreas));
  };

  const fetchWarnings = async (type, number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}warning-pages/Content/${type}/${number}`
      );
      switch (number) {
        case 1:
          setWarningOne(response.data);
          break;
        case 2:
          setWarningTwo(response.data);
          break;
        case 3:
          setWarningThree(response.data);
          break;
        default:
          break;
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const theme = useSelector((state) => state.activeStep.theme);

  const fonts = useSelector((state) => state.activeStep.fonts);
  if (showConsoles) {
    console.log('fonts', fonts);
  }
  const colors = useSelector((state) => state.activeStep.colors);

  const bendingCost = useSelector((state) => state.activeStep.bendingCostPerFt);
  const colorCost = useSelector((state) => state.activeStep.colorCost);
  const tech22Cost = useSelector((state) => state.activeStep.tech22Cost);
  const blackAccessoryCost = useSelector(
    (state) => state.activeStep.blackAccessoryCost
  );
  const clearPlexiCost = useSelector(
    (state) => state.activeStep.clearPlexiCost
  );

  const Hmax = useSelector((state) => state.activeStep.plexiHeightMax);
  // console.log(Hmax);
  const Wmax = useSelector((state) => state.activeStep.plexiWidthMax);
  const markUp = useSelector((state) => state.activeStep.markup);
  const discount = useSelector((state) => state.activeStep.discount);
  const materialLengthPerTech = useSelector(
    (state) => state.activeStep.materialLengthPerTech
  );

  const handleUnselect = () => {
    // console.log(selectedTextAreaId);
    setSelectedTextAreaId(null);
  };

  const calculateGlassFontPrice = async () => {
    try {
      console.log("Calculating GLass Price");
      if (textAreas[0]?.value || textAreas[1]?.value || textAreas[2]?.value) {
        // console.log(selectedFont);

        let fontPricePerFt = 0;
        let materialLength = 0;
        let neonWidth = 0;
        let neonHeight = 0;
        let signWidth = 0;
        let signHeight = 0;
        let glassCost = 0;
        let unitNumber = 0;
        let segments = 0;
        if (showConsoles) {
          console.log("Bending Cost :", bendingCost);
          console.log("EGL White Cost :", colorCost);
          console.log("Max Plexi Height :", Hmax);
          console.log("Max Plexi Width :", Wmax);
          console.log('textAreas.length', textAreas.length)
        }
        if (textAreas.length === 1 && textAreas[0]?.value) {
          if (showConsoles) {
            console.log("------------------ Single Font");
            // console.log(
            //   textAreas[0]?.value,
            //   textAreas[0]?.fontFamily,
            //   textAreas[0]?.jsonLink
            // );
          }
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );

          materialLength = result?.materialLength;
          neonWidth = result?.totalWidth;
          neonHeight = result?.maxHeight;
          signWidth = result?.totalWidth + 2 * 2;
          signHeight = result?.maxHeight + 2 * 2;
          //if (showConsoles) {
            console.log("Material Length : ", materialLength);

            console.log("Neon Width : ", neonWidth);
            console.log("Neon Height : ", neonHeight);
            console.log("Sign Width : ", signWidth);
            console.log("Sign Height : ", signHeight);
          //}

          dispatch(setProductSizeWidth(Number(Number(neonWidth)?.toFixed(2))));
          dispatch(
            setProductSizeHeight(Number(Number(neonHeight)?.toFixed(2)))
          );

          // console.log(Number(signWidth)?.toFixed(2));
          // console.log(materialLength);

          fonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt = font.price;
              if (showConsoles) {
                console.log("Font Price: ", fontPricePerFt);
              }
            }
          });

          glassCost =
            Number(fontPricePerFt + bendingCost + colorCost) *
            (materialLength / 12);
          if (showConsoles) {
            console.log("Glass Cost:", glassCost);
          }

          unitNumber = Number(
            Math.ceil(neonWidth / 24) + Math.ceil(neonHeight / 10)
          );
          dispatch(setPowerQuantity(unitNumber));
          if (showConsoles) {
            console.log("Unit Number:", unitNumber);
          }

          segments = Number(
            Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
          );
          if (showConsoles) {
            console.log("Segments:", segments);
          }
        } else if (textAreas.length === 2) {
          //if (showConsoles) {
            console.log("------------------Double Font");
          //}
          let fontPricePerFt1 = 0;
          let fontPricePerFt2 = 0;
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result1);
          let materialLength1 = result1?.materialLength;
          let neonWidth1 = result1?.totalWidth;
          let neonHeight1 = result1?.maxHeight;

          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          // console.log(result2);
          let materialLength2 = result2?.materialLength;
          let neonWidth2 = result2?.totalWidth;
          let neonHeight2 = result2?.maxHeight;

          dispatch(
            setProductSizeWidth(
              Number(Number(Math.max(neonWidth1, neonWidth2))?.toFixed(2))
            )
          );
          dispatch(
            setProductSizeHeight(
              Number(Number(neonHeight1 + neonHeight2)?.toFixed(2))
            )
          );

          // console.log(Number(signHeight1 + signHeight2 / 2)?.toFixed(2));
          // console.log(materialLength / 12);

          fonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          fonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          if (showConsoles) {
            console.log(
              `Material Length of ${textAreas[0].value} :`,
              materialLength1
            );
            console.log(`Neon Width of ${textAreas[0].value} :`, neonWidth1);
            console.log(`Neon Height of ${textAreas[0].value} :`, neonHeight1);
            console.log(
              `Font Price of ${textAreas[0].fontFamily} :`,
              fontPricePerFt1
            );

            console.log(
              `Material Length of ${textAreas[1].value} :`,
              materialLength2
            );
            console.log(`Neon Width of ${textAreas[1].value} :`, neonWidth2);
            console.log(`Neon Height of ${textAreas[1].value} :`, neonHeight2);
            console.log(
              `Font Price of ${textAreas[1].fontFamily} :`,
              fontPricePerFt2
            );
          }
          glassCost = Number(
            Number(fontPricePerFt1 + bendingCost + colorCost) *
              (materialLength1 / 12) +
              Number(fontPricePerFt2 + bendingCost + colorCost) *
                (materialLength2 / 12)
          );
          if (showConsoles) {
            console.log("Glass Cost:", glassCost);
          }

          // console.log("Max of Neon Width :", Math.max(neonWidth1, neonWidth2));
          unitNumber = Number(
            Math.ceil(Math.max(neonWidth1, neonWidth2) / 24) +
              Math.ceil((neonHeight1 + neonHeight2) / 10)
          );
          dispatch(setPowerQuantity(unitNumber));
          if (showConsoles) {
            console.log("Unit Number:", unitNumber);
          }

          signHeight = Number(neonHeight1 + neonHeight2) + 2 * 2;
          signWidth = Math.max(neonWidth1, neonWidth2) + 2 * 2;

          if (showConsoles) {
            console.log("Sign Height :", signHeight);
            console.log("Sign Width :", signWidth);
          }
          segments = Number(
            Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
          );
          if (showConsoles) {
            console.log("Segments:", segments);
          }
          materialLength = materialLength1 + materialLength2;
          if (showConsoles) {
            console.log("Total Material Length :", materialLength);
          }
        } else if (textAreas.length === 3) {
          //if (showConsoles) {
            console.log("------------------Triple Font");
          //}
          let fontPricePerFt1 = 0;
          let fontPricePerFt2 = 0;
          let fontPricePerFt3 = 0;
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result1);
          let materialLength1 = result1?.materialLength;
          let neonWidth1 = result1?.totalWidth;
          let neonHeight1 = result1?.maxHeight;

          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          // console.log(result2);
          let materialLength2 = result2?.materialLength;
          let neonWidth2 = result2?.totalWidth;
          let neonHeight2 = result2?.maxHeight;

          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink,
            textAreas[2]?.woffFile
          );
          // console.log(result2);
          let materialLength3 = result3?.materialLength;
          let neonWidth3 = result3?.totalWidth;
          let neonHeight3 = result3?.maxHeight;

          dispatch(
            setProductSizeWidth(
              Number(
                Number(Math.max(neonWidth1, neonWidth2, neonWidth3))?.toFixed(2)
              )
            )
          );
          dispatch(
            setProductSizeHeight(
              Number(
                Number(neonHeight1 + neonHeight2 + neonHeight3)?.toFixed(2)
              )
            )
          );

          fonts.forEach((font) => {
            if (font.name === textAreas[0]?.fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          fonts.forEach((font) => {
            if (font.name === textAreas[1]?.fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          fonts.forEach((font) => {
            if (font.name === textAreas[2]?.fontFamily) {
              // console.log(font.name);
              fontPricePerFt3 = font.price;
            }
          });

          if (showConsoles) {
            console.log(
              `Material Length of ${textAreas[0].value} :`,
              materialLength1
            );
            console.log(`Neon Width of ${textAreas[0].value} :`, neonWidth1);
            console.log(`Neon Height of ${textAreas[0].value} :`, neonHeight1);
            console.log(
              `Font Price of ${textAreas[0].fontFamily} :`,
              fontPricePerFt1
            );

            console.log(
              `Material Length of ${textAreas[1].value} :`,
              materialLength2
            );
            console.log(`Neon Width of ${textAreas[1].value} :`, neonWidth2);
            console.log(`Neon Height of ${textAreas[1].value} :`, neonHeight2);
            console.log(
              `Font Price of ${textAreas[1].fontFamily} :`,
              fontPricePerFt2
            );

            console.log(
              `Material Length of ${textAreas[2].value} :`,
              materialLength3
            );
            console.log(`Neon Width of ${textAreas[2].value} :`, neonWidth3);
            console.log(`Neon Height of ${textAreas[2].value} :`, neonHeight3);
            console.log(
              `Font Price of ${textAreas[2].fontFamily} :`,
              fontPricePerFt3
            );
          }
          // console.log(fontPricePerFt);
          glassCost = Number(
            Number(fontPricePerFt1 + bendingCost + colorCost) *
              (materialLength1 / 12) +
              Number(fontPricePerFt2 + bendingCost + colorCost) *
                (materialLength2 / 12) +
              Number(fontPricePerFt3 + bendingCost + colorCost) *
                (materialLength3 / 12)
          );
          if (showConsoles) {
            console.log("Glass Cost:", glassCost);
          }

          unitNumber = Number(
            Math.ceil(Math.max(neonWidth1, neonWidth2, neonWidth3) / 24) +
              Math.ceil((neonHeight1 + neonHeight2 + neonHeight3) / 10)
          );
          dispatch(setPowerQuantity(unitNumber));
          if (showConsoles) {
            console.log("Unit Number:", unitNumber);
          }

          signHeight = Number(neonHeight1 + neonHeight2 + neonHeight3) + 2 * 2;
          signWidth = Math.max(neonWidth1, neonWidth2, neonWidth3) + 2 * 2;
          if (showConsoles) {
            console.log("Sign Height :", signHeight);
            console.log("Sign Width :", signWidth);
          }

          segments = Number(
            Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
          );
          if (showConsoles) {
            console.log("Segments:", segments);
          }
          materialLength = materialLength1 + materialLength2 + materialLength3;
          if (showConsoles) {
            console.log("Total Material Length :", materialLength);
          }
        } else {
          console.log('more than three');
        }

        dispatch(setProductBackingWidth(4));
        dispatch(setProductBackingHeight(4));
        if (showConsoles) {
          console.log("Material Length Per Tech", materialLengthPerTech);
        }
        let techs = Number(
          Math.ceil(
            (materialLength / 12 + unitNumber) / materialLengthPerTech
          ) +
            segments -
            1
        );
        if (showConsoles) {
          console.log("Techs:", techs);
          console.log("Tech-22 Cost :", tech22Cost);
        }

        let transformerCost = Number(techs * tech22Cost);
        if (showConsoles) {
          console.log("Transformer Cost:", transformerCost);
          console.log("Clear Plexi  Cost :", clearPlexiCost);
        }
        let backingCost = Number(signWidth * signHeight * clearPlexiCost);
        if (showConsoles) {
          console.log("Backing Cost:", backingCost);
          console.log("Basic Accessories Cost :", blackAccessoryCost);
        }
        let accessoriesCost = Number(unitNumber * blackAccessoryCost);
        if (showConsoles) {
          console.log("Accessories Cost:", accessoriesCost);
        }

        let totalCost = Number(
          glassCost + transformerCost + backingCost + accessoriesCost
        );
        if (showConsoles) {
          console.log("Total Cost:", totalCost);
          console.log("Mark Up Percentage :", markUp);
          console.log("Discount Percentage :", discount);
        }
        let decimalMarkUp = markUp / 100;
        let decimalDiscount = discount / 100;

        let markupAmount = decimalMarkUp * totalCost;
        if (showConsoles) {
          console.log("MarkUp Amount:", markupAmount);
          console.log("Decimal Discount:", decimalDiscount);
        }
        let markUpPrice = totalCost + markupAmount;
        let discountFactor = Number(1 - decimalDiscount);
        if (showConsoles) {
          console.log("MarkUp Price :", markUpPrice);
          console.log("Discounted Factor :", discountFactor);
        }

        let price = Number(markUpPrice * discountFactor);
        if (showConsoles) {
          console.log("Price:", price);
        }
        if (isNaN(price)) {
          dispatch(addPrice(0));
        } else {
          // Print Price
          dispatch(addPrice(Math.ceil(price)));
        }
      } else if (!textAreas[0]?.value) {
        dispatch(addPrice(0));
        dispatch(setProductSizeWidth(0));
        dispatch(setProductSizeHeight(0));
        dispatch(setProductBackingWidth(0));
        dispatch(setProductBackingHeight(0));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const LEDCostPerMaterial = useSelector((state) => state.activeStep.colorCost);
  const InterShippingCost = useSelector(
    (state) => state.activeStep.InterShippingCost
  );

  const calculateLEDFontPrice = async () => {
    // console.log("Calculating LED Price");
    if (textAreas[0]?.value || textAreas[1]?.value) {
      // console.log(selectedFont);

      let materialLength = 0;
      let signWidth = 0;
      let signHeight = 0;
      let backingArea = 0;
      // let minPowerSupply = 0;
      let LEDCost = 0;
      let segments = 0;
      if (showConsoles) {
        console.log("Cool White Cost :", LEDCostPerMaterial);
        console.log("Max Plexi Height :", Hmax);
        console.log("Max Plexi Width :", Wmax);
      }
      if (textAreas.length === 1 && textAreas[0].value) {
        if (showConsoles) {
          console.log("------------------Single Font");
        }
        let result = await calculateFontArea(
          textAreas[0]?.value,
          textAreas[0]?.fontFamily,
          textAreas[0]?.jsonLink,
          textAreas[0]?.woffFile
        );
        materialLength = result?.materialLength;
        signWidth = result?.totalWidth + 2 * 2;
        signHeight = result?.maxHeight + 2 * 2;
        dispatch(setProductSizeWidth(signWidth - 4));
        dispatch(setProductSizeHeight(signHeight - 4));
        if (showConsoles) {
          console.log("Material Length : ", materialLength);

          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }

        LEDCost = Number(LEDCostPerMaterial) * materialLength;
        if (showConsoles) {
          console.log("LED Cost :", LEDCost);
        }

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments :", segments);
        }
        backingArea = Number(signHeight * signWidth);
        if (showConsoles) {
          console.log("Backing Area :", backingArea);
        }
      } else if (textAreas.length === 2) {
        if (showConsoles) {
          console.log("------------------Double Fonts");
        }
        let result1 = await calculateFontArea(
          textAreas[0]?.value,
          textAreas[0]?.fontFamily,
          textAreas[0]?.jsonLink,
          textAreas[0]?.woffFile
        );
        let materialLength1 = result1?.materialLength;
        let signWidth1 = result1?.totalWidth;
        let signHeight1 = result1?.maxHeight;

        let result2 = await calculateFontArea(
          textAreas[1]?.value,
          textAreas[1]?.fontFamily,
          textAreas[1]?.jsonLink,
          textAreas[1]?.woffFile
        );
        let materialLength2 = result2?.materialLength;
        let signWidth2 = result2?.totalWidth;
        let signHeight2 = result2?.maxHeight;

        LEDCost = Number(
          Number(LEDCostPerMaterial) * materialLength1 +
          Number(LEDCostPerMaterial) * materialLength2
        );
        if (showConsoles) {
          // console.log(signWidth);
          // console.log(materialLength / 12);
          // console.log(fontPricePerFt);
          console.log("LED Cost :", LEDCost);
        }

        materialLength = materialLength1 + materialLength2;
        signHeight = Number(signHeight1 + signHeight2) + 2 * 2;
        signWidth = Math.max(signWidth1, signWidth2) + 2 * 2;
        if (showConsoles) {
          console.log("Material Length : ", materialLength);

          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }
        dispatch(setProductSizeWidth(Number(signWidth - 4)));
        dispatch(setProductSizeHeight(signHeight - 4));

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments :", segments);
        }

        backingArea = Number(signHeight * signWidth);

        if (showConsoles) {
          console.log("Backing Area :", backingArea);
        }
      } else if (textAreas.length === 3) {
        if (showConsoles) {
          console.log("------------------Triple Fonts");
        }
        let result1 = await calculateFontArea(
          textAreas[0]?.value,
          textAreas[0]?.fontFamily,
          textAreas[0]?.jsonLink,
          textAreas[0]?.woffFile
        );
        // console.log(result1);
        let materialLength1 = result1?.materialLength;
        let signWidth1 = result1?.totalWidth;
        let signHeight1 = result1?.maxHeight;

        let result2 = await calculateFontArea(
          textAreas[1]?.value,
          textAreas[1]?.fontFamily,
          textAreas[1]?.jsonLink,
          textAreas[1]?.woffFile
        );
        // console.log(result2);
        let materialLength2 = result2?.materialLength;
        let signWidth2 = result2?.totalWidth;
        let signHeight2 = result2?.maxHeight;
        let result3 = await calculateFontArea(
          textAreas[2]?.value,
          textAreas[2]?.fontFamily,
          textAreas[2]?.jsonLink,
          textAreas[2]?.woffFile
        );
        // console.log(result3);
        let materialLength3 = result3?.materialLength;
        let signWidth3 = result3?.totalWidth;
        let signHeight3 = result3?.maxHeight;

        LEDCost = Number(
          Number(LEDCostPerMaterial) * materialLength1 +
          Number(LEDCostPerMaterial) * materialLength2 +
          Number(LEDCostPerMaterial) * materialLength3
        );
        if (showConsoles) {
          // console.log(signWidth);
          // console.log(materialLength / 12);
          // console.log(LEDCostPerMaterial);
          console.log("LED Cost :", LEDCost);
        }
        materialLength = materialLength1 + materialLength2 + materialLength3;
        signHeight = Number(signHeight1 + signHeight2 + signHeight3) + 2 * 2;
        signWidth = Math.max(signWidth1, signWidth2, signWidth3) + 2 * 2;
        if (showConsoles) {
          console.log("Material Length : ", materialLength);
          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }

        dispatch(setProductSizeWidth(signWidth - 4));
        dispatch(setProductSizeHeight(signHeight - 4));
        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments :", segments);
        }

        backingArea = Number(signHeight * signWidth);
        if (showConsoles) {
          console.log("Backing Area :", backingArea);
        }
      }
      dispatch(setProductBackingWidth(4));
      dispatch(setProductBackingHeight(4));

      if (showConsoles) {
        console.log("Material Length Per Power Supply", materialLengthPerTech);
      }
      let powerSupplies = Number(
        Math.ceil(materialLength / 12 / materialLengthPerTech)
      );
      if (showConsoles) {
        console.log("Number of Power Supply :", powerSupplies);

        console.log("Cost per Power Supply :", tech22Cost);
      }
      let powerSupplyCost = Number(powerSupplies * tech22Cost);
      if (showConsoles) {
        console.log("Power Supply Cost :", powerSupplyCost);

        console.log("Clear Plexi Cost :", clearPlexiCost);
      }
      let backingCost = Number(backingArea * clearPlexiCost);
      if (showConsoles) {
        console.log("Backing Cost :", backingCost);

        console.log("Basic Accessories Cost :", blackAccessoryCost);
      }
      let accessoriesCost = Number(segments * blackAccessoryCost);
      if (showConsoles) {
        console.log("Accessories Cost :", accessoriesCost);

        console.log("International Shipping Cost :", InterShippingCost);
      }
      let shippingCost = Number(
        Number(backingArea * InterShippingCost) / segments
      );
      if (showConsoles) {
        console.log("Shipping Cost :", shippingCost);
      }
      let totalCost = Number(
        LEDCost + powerSupplyCost + backingCost + accessoriesCost + shippingCost
      );
      if (showConsoles) {
        console.log("Total Cost:", totalCost);

        console.log("Mark Up Percentage :", markUp);
        console.log("Discount Percentage :", discount);
      }
      let decimalMarkUp = markUp / 100;
      let decimalDiscount = discount / 100;

      let markupAmount = decimalMarkUp * totalCost;
      if (showConsoles) {
        console.log("MarkUp Amount:", markupAmount);
        console.log("Decimal Discount:", decimalDiscount);
      }
      let markUpPrice = totalCost + markupAmount;
      let discountFactor = Number(1 - decimalDiscount);
      if (showConsoles) {
        console.log("MarkUp Price :", markUpPrice);
        console.log("Discounted Factor :", discountFactor);
      }

      let price = Number(markUpPrice * discountFactor);
      if (showConsoles) {
        console.log("Price :", price);
      }
      if (isNaN(price)) {
        dispatch(addPrice(0));
      } else {
        dispatch(addPrice(Math.ceil(price)));
      }
    } else if (!textAreas[0]?.value) {
      dispatch(addPrice(0));
      dispatch(setProductSizeWidth(0));
      dispatch(setProductSizeHeight(0));
    }
  };

  useEffect(() => {
    dispatch(fetchFonts(product.source));
    dispatch(fetchColors(product.source));
    // These warnings dont exist in the database
    // fetchWarnings(product.source, 1);
    // fetchWarnings(product.source, 2);
    fetchWarnings(product.source, 3);

    // Mark the user as visited after the first visit
    if (!hasVisitedContentPage) {
      localStorage.setItem("hasVisitedContentPage", "true");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (product.format === "super-custom") {
      dispatch(fetchHelp(`Super Content/${product.source}`));
      dispatch(fetchExamples("Super Content", product.source));
    } else {
      dispatch(fetchHelp(`Content/${product.source}`));
      dispatch(fetchExamples("Content", product.source));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   // textAreas.forEach((_, index) => updateTextareaHeight(index));
  //   if (product.source === "Glass") {
  //     calculateGlassFontPrice();
  //   } else if (product.source === "LED") {
  //     calculateLEDFontPrice();
  //   }
  // }, [textAreas]);

  useEffect(() => {
    // This Changed so we are updating the product here
    if (product.source === "Glass") {
      calculateGlassFontPrice();
    } else if (product.source === "LED") {
      calculateLEDFontPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, textAreas, fonts]);

  useEffect(() => {
    // Ensure 'fonts' is non-empty before updating the state

    if (!textAreas[0]?.fontFamily) {
      if (fonts.length !== 0) {
        const randomFont = getRandomFontFamily(fonts);
        // console.log(randomFont);
        setTextAreas((prevTextAreas) => {
          return prevTextAreas.map((area) => ({
            ...area,
            fontFamily: randomFont.fontFamily,
            jsonLink: randomFont.jsonLink,
            woffFile: randomFont.woffFile,
            fontSize: getFontSize(randomFont.fontFamily, product.source),
            tracking: randomFont.tracking,
          }));
        });
      }
      if (colors.length !== 0) {
        setTextAreas((prevTextAreas) => {
          return prevTextAreas.map((area) => ({
            ...area,
            color: "#36CDB4",
            customColor: false,
          }));
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fonts, colors, textAreas]);

  const [hoveredStates, setHoveredStates] = useState([]); // Initialize an array of hover states

  const handleHoverChange = (index, isHovered) => {
    const newHoveredStates = Array(hoveredStates.length).fill(false);

    // Set the specified index to the provided isHovered value
    newHoveredStates[index] = isHovered;

    // Update the state
    setHoveredStates(newHoveredStates);
  };
  // const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobileOrTablet(window.innerWidth <= 768); // Adjust the breakpoint as needed
  //   };

  //   // Initial check
  //   handleResize();

  //   // Add event listener to update when the window is resized
  //   window.addEventListener("resize", handleResize);

  //   // Clean up the event listener
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  useEffect(() => {
    if (product.format === "custom") {
      const updatedTextAreas = textAreas.map((textArea) => {
        let updatedTextArea = { ...textArea };

        // Check if the fontFamily is not in the fonts array
        if (
          !fonts?.some((font) => font?.name === updatedTextArea?.fontFamily)
        ) {
          // console.log("Switching Font");
          const randomFontIndex = Math.floor(Math.random() * fonts?.length);
          updatedTextArea.fontFamily = fonts[randomFontIndex]?.name;
        }

        // Check if the color is not in the colors array
        // if (!colors?.some((color) => color?.code === updatedTextArea?.color)) {
        //   // console.log("Switching Color");
        //   const randomColorIndex = Math.floor(Math.random() * colors?.length);
        //   updatedTextArea.color = colors[randomColorIndex]?.code;
        //   // document.documentElement.style.setProperty(
        //   //   "--stroke-color",
        //   //   colors[randomColorIndex]?.code
        //   // );
        // }

        return updatedTextArea;
      });

      dispatch(setProductFont(updatedTextAreas));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fonts, colors, textAreas]);

  // console.log(textAreas);
  useEffect(() => {
    dispatch(setLockedWidth(0));
    dispatch(setLockedHeight(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Preload images
  const preloadImages = () => {
    fonts.forEach((font) => {
      const imgOn = new Image();
      const imgOff = new Image();
      imgOn.src = font.imageOn;
      imgOff.src = font.imageOff;
    });
  };

  // Call preloadImages function when your component mounts
  useEffect(() => {
    preloadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fonts]);

  const debouncedHandleHoverChange = debounce(handleHoverChange, 300);

  useEffect(() => {
    // Attach debounced function to scroll event
    window.addEventListener("scroll", debouncedHandleHoverChange);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", debouncedHandleHoverChange);
    };
  }, [debouncedHandleHoverChange]);

  return (
    <>
      {/* {showJoyride && (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          run={run}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
 */}
      <div className=" margin-adjust ">
        {product.format === "super-custom" ? (
          <>
            <SuperContent />
          </>
        ) : (
          <>
            <Row>
              <Col md={12} lg={2} xs={12} sm={12}>
                <div
                  style={{
                    height: "60vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    // margin: "auto",
                  }}
                >
                  {fonts.length > 0 ? (
                    <>
                      <Row draggable="false">
                        {fonts?.map((font, index) => {
                          const isSelected = textAreas.some(
                            (textArea) => textArea.fontFamily === font.name
                          );

                          return (
                            <Col key={index} md={4} lg={12} xs={6} sm={6}>
                              <div
                                draggable
                                onDragStart={(e) => {
                                  e.dataTransfer.setData(
                                    "text/plain",
                                    font.name
                                  );
                                  setDraggedFont(true);
                                }}
                                onDragEnd={() => {
                                  // setSelectedFont(font.name);
                                  setDraggedFont(false);
                                }}
                                className={`border-2 border-[#AFE9E0] m-3 font-black text-center items-center text-3xl cursor-pointer ${
                                  isSelected ? "selected" : "hover-effect"
                                } `}
                                style={{
                                  fontFamily: font.name,
                                  // height: "10%",
                                  flex: "1 0 100px", // Set to 1 to allow flex items to grow and shrink, minimum width of 100px
                                  display: "flex",
                                  justifyContent: "center",
                                  borderRadius: "18px",
                                }}
                                onClick={() => {
                                  // console.log(font);
                                  addTextArea(font);
                                }}
                                onTouchStart={() => {
                                  addTextArea(font);
                                  handleHoverChange(index, true);
                                }}
                                onTouchEnd={() => {
                                  handleHoverChange(index, false);
                                }}
                                onMouseEnter={() => {
                                  let userPlatform = getOSInfo();
                                  if (
                                    !(
                                      userPlatform === "iPhone" ||
                                      userPlatform === "Android"
                                    )
                                  ) {
                                    handleHoverChange(index, true);
                                  }
                                }}
                                onMouseLeave={() => {
                                  let userPlatform = getOSInfo();
                                  if (
                                    !(
                                      userPlatform === "iPhone" ||
                                      userPlatform === "Android"
                                    )
                                  ) {
                                    handleHoverChange(index, false);
                                  }
                                }}
                              >
                                {hoveredStates[index] ? (
                                  <img
                                    loading="lazy"
                                    src={font.imageOff}
                                    alt={font.name}
                                    style={{
                                      borderRadius: "16px",
                                      width: "100%",
                                      height: "100%",
                                      fontFamily: font.name,
                                    }}
                                  />
                                ) : (
                                  <img
                                    loading="lazy"
                                    src={font?.imageOn}
                                    alt={font.name}
                                    style={{
                                      borderRadius: "16px",
                                      width: "100%",
                                      height: "100%",
                                      fontFamily: font.name,
                                    }}
                                  />
                                )}
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </>
                  ) : (
                    <>
                      <ThreeDots
                        // height="100%"
                        width="100%"
                        radius="9"
                        color="#36CDB4"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </>
                  )}
                </div>

                <Typography
                  className=" border-2 border-[#AFE9E0] py-4 px-3  cursor-pointer rounded-3xl font-black text-center items-center hover-effect"
                  style={{
                    margin: "20px",
                    // marginLeft: "10px",
                    fontSize:
                      window.innerWidth > 1440
                        ? "1.3rem"
                        : window.innerWidth > 1024
                        ? "0.75rem"
                        : window.innerWidth > 992
                        ? "0.6rem"
                        : "2rem",
                    fontFamily: "Beatrice-Display",
                    fontStyle: "italic",
                  }}
                  onClick={() => {
                    setShowOther(true);
                  }}
                >
                  Other Fonts
                </Typography>
              </Col>
              <Col md={12} lg={10} xs={12} sm={12}>
                <div
                  className="border-2 border-[#AFE9E0] rounded-3xl p-4"
                  style={{
                    backgroundColor: `${theme}`,
                    boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.20)",
                  }}
                >
                  <div className="price-container">
                    <div
                      style={{
                        display: "flex",
                        // alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Beatrice-Display",
                            color: "#25CCB0",
                            fontSize: window.innerWidth > 768 ? "40px" : "2rem",
                            fontWeight: "700",
                            fontStyle: "italic",
                            letterSpacing: "0.05em",
                          }}
                        >
                          Pick a Font
                        </Typography>
                        <CustomWidthTooltip
                          open={openHeadingInfo}
                          onClose={() => handleTooltipClose(setOpenHeadingInfo)}
                          title={
                            <p>
                              You can choose up to 3 fonts. <br /> Note: Fonts
                              are priced differently depending on materials
                              used. [If using multiple fonts, click the lock
                              button and click on your next font]
                            </p>}
                        >
                          <MyComponent
                            onMouseEnter={() =>
                              handleMouseEnter(setOpenHeadingInfo)
                            }
                            onMouseLeave={() =>
                              handleMouseLeave(setOpenHeadingInfo)
                            }
                            onClick={() =>
                              handleClick(setOpenHeadingInfo, openHeadingInfo)
                            }
                            style={{
                              marginLeft: "10px",
                              display: "inline-block",
                            }}
                          />
                        </CustomWidthTooltip>
                      </div>
                      <div
                        style={{
                          width: "40%",
                          height: "10vh",
                          display: warningOne?.warningStatus ? "" : "none",
                          zIndex: 9,
                        }}
                        className="warning"
                      >
                        <Typography className="warning-title">
                          {warningOne?.title}
                        </Typography>
                        <div
                          className="enable-listing-style warning-desc"
                          dangerouslySetInnerHTML={{
                            __html: warningOne?.warningContent,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: warningTwo?.warningStatus ? "none" : "flex",
                        flexDirection: "column",
                        alignItems: "flex-start", // Align items to the right for both mobile and desktop
                        justifyContent: "flex-start", // Align content to the bottom of the container
                        marginTop: "10px", // Add space between text and price
                      }}
                      className="text-end"
                    >
                      <PriceEstimate />
                    </div>
                    <div
                      style={{
                        width: "30%",
                        height: "100px",
                        display: warningTwo?.warningStatus ? "" : "none",
                        zIndex: 9,
                      }}
                      className="warning"
                    >
                      <Typography className="warning-title">
                        {warningTwo?.title}
                      </Typography>
                      <div
                        className="enable-listing-style warning-desc"
                        dangerouslySetInnerHTML={{
                          __html: warningTwo?.warningContent,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "80px",
                      display: warningThree?.warningStatus ? "" : "none",
                      zIndex: 9,
                      position: "relative",
                    }}
                    className="warning"
                  >
                    <Typography className="warning-title">
                      {warningThree?.title}
                    </Typography>
                    <div
                      className="enable-listing-style warning-desc"
                      dangerouslySetInnerHTML={{
                        __html: warningThree?.warningContent,
                      }}
                    ></div>
                  </div>

                  <div>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          sm={12}
                          lg={11}
                          md={12}
                          style={{
                            backgroundColor: `${theme}`,
                            color: !theme,
                          }}
                        >
                          <TextBoxes
                            setTextAreas={setTextAreas}
                            handleDrop={handleDrop}
                            handleTextAreaChange={handleTextAreaChange}
                            handleTextAreaDragStart={handleTextAreaDragStart}
                            selectedTextAreaId={selectedTextAreaId}
                            setSelectedTextAreaId={setSelectedTextAreaId}
                            textAreas={textAreas}
                            textareaRefs={textareaRefs}
                            updateTextareaHeight={updateTextareaHeight}
                          />
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                          }}
                          lg={1}
                          xs={0}
                          sm={0}
                          className="desktop-div"
                        >
                          <span className="mobile-display">
                            <button
                              style={{
                                color: "#25CCB0",
                                border: "1px solid #25CCB0",
                                borderRadius: "16px",
                                padding: "10px",
                                margin: "5px",
                              }}
                              className={
                                selectedTextAreaId === null ? "selected" : ""
                              }
                              onClick={() => handleUnselect()}
                            >
                              {selectedTextAreaId === null ? (
                                <Lock width={20} height={20} />
                              ) : (
                                <Unlock width={20} height={20} />
                              )}
                            </button>
                            <button
                              style={{
                                margin: "5px",
                              }}
                              onClick={() =>
                                handleHorizontalAlignmentChange("left")
                              }
                              className="LeftAlignIcon"
                            >
                              <LeftAlignIcon />
                            </button>
                            <button
                              style={{
                                margin: "5px",
                              }}
                              onClick={() =>
                                handleHorizontalAlignmentChange("center")
                              }
                              className="Center"
                            >
                              <Center />
                            </button>
                            <button
                              style={{
                                margin: "5px",
                              }}
                              onClick={() =>
                                handleHorizontalAlignmentChange("right")
                              }
                              className="RightAlignIcon"
                            >
                              <RightAlignIcon />
                            </button>
                            <button
                              className="deleteText"
                              style={{
                                margin: "5px",
                              }}
                              onClick={handleDelete}
                            >
                              <DeleteIcon />
                            </button>

                            <DescriptionButton
                              value={desc}
                              setValue={setProductFontDescription}
                            />
                            <ClearButton />
                          </span>
                        </Col>

                        <div className="mobile-tablet-div">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              style={{
                                color: "#25CCB0",
                                border: "1px solid #25CCB0",
                                borderRadius: "16px",
                                padding: "11px",
                                margin: "5px",
                              }}
                              className={
                                selectedTextAreaId === null ? "selected" : ""
                              }
                              onClick={() => handleUnselect()}
                            >
                              {selectedTextAreaId === null ? (
                                <Lock width={20} height={20} />
                              ) : (
                                <Unlock width={20} height={20} />
                              )}
                            </button>
                            <button
                              style={{
                                margin: "5px",
                              }}
                              onClick={() =>
                                handleHorizontalAlignmentChange("left")
                              }
                              className="LeftAlignIcon"
                            >
                              <LeftAlignIcon />
                            </button>
                            <button
                              style={{
                                margin: "5px",
                              }}
                              onClick={() =>
                                handleHorizontalAlignmentChange("center")
                              }
                              className="Center"
                            >
                              <Center />
                            </button>
                            <button
                              style={{
                                margin: "5px",
                              }}
                              onClick={() =>
                                handleHorizontalAlignmentChange("right")
                              }
                              className="RightAlignIcon"
                            >
                              <RightAlignIcon />
                            </button>
                            <button
                              className="deleteText"
                              style={{
                                margin: "5px",
                              }}
                              onClick={handleDelete}
                            >
                              <DeleteIcon />
                            </button>
                          </div>

                          <DescriptionButton
                            value={desc}
                            setValue={setProductFontDescription}
                          />
                          <ClearButton />
                        </div>
                      </Row>
                      <Row className="nightmood">
                        <BottomControls />
                      </Row>
                    </Container>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
        <OtherFonts
          textAreas={textAreas}
          setTextAreas={setTextAreas}
          show={showOther}
          close={() => setShowOther(false)}
        />
        {showExamples && <ExampleModal />}
      </div>
    </>
  );
};

export default Content;
