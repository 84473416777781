import {
  Box,
  Button,
  Modal,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Close from "../../../Assets/Close";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Info from "../../../Assets/SuperCustom/Info";

import {
  addOtherColors,
  setProductFont,
} from "../../../Redux/Slice";
import FontUpload from "../../Content/FontUpload";

import {
  debounce,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  handleTooltipClose,
} from "../../../utils/client/helpers";
import { getOSInfo } from "../../../utils/client/calculations";

const SplitColors = ({
  textAreas,
  setTextAreas,
  selectedTextArea,
  show,
  close,
  handleMergeButtonClick,
  setSelectedLetter,
  selectedLetter,
}) => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const colors = useSelector((state) => state.activeStep.colors);
  // const [fontFile, setFontFile] = useState("");
  const [openHeadingInfo, setOpenHeadingInfo] = useState(false);

  const handleLetterColorSelection = (color) => {
    if (selectedLetter !== null) {
      setTextAreas((prevState) => {
        const updatedTextAreas = prevState.map((textArea) => {
          if (textArea.id === selectedTextArea) {
            // console.log("TextArea Matched");
            const updatedLetters = textArea.letters.map((letter) => {
              if (letter.id === selectedLetter) {
                // console.log("Added Color to letter");
                return { ...letter, color: color };
              }
              return letter;
            });
            const updatedTextArea = { ...textArea, letters: updatedLetters };
            return updatedTextArea;
          }
          return textArea;
        });
        dispatch(setProductFont(updatedTextAreas));
        return updatedTextAreas;
      });
    }
  };

  const [hoveredStates, setHoveredStates] = useState([]); // Initialize an array of hover states

  const handleHoverChange = (index, isHovered) => {
    const newHoveredStates = Array(hoveredStates.length).fill(false);

    // Set the specified index to the provided isHovered value
    newHoveredStates[index] = isHovered;

    // Update the state
    setHoveredStates(newHoveredStates);
  };
  const handleSubmit = () => {
    close();
  };

  // Preload images
  const preloadImages = () => {
    colors.forEach((color) => {
      const imgOn = new Image();
      const imgOff = new Image();
      imgOn.src = color.imageOn;
      imgOff.src = color.imageOff;
    });
  };

  // Call preloadImages function when your component mounts
  useEffect(() => {
    preloadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colors]);

  const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref}>
        <Info />
      </div>
    );
  });

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      arrow
      placement="bottom-start"
      {...props}
      style={{
        backgroundColor: "",
      }}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "14px",
      maxWidth: 200,
      backgroundColor: "#D9FF9E",
      color: "#03705E",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#D9FF9E",
    },
  });

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "100vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
  };
  const style1 = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    // height: "100vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    // maxHeight: "700px",
  };

  const debouncedHandleHoverChange = debounce(handleHoverChange, 300);

  useEffect(() => {
    // Attach debounced function to scroll event
    window.addEventListener("scroll", debouncedHandleHoverChange);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", debouncedHandleHoverChange);
    };
  }, [debouncedHandleHoverChange]);

  return (
    <>
      <Modal
        open={show}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1} md={style} lg={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: window.innerWidth > 768 ? "40px" : "1.5rem",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Select the letter and choose the color
              <CustomWidthTooltip
                open={openHeadingInfo}
                onClose={() => handleTooltipClose(setOpenHeadingInfo)}
                title={
                  "You can split your words into letters and apply different colors to each letter."
                }
              >
                <MyComponent
                  onMouseEnter={() => handleMouseEnter(setOpenHeadingInfo)}
                  onMouseLeave={() => handleMouseLeave(setOpenHeadingInfo)}
                  onClick={() =>
                    handleClick(setOpenHeadingInfo, openHeadingInfo)
                  }
                  style={{
                    marginLeft: "10px",
                    display: "inline-block",
                  }}
                />
              </CustomWidthTooltip>
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={close}
            >
              <Close />
            </div>
          </div>
          <div
            style={{
              fontFamily: "Beatrice",
              width: "100%",
            }}
          >
            <Typography
              style={{
                color: "#BF6BE3",
                fontSize: window.innerWidth > 768 ? "1rem" : "0.75rem",
              }}
            >
              NOTE: Sometimes this feature can impact the look of your piece in
              the preview window.
            </Typography>
            <Typography
              style={{
                fontSize: window.innerWidth > 768 ? "1rem" : "0.75rem",
              }}
            >
              This typically happens with long or vertically stacked text
              designs. If this happens to you, you can remove the split letter
              colors and tell us the colors you want to use in the notepad.
            </Typography>
          </div>
          <div>
            <div
              style={{
                marginTop: "20px",
                height: window.innerWidth > 768 ? "200px" : "100px",
                overflowY: "hidden",
                overflowX: "auto",
              }}
              className="border-2 border-[#AFE9E0] rounded-3xl"
            >
              {textAreas.map((textArea) => selectedTextArea === textArea.id ? (
                <div key={textArea.id}>
                  <button
                    style={{
                      color: "#4EBFFF",
                      height: "10px",
                      fontSize: window.innerWidth > 768 ? "18px" : "0.5rem",
                      marginLeft: "10px",
                    }}
                    onClick={handleMergeButtonClick}
                  >
                    Cancel Split
                  </button>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      overflow: "auto",
                      maxHeight: window.innerWidth > 768 ? "170px" : "70px",
                      // flexDirection: "row",
                      // justifyContent: "flex-start",
                    }}
                  >
                    {textArea?.letters?.map((letterObj, letterIndex) => (
                      <Card
                        key={letterIndex}
                        style={{
                          background: "none",
                          fontSize: `${textArea.fontSize}px`,
                          // padding: "5px",
                          margin: "2px",
                          // width: "40px",
                          fontFamily: textArea.fontFamily,
                          color: letterObj.color,
                          border: `1px solid ${selectedLetter === letterIndex
                            ? "#4EBFFF"
                            : "#AFE9E0"
                            }`,
                          borderRadius: "0px",
                          // border: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedLetter(letterIndex);
                          // console.log(letterIndex);
                        }}
                      >
                        {letterObj.letter}
                      </Card>
                    ))}
                  </div>
                </div>
              ) : (
                <div key={textArea.id} />
              ))}
            </div>
            <div
              style={{
                marginTop: "20px",
                height: window.innerWidth > 768 ? "350px" : "100px",
                overflowY: "scroll",
                overflowX: "hidden",
                paddingRight: "30px",
              }}
              className="selected rounded-3xl"
            >
              <Row>
                {colors.map((color, index) => (
                  <Col key={color.name} xs={6} sm={4} md={3}>
                    <img
                      className={`border-2 border-[#AFE9E0] m-3 font-black text-center items-center text-3xl cursor-pointer rounded-3xl ${textAreas?.some((textArea) =>
                        textArea?.letters?.some(
                          (letter) => letter.color === color.code
                        )
                      )
                        ? "selected"
                        : "hover-effect"
                        }`}
                      style={{
                        fontFamily: color.name,
                        // height: "120px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      src={
                        hoveredStates[index]
                          ? color?.imageOff
                          : color?.imageOn
                      }
                      onClick={() => {
                        handleLetterColorSelection(color.code);
                        dispatch(addOtherColors(color));
                      }}
                      onTouchStart={() => {
                        handleLetterColorSelection(color.code);
                        dispatch(addOtherColors(color));
                        handleHoverChange(index, true);
                      }}
                      onTouchEnd={() => {
                        handleHoverChange(index, false);
                      }}
                      onMouseEnter={() => {
                        let userPlatform = getOSInfo();
                        if (
                          !(
                            userPlatform === "iPhone" ||
                            userPlatform === "Android"
                          )
                        ) {
                          handleHoverChange(index, true);
                        }
                      }}
                      onMouseLeave={() => {
                        let userPlatform = getOSInfo();
                        if (
                          !(
                            userPlatform === "iPhone" ||
                            userPlatform === "Android"
                          )
                        ) {
                          handleHoverChange(index, false);
                        }
                      }}
                      alt=""
                    />
                  </Col>
                ))}
              </Row>
            </div>
            <div
              className="mobile-flow-switch"
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{
                  width: window.innerWidth > 768 ? "30%" : "100%",
                  color: "#ffffff",
                  fontSize: "16px",
                  padding: "10px",
                  fontWeight: "600",
                  bgcolor: "#36CDB4",
                  borderRadius: "16px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                  ":hover": {
                    color: "#36CDB4",
                    border: "1px solid #36CDB4",
                  },
                }}
                onClick={handleSubmit}
              >
                Continue
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <FontUpload
        show={success}
        close={() => setSuccess(false)}
        //file={fontFile}
        file=""
      />
    </>
  );
};

export default SplitColors;
