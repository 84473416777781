import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import EditModal from "./EditModal";
import CustomColors from "./../../Assets/CustomColors.png";
import Select, { components } from "react-select";
import { useSelector } from "react-redux";

const ImageCard = ({ heading, selection, fontColors }) => {
  const [edit] = useState("");
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: `Image ${selection[0] + 1}`,
    imageSrc: selection[0],
    value: selection[0],
  });
  const [options, setOptions] = useState([]);

  const colors = useSelector((state) => state.activeStep.colors);

  const savedDrawingData =
    JSON.parse(localStorage.getItem("savedDrawing")) || "";

  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <span className="toggle-icon" />
      </components.DropdownIndicator>
    );
  };
  useEffect(() => {
    let newOptions = selection.map((image, index) => ({
      value: image,
      label: `Image ${index + 1}`,
      imageSrc: image,
    }));

    let uniqueColors = [...new Set(fontColors)];

    if (uniqueColors.length !== selection.length) {
      newOptions.push({
        value: CustomColors,
        label: `Custom Image`,
        imageSrc: CustomColors,
      });
      setSelectedOption({
        label: `Image ${CustomColors + 1}`,
        imageSrc: CustomColors,
        value: CustomColors,
      });
    }

    setOptions((prevOptions) => {
      const filteredOptions = newOptions.filter(
        (newOption) =>
          !prevOptions.some(
            (prevOption) => prevOption.value === newOption.value
          )
      );
      return [...prevOptions, ...filteredOptions];
    });
  }, [fontColors, selection]);

  useEffect(() => {
    if (savedDrawingData) {
      const colorCodes = savedDrawingData.map((item) => item.strokeColor);
      const matchingColors = colors.filter((color) =>
        colorCodes.includes(color.code)
      );

      const colorImages = matchingColors.map((color) => color.imageOn);
      const newOptions = colorImages.map((image, index) => ({
        value: image,
        label: `Image ${index + 1}`,
        imageSrc: image,
      }));

      if (selectedOption.imageSrc === undefined) {
        setSelectedOption({
          label: `Image ${colorImages[0] + 1}`,
          imageSrc: colorImages[0],
          value: colorImages[0],
        });
      }

      setOptions((prevOptions) => {
        const filteredOptions = newOptions.filter(
          (newOption) =>
            !prevOptions.some(
              (prevOption) => prevOption.value === newOption.value
            )
        );
        return [...prevOptions, ...filteredOptions];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colors, fontColors, selection]);

  // useEffect(() => {
  //   // console.log(fontColors);
  //   // console.log(selection);
  //   let uniqueColors = [...new Set(fontColors)];
  //   // console.log(uniqueColors);
  //   if (uniqueColors.length !== selection.length) {
  //     options.push({
  //       value: CustomColors,
  //       label: `Custom Image`,
  //       imageSrc: CustomColors,
  //     });
  //     // console.log("addind custom");
  //   }
  // }, [fontColors, selection]);

  // console.log(options);
  // console.log(selectedOption);

  return (
    <div
      style={{
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Beatrice",
            color: "#000000",
            marginBottom: "5px",
          }}
        >
          {heading}
        </Typography>
        {/* <Typography
          style={{
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Beatrice",
            color: "#03705E",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            setEdit(heading);
            setShow(true);
          }}
        >
          <>Edit</>
        </Typography> */}
      </div>
      <Card
        style={{
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "2px solid #AFE9E0",
          borderRadius: "16px",
          padding: "10px",
        }}
      >
        {selection.length > 0 || options.length > 0 ? (
          <Select
            styles={{
              control: (provided) => ({
                ...provided,
                border: "none",
                boxShadow: "none",
              }),
              indicatorsContainer: (provided) => ({
                ...provided,
                border: "none",
              }),
            }}
            isSearchable={false}
            value={selectedOption}
            options={options}
            onChange={(option) => setSelectedOption(option)}
            getOptionLabel={(option) => (
              <img
                style={{
                  width: "130px",
                  height: "80px",
                }}
                src={option.imageSrc}
                alt={`Img ${option.label}`}
              />
            )}
            getOptionValue={(option) => option.value}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: CustomDropdownIndicator, // CustomDropdownIndicator for the selector icon
            }}
          />
        ) : (
          <>
            {fontColors ? (
              <>
                <img
                  style={{
                    height: "80px",
                  }}
                  src={CustomColors}
                  alt="custom"
                />
              </>
            ) : (
              <>
                <Typography
                  style={{
                    margin: "20px",
                    fontSize: "32px",
                    fontWeight: "400",
                    fontFamily: "Beatrice",
                    color: "#000000",
                  }}
                >
                  N/A
                </Typography>
              </>
            )}
          </>
        )}
      </Card>

      <EditModal edit={edit} show={show} close={() => setShow(false)} />
    </div>
  );
};

export default ImageCard;
