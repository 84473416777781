import React, { useEffect, useState } from "react";
import ShippingInfo from "./Shipping/ShippingInfo";
import Info from "../../Assets/SuperCustom/Info";
import {
  Button,
  Checkbox,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import {
  setShippingAPIRate,
  setShippingCost,
  setShippingTime,
} from "../../Redux/Slice";
import { Col, Row } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import {
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  handleTooltipClose,
} from "../../utils/client/helpers";

const Shipping = () => {
  const cost = useSelector((state) => state.activeStep.product.cost);
  const showConsoles = useSelector((state) => state.activeStep.showConsoles);

  const [openPriceInfo, setOpenPriceInfo] = useState(false);
  const [openShippingPriceInfo, setOpenShippingPriceInfo] = useState(false);

  const shippingCost = useSelector(
    (state) => state.activeStep.product.shippingCost
  );
  const shippingAPIRate = useSelector(
    (state) => state.activeStep.shippingAPIRate
  );
  const shippingTime = useSelector(
    (state) => state.activeStep.product.shippingTime
  );
  const product = useSelector((state) => state.activeStep.product);
  const details = useSelector(
    (state) => state.activeStep.product.delivery.details
  );
  const markUp = useSelector((state) => state.activeStep.markup);
  const discount = useSelector((state) => state.activeStep.discount);

  const [risks, setRisks] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [risksError, setRisksError] = useState(false);
  const [weightPerCubicInch, setWeightPerCubicInch] = useState(0);
  const [flatRate, setFlatRate] = useState(0);
  const [glassShippingMaterial, setGlassShippingMaterial] = useState(0);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [finalTime, setFinalTime] = useState("");

  const validateForm = () => {
    const errors = {};

    if (!details?.address) {
      errors.address = "Address is required";
    }
    if (!details?.city) {
      errors.city = "City is required";
    }
    if (!details?.zip) {
      errors.zip = "Zip Code is required";
    }
    if (!details?.country) {
      errors.country = "Country is required";
    }
    if (!details?.state) {
      errors.state = "State is required";
    }

    return errors;
  };

  const dispatch = useDispatch();

  const handleFEDx = async () => {
    let errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setErrors({});
    let weight =
      weightPerCubicInch *
      (product?.size?.width +
        product?.backing?.width +
        product.backing.lockedWidth +
        3) *
      (product?.size?.height +
        product?.backing?.height +
        product.backing.lockedHeight +
        3) *
      (product?.size?.depth + 3);
    weight = Math.ceil(weight);
    if (showConsoles) {
      console.log("Weight Per Cubic:", weightPerCubicInch);
      console.log(
        "W*:",
        product.size.width +
          product?.backing?.width +
          product.backing.lockedWidth +
          3
      );
      console.log(
        "H*:",
        product.size.height +
          product?.backing?.height +
          product.backing.lockedHeight +
          3
      );
      console.log("D*:", product.size.depth + 3);
      console.log("Weight", Math.ceil(weight));
    }

    const data = {
      shipment: {
        to_address: {
          name: details?.name,
          street1: details?.address,
          city: details?.city,
          state: details?.state,
          zip: details?.zip,
          country: details?.country,
        },
        from_address: {
          name: "GLO Studio",
          street1: "133 Norfolk Street, Storefront",
          city: "New York",
          state: "NY",
          zip: "10002",
          country: "US",
          phone: "9173972271",
        },
        parcel: {
          length: product.size.depth + 3,
          width: Number(
            product.size.width +
              product.backing.width +
              product.backing.lockedWidth +
              3
          ),
          height: Number(
            product.size.height +
              product.backing.height +
              product.backing.lockedHeight +
              3
          ),
          weight: weight * 16,
        },
        options: {
          delivery_confirmation: "INDIRECT_SIGNATURE",
        },
      },
    };


    try {
      setLoading(true);
      const response = await axios.post(
        // `${process.env.REACT_APP_BACKEND_URL}shippings/fedEx/getRates`,
        `${process.env.REACT_APP_BACKEND_URL}shippings/ups/getRateFED`,
        data
      );
      // console.log(response.data);
      // console.log(typeof response.data);
      if (response.data) {
        setLoading(false);
        // console.log(response.data);
        // setShowAPIResponse(true);
        let shippingRate = Number(response.data.rate);
        let shippingCost =
          (product.size.width +
            product.backing.width +
            product.backing.lockedWidth +
            3) *
            (product.size.height +
              product.backing.height +
              product.backing.lockedHeight +
              3) *
            glassShippingMaterial +
          flatRate;

        let insuranceRate = cost * 0.005;

        dispatch(setShippingAPIRate(Math.ceil(shippingRate + insuranceRate)));

        if (showConsoles) {
          console.log("Shipping API Rate:", shippingRate);
          console.log("Shipping Insurance Rate:", insuranceRate);
          console.log("Total Shipping Rate:", shippingRate + insuranceRate);
          console.log("Shipping Flat Rate:", flatRate);
          console.log("Shipping Cost:", shippingCost);
        }

        let decimalMarkUp = markUp / 100;
        let decimalDiscount = discount / 100;

        let markupAmount = decimalMarkUp * shippingCost;

        let markUpPrice = shippingCost + markupAmount;
        let discountFactor = Number(1 - decimalDiscount);

        let shippingPrice = Number(markUpPrice * discountFactor);
        if (showConsoles) {
          console.log("Shipping Price:", shippingPrice);
        }
        dispatch(setShippingCost(Math.ceil(shippingPrice)));


        const transitDays = response.data.time; // Number of transit days

        // console.log(transitDays);
        // Parse the original final time range
        let [startRange, endRange] = finalTime.split(" - ");
        let [startMonth, startDate] = startRange.split(" ");
        let [, endDay, endYear] = endRange.split(" "); // We don't need endMonth here
        // console.log(
        //   startRange,
        //   endRange,
        //   startMonth,
        //   startDate,
        //   endDay,
        //   endYear
        // );

        // Calculate the end date of the original range

        // console.log(endDay, startMonth);
        // console.log(!isValidDate(endDay, startMonth, endYear));
        let startMonthIndex = monthNames.indexOf(startMonth);
        // console.log(startMonthIndex);

        if (!isValidDate(endDay, startMonthIndex, endYear)) {
          // If the date is not valid, add the remaining days to the current month
          const lastDayOfMonth = getLastDayOfMonth(endYear, startMonthIndex);
          // console.log(parseInt(endDay));
          // console.log(parseInt(lastDayOfMonth));
          endDay = parseInt(endDay) - lastDayOfMonth;

          // Move to the next month
          let nextMonthIndex = startMonthIndex + 1;
          let nextYear = endYear;
          // console.log(nextMonthIndex);

          if (nextMonthIndex >= 12) {
            nextMonthIndex = 0; // Wrap around to January if necessary
            nextYear++; // Increment the year if we move to the next year
          }

          startMonthIndex = nextMonthIndex;
          endYear = nextYear;
        }

        const endDateObj = new Date(endYear, startMonthIndex, endDay);
        const startDateObj = new Date(endYear, startMonthIndex, startDate);
        // console.log(startDateObj.getTime());

        // Calculate the new end date by adding transit days and additional days
        const newEndDateObj = new Date(
          endDateObj.getTime() + transitDays * 24 * 60 * 60 * 1000
        );
        const newStartDateObj = new Date(
          startDateObj.getTime() + transitDays * 24 * 60 * 60 * 1000
        );
        // console.log(newStartDateObj);

        // Format the dates for display
        const newEndMonth = newEndDateObj.toLocaleString("default", {
          month: "short",
        });
        const newStartDateFormatted = `${startMonth} ${newStartDateObj.getDate()}`;
        const newEndDateFormatted = `${newEndMonth} ${newEndDateObj.getDate()}, ${newEndDateObj.getFullYear()}`;
        // console.log(
        //   newStartDateFormatted,
        //   newStartDateFormatted,
        //   "response.data.rate,",
        //   response.data.rate
        // );
        // Construct the new final time string
        const newFinalTime = `${newStartDateFormatted} - ${newEndDateFormatted}`;

        setFinalTime(newFinalTime);
        dispatch(setShippingTime(newFinalTime));
      } else {
        swal({
          title: "Note",
          text: `${response.data}`,
          icon: "info",
        });
      }
    } catch (err) {
      setLoading();
      toast.error(err.response.data.message);
      console.log(err.response.data.message);
    }
  };
  const handleUPS = async () => {
    let errors = validateForm();
    if (Object.keys(errors).length > 0) {
      // console.log("validating");
      setErrors(errors);
      return;
    }
    setErrors({});

    // dispatch(setProductDeliveryDetails(details));
    let weight =
      weightPerCubicInch *
      (product.size.width +
        product?.backing?.width +
        product.backing.lockedWidth +
        5) *
      (product.size.height +
        product?.backing?.height +
        product.backing.lockedHeight +
        5) *
      (product.size.depth + 5);
    weight = Math.ceil(weight);
    if (showConsoles) {
      console.log("Weight Per Cubic:", weightPerCubicInch);
      console.log(
        "W*:",
        product.size.width +
          product?.backing?.width +
          product.backing.lockedWidth +
          5
      );
      console.log(
        "H*:",
        product.size.height +
          product?.backing?.height +
          product.backing.lockedHeight +
          5
      );
      console.log("D*:", product.size.depth + 5);
      console.log("Weight", weight);
    }
    // console.log("product.size", product.size);

    const data = {
      shipment: {
        to_address: {
          name: details?.name,
          street1: details?.address,
          city: details?.city,
          state: details?.state,
          zip: details?.zip,
          country: details?.country,
        },
        from_address: {
          name: "GLO Studio",
          street1: "133 Norfolk Street, Storefront",
          city: "New York",
          state: "NY",
          zip: "10002",
          country: "US",
          phone: "9173972271",
        },
        parcel: {
          length: product.size.depth + 5,
          width: Number(
            product.size.width +
              product.backing.width +
              product.backing.lockedWidth +
              5
          ),
          height: Number(
            product.size.height +
              product.backing.height +
              product.backing.lockedHeight +
              5
          ),
          weight: weight * 16,
        },
        options: {
          delivery_confirmation: "INDIRECT_SIGNATURE",
        },
      },
    };

    if (!risks) {
      setRisksError(true);
      return;
    } else {
      setRisksError(false);

      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}shippings/ups/getRateUPS`,
          data
        );
        // console.log(response.data);
        // console.log(typeof response.data);
        if (response.data) {
          setLoading(false);
          // setShowAPIResponse(true);
          // console.log("Shipping Information :", response.data);
          let shippingHandlingRate = Number(Math.ceil(response.data.rate));

          let insuranceRate = cost * 0.005;

          dispatch(
            setShippingAPIRate(Math.ceil(shippingHandlingRate + insuranceRate))
          );
          let shippingCost =
            (product.size.width +
              product.backing.width +
              product.backing.lockedWidth +
              5) *
              (product.size.height +
                product.backing.height +
                product.backing.lockedHeight +
                5) *
              glassShippingMaterial +
            flatRate;
          if (showConsoles) {
            console.log("Shipping API Rate:", shippingHandlingRate);
            console.log("Shipping Insurance Rate:", insuranceRate);
            console.log(
              "Total Shipping Rate:",
              shippingHandlingRate + insuranceRate
            );
            console.log("Shipping Flat Rate:", flatRate);
            console.log("Shipping Cost:", shippingCost);
          }
          let decimalMarkUp = markUp / 100;
          let decimalDiscount = discount / 100;

          let markupAmount = decimalMarkUp * shippingCost;

          let markUpPrice = shippingCost + markupAmount;
          let discountFactor = Number(1 - decimalDiscount);

          let shippingPrice = Number(markUpPrice * discountFactor);
          if (showConsoles) {
            console.log("Shipping Price:", shippingPrice);
          }
          dispatch(setShippingCost(Math.ceil(shippingPrice)));
          // const currentDate = new Date();
          // const futureDate = new Date(
          //   currentDate.getTime() + response.data.time * 24 * 60 * 60 * 1000
          // );

          // // Format the future date to the desired format: "yyyy-MM-ddTHH:mm:ss"
          // const formattedFutureDate = futureDate
          //   .toISOString()
          //   .slice(0, 19)
          //   .split("T")[0];

          // const formattedDate = format(
          //   new Date(formattedFutureDate),
          //   "MMM dd, yyyy"
          // );
          // console.log(formattedDate);
          // Parse transit date string into a Date object
          const transitDays = response.data.time; // Number of transit days

          // console.log(transitDays);
          // Parse the original final time range
          let [startRange, endRange] = finalTime.split(" - ");
          let [startMonth, startDate] = startRange.split(" ");
          let [, endDay, endYear] = endRange.split(" "); // We don't need endMonth here
          // console.log(
          //   startRange,
          //   endRange,
          //   startMonth,
          //   startDate,
          //   endDay,
          //   endYear
          // );

          // Calculate the end date of the original range

          // console.log(endDay, startMonth);
          // console.log(!isValidDate(endDay, startMonth, endYear));
          let startMonthIndex = monthNames.indexOf(startMonth) + 1;
          // console.log(startMonthIndex);

          if (!isValidDate(endDay, startMonthIndex, endYear)) {
            // If the date is not valid, add the remaining days to the current month
            const lastDayOfMonth = getLastDayOfMonth(endYear, startMonthIndex);
            // console.log(parseInt(endDay));
            // console.log(parseInt(lastDayOfMonth));
            endDay = parseInt(endDay) - lastDayOfMonth;

            // Move to the next month
            let nextMonthIndex = startMonthIndex + 1;
            let nextYear = endYear;
            // console.log(nextMonthIndex);

            if (nextMonthIndex >= 12) {
              nextMonthIndex = 0; // Wrap around to January if necessary
              nextYear++; // Increment the year if we move to the next year
            }

            startMonthIndex = nextMonthIndex;
            endYear = nextYear;
          }

          const endDateObj = new Date(endYear, startMonthIndex, endDay);
          const startDateObj = new Date(endYear, startMonthIndex, startDate);
          // console.log(startDateObj.getTime());

          // Calculate the new end date by adding transit days and additional days
          const newEndDateObj = new Date(
            endDateObj.getTime() + transitDays * 24 * 60 * 60 * 1000
          );
          const newStartDateObj = new Date(
            startDateObj.getTime() + transitDays * 24 * 60 * 60 * 1000
          );
          // console.log(newStartDateObj);

          // Format the dates for display
          const newEndMonth = newEndDateObj.toLocaleString("default", {
            month: "short",
          });
          const newStartDateFormatted = `${startMonth} ${newStartDateObj.getDate()}`;
          const newEndDateFormatted = `${newEndMonth} ${newEndDateObj.getDate()}, ${newEndDateObj.getFullYear()}`;
          // console.log(
          //   newStartDateFormatted,
          //   newStartDateFormatted,
          //   "response.data.rate,",
          //   response.data.rate
          // );
          // Construct the new final time string
          const newFinalTime = `${newStartDateFormatted} - ${newEndDateFormatted}`;

          setFinalTime(newFinalTime);
          dispatch(setShippingTime(newFinalTime));
        } else {
          swal({
            title: "Note",
            text: `${response.data}`,
            icon: "info",
          });
        }
      } catch (err) {
        setLoading();
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      }
    }
  };

  function getLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
  }

  // Check if the day is valid for the given month and year
  function isValidDate(day, month, year) {
    const lastDayOfMonth = getLastDayOfMonth(year, month);
    return day >= 1 && day <= lastDayOfMonth;
  }

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      arrow
      placement="bottom-end"
      {...props}
      style={{
        backgroundColor: "",
      }}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "14px",
      maxWidth: 300,
      backgroundColor: "#D9FF9E",
      color: "#03705E",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#D9FF9E",
    },
  });

  const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref}>
        <Info />
      </div>
    );
  });

  const currentDate = new Date();
  const getProductionDays = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}delivery/shippings/${product.source}/${product.backing.name}`
      );
      if (response?.data[0]) {
        // console.log(response?.data[0]);
        const maximumProductionDays = response?.data[0]?.maximumProductionDays;
        const minimumProductionDays = response?.data[0]?.minimumProductionDays;
        setWeightPerCubicInch(response?.data[0]?.signWeightPerSqinch);
        setGlassShippingMaterial(
          response?.data[0]?.glassNeonShippingMaterialPerSquareInch
        );
        setFlatRate(response?.data[0]?.glassShippingHandlingFlatRate);
        const maxDate = new Date(currentDate);
        const minDate = new Date(currentDate);
        maxDate.setDate(maxDate.getDate() + maximumProductionDays);
        minDate.setDate(minDate.getDate() + minimumProductionDays);

        const newYear = maxDate.getFullYear();
        const maxMonth = monthNames[maxDate.getMonth()];
        const minMonth = monthNames[minDate.getMonth()];
        const maxDay = maxDate.getDate();
        const minDay = minDate.getDate();
        setFinalTime(
          `${minMonth} ${minDay} - ${maxMonth} ${maxDay}, ${newYear}`
        );

        dispatch(setShippingCost(null));
        dispatch(setShippingAPIRate(null));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProductionDays();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(productionDays);

  return (
    <>
      <div
        className="shipping-bg"
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          color: "#07AB90",
        }}
      >
        <Row>
          <Col xs={12} md={12} xl={6}>
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: window.innerWidth > 768 ? "40px" : "2rem",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
                textAlign: "left",
              }}
            >
              Delivery Options
            </Typography>
          </Col>
          <Col xs={12} md={12} xl={6}>
            <div
              style={{
                display: product.backing.name === "custom" ? "none" : "",
              }}
              className=" justify-end"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  style={{ fontFamily: "Beatrice", paddingTop: "5px" }}
                  className="text-[#BF6BE3]"
                >
                  Price Estimate
                </Typography>
                <CustomWidthTooltip
                  open={openPriceInfo}
                  onClose={() => handleTooltipClose(setOpenPriceInfo)}
                  title={
                    "Have questions about pricing or just need a human touch? Go through the process and submit an inquiry at the end. Someone from our team will be in touch!"
                  }
                >
                  <MyComponent
                    onMouseEnter={() => handleMouseEnter(setOpenPriceInfo)}
                    onMouseLeave={() => handleMouseLeave(setOpenPriceInfo)}
                    onClick={() => handleClick(setOpenPriceInfo, openPriceInfo)}
                    style={{
                      marginLeft: "10px",
                      display: "inline-block",
                    }}
                  />
                </CustomWidthTooltip>
              </div>
              <Typography
                style={{
                  fontFamily: "Beatrice",
                  fontSize: window.innerWidth > 768 ? "1.875rem" : "1rem",
                }}
                className=" text-[#07AB90] text-right"
              >
                {shippingCost ? (
                  <>
                    ${Number(cost + shippingCost + shippingAPIRate)}
                    <CustomWidthTooltip
                      open={openShippingPriceInfo}
                      onClose={() =>
                        handleTooltipClose(setOpenShippingPriceInfo)
                      }
                      title={`Neon : $${
                        cost + shippingCost
                      } + \n Shipping : $${shippingAPIRate}`}
                    >
                      <MyComponent
                        onMouseEnter={() =>
                          handleMouseEnter(setOpenShippingPriceInfo)
                        }
                        onMouseLeave={() =>
                          handleMouseLeave(setOpenShippingPriceInfo)
                        }
                        onClick={() =>
                          handleClick(
                            setOpenShippingPriceInfo,
                            openShippingPriceInfo
                          )
                        }
                        style={{
                          marginLeft: "10px",
                          display: "inline-block",
                        }}
                      />
                    </CustomWidthTooltip>
                  </>
                ) : (
                  <>${cost} + Shipping price</>
                )}
              </Typography>
            </div>
          </Col>
        </Row>
        <div>
          {product.backing.name === "custom" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: window.innerWidth > 768 ? "50px" : "20px",
                marginBottom: window.innerWidth > 768 ? "50px" : "20px",
              }}
            >
              <Typography
                style={{
                  fontSize: window.innerWidth > 768 ? "21px" : "1rem",
                  fontFamily: "Beatrice",
                  width: window.innerWidth > 768 ? "57%" : "100%",
                }}
              >
                We will be able to give you an estimated time frame after we
                reach out with an updated quote.
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Typography
                style={{
                  fontSize: window.innerWidth > 768 ? "21px" : "1rem",
                  fontFamily: "Beatrice",
                  width: window.innerWidth > 768 ? "58%" : "100%",
                }}
              >
                Order today with the{" "}
                <Typography
                  component="span"
                  style={{
                    display: "inline-block",
                    fontSize: window.innerWidth > 768 ? "21px" : "1rem",
                    fontFamily: "Beatrice",
                    color: "#BF6BE3",
                  }}
                >
                  “Shipping”
                </Typography>
                method, and your estimated delivery window will be:
              </Typography>
              <Typography
                style={{
                  fontSize: window.innerWidth > 768 ? "48px" : "1.5rem",
                  fontFamily: "Beatrice",
                }}
              >
                {shippingTime ? (
                  <>{finalTime}</>
                ) : (
                  <>{finalTime} + Transit time</>
                )}
              </Typography>
            </div>
          )}
        </div>

        {/* <div
          style={{
            marginTop: "50px",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Button
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "uppercase",
              letterSpacing: "2px",
              borderRadius: "16px",
              color: "#25CCB0",
              bgcolor: "#D8F4F1",
              paddingRight: "20px",
              paddingLeft: "20px",
              fontFamily: "Beatrice",
              margin: "20px",
              marginLeft: "0px",
            }}
            onClick={() => {
              setShow(true);
            }}
          >
            EXAMPLES
          </Button>
          <Button
            sx={{
              color: "#03705E",
              fontSize: "20px",
              fontWeight: "600",
              bgcolor: "#D8F4F1",
              borderRadius: "24px",
              paddingRight: "30px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
              paddingLeft: "30px",
              margin: "20px",
              // marginBottom: "20px",
              fontFamily: "Beatrice",
              textTransform: "none",
            }}
            onClick={handleShipping}
          >
            Select Shipping options
          </Button>
          {product?.source === "Glass" && (
            <>
              <Button
                sx={{
                  color: "#03705E",
                  fontSize: "20px",
                  fontWeight: "600",
                  bgcolor: "#D8F4F1",
                  borderRadius: "24px",
                  paddingRight: "30px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                  paddingLeft: "30px",
                  margin: "20px",
                  // marginBottom: "20px",
                  fontFamily: "Beatrice",
                  textTransform: "none",
                }}
                onClick={handleFEDx}
              >
                Calculate FEDx
              </Button>
            </>
          )}
          {product?.source === "LED" && (
            <>
              <Button
                sx={{
                  color: "#03705E",
                  fontSize: "20px",
                  fontWeight: "600",
                  bgcolor: "#D8F4F1",
                  borderRadius: "24px",
                  paddingRight: "30px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                  paddingLeft: "30px",
                  margin: "20px",
                  // marginBottom: "20px",
                  fontFamily: "Beatrice",
                  textTransform: "none",
                }}
                // onClick={handleFEDx}
              >
                Calculate UPS
              </Button>
            </>
          )}
        </div> */}
      </div>
      {/* <BillingInfo formik={formik} /> */}

      <ShippingInfo errors={errors} />

      {/* <ShippingOptions
        showOptions={showOptions}
        closeOptions={() => setShowOptions(false)}
      /> */}
      {/* {product.source === "Glass" ? (
        <></>
      ) : (
        <>
          <Row>
            <Col>
              <label style={{ marginBottom: "5px", color: "#8F8F8F" }}>
                Address Type
              </label>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "0px",
                  }}
                >
                  <Checkbox
                    sx={{
                      color: "#AFE9E0",
                      "&.Mui-checked": {
                        color: "#9EA0DC",
                      },
                    }}
                    checked={details.addressType === "Business" ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(
                          setProductDeliveryDetails({
                            ...details,
                            addressType: "Business",
                          })
                        );
                      } else {
                        dispatch(
                          setProductDeliveryDetails({
                            ...details,
                            addressType: null,
                          })
                        );
                      }
                    }}
                  />
                  <Typography
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontFamily: "Beatrice",
                      fontWeight: "400",
                    }}
                  >
                    Business
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "0px",
                  }}
                >
                  <Checkbox
                    sx={{
                      color: "#AFE9E0",
                      "&.Mui-checked": {
                        color: "#9EA0DC",
                      },
                    }}
                    checked={
                      details.addressType === "Residential" ? true : false
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(
                          setProductDeliveryDetails({
                            ...details,
                            addressType: "Residential",
                          })
                        );
                      } else {
                        dispatch(
                          setProductDeliveryDetails({
                            ...details,
                            addressType: null,
                          })
                        );
                      }
                    }}
                  />
                  <Typography
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontFamily: "Beatrice",
                      fontWeight: "400",
                    }}
                  >
                    Residential
                  </Typography>
                </div>
              </div>
              {errors.addressType && (
                <div
                  style={{
                    // display: "block",
                    color: "red",
                    // margin: "5px",
                  }}
                >
                  Please check this box to proceed
                </div>
              )}
            </Col>
          </Row>
        </>
      )} */}
      {product.source === "Glass" ? (
        <div
          style={{
            padding: window.innerWidth > 768 ? "20px" : "10px",
            margin: window.innerWidth > 768 ? "40px" : "20px",
            // padding: "20px",
            // margin: "40px",
          }}
          className="warning"
        >
          <Typography
            style={{
              fontSize: window.innerWidth > 768 ? "1.25rem" : "1rem",
              // fontSize: "24px",
              fontWeight: "600",
              fontFamily: "Beatrice",
            }}
          >
            Here&apos;s how we ship our neons!
          </Typography>
          <Typography
            className="text-[#BF6BE3]"
            style={{
              // height: "80px",
              fontSize: window.innerWidth > 768 ? "1rem" : "0.75rem",
              // fontSize: "16px",
              fontWeight: "400",
              fontFamily: "Beatrice",
            }}
          >
            We use UPS air and insure all of our shipments. We also package
            every piece for optimal safety for the neon. If a piece is too large
            to put into a regular box safely, we need to build a wood crate for
            it. This is an additional cost so we&apos;ll reach out to you if this is
            the case with your piece!
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0px",
                }}
              >
                <Checkbox
                  sx={{
                    color: "#AFE9E0",
                    "&.Mui-checked": {
                      color: "#9EA0DC",
                    },
                  }}
                  value={risks}
                  onChange={() => {
                    setRisks(!risks);
                  }}
                />
                <Typography
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontFamily: "Beatrice",
                    fontWeight: "400",
                    fontSize: window.innerWidth > 768 ? "16px" : "0.75rem",
                  }}
                >
                  Got it!{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://glo-studio.com/policies/shipping-policy"
                    style={{
                      color: "#BF6BE3",
                      textAlign: "center",
                      fontFamily: "Beatrice",
                      fontWeight: "400",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    learn more
                  </a>
                </Typography>
              </div>
              {risksError && (
                <div
                  style={{
                    // display: "block",
                    color: "red",
                    // margin: "5px",
                  }}
                >
                  Please check this box to proceed
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Row>
        <Col xs={12} md={12} xl={6}>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#000",
              marginTop: "40px",
              fontFamily: "Beatrice",
              marginBottom: "20px",
            }}
          >
            *We ship internationally, please write us at{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:hello@glo-studio.com"
            >
              hello@glo-studio.com
            </a>{" "}
            for delivery.
          </Typography>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          xs={12}
          md={12}
          xl={6}
        >
          {product.backing.name === "custom" ? (
            <></>
          ) : (
            <>
              {product.source === "LED" ? (
                <>
                  {loading || weightPerCubicInch === 0 ? (
                    <ThreeDots
                      // height="100%"
                      width="100%"
                      radius="9"
                      color="#36CDB4"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <Button
                      disabled={loading}
                      style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "22px",
                        cursor: "pointer",
                        backgroundColor: "#36CDB4",
                        textAlign: "center",
                        padding: "10px",
                        paddingRight: "40px",
                        paddingLeft: "40px",
                        paddingTop: "10px",
                        borderRadius: "35px",
                        border: "none",
                      }}
                      onClick={handleFEDx}
                    >
                      Calculate FEDEx
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {loading || weightPerCubicInch === 0 ? (
                    <ThreeDots
                      // height="100%"
                      width="100%"
                      radius="9"
                      color="#36CDB4"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <Button
                      disabled={loading}
                      style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "22px",
                        cursor: "pointer",
                        backgroundColor: "#36CDB4",
                        textAlign: "center",
                        padding: "10px",
                        paddingRight: "40px",
                        paddingLeft: "40px",
                        paddingTop: "10px",
                        borderRadius: "35px",
                        border: "none",
                      }}
                      onClick={handleUPS}
                    >
                      Calculate UPS Air
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Shipping;
