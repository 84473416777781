import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditModal from "./EditModal";
import { useSelector } from "react-redux";
import Heart from "../../Assets/Backings/Heart.png";

const BackingStyleCard = ({ heading }) => {
  const [edit] = useState("");
  const [show, setShow] = useState(false);
  const backing = useSelector((state) => state.activeStep.product.backing);
  const backingStyles = useSelector((state) => state.activeStep.backingStyles);

  let [backingStyleImage, setBackingStyleImage] = useState();

  useEffect(() => {
    if (backingStyles && backing) {
      backingStyles.map((backingStyle) => {
        if (backingStyle.type === backing.name) {
          setBackingStyleImage(backingStyle.image);
        }
        return 0;
      });
    }
  }, [backing, backingStyles]);

  // console.log(backingStyleImage);
  return (
    <div
      style={{
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Beatrice",
            color: "#000000",
            marginBottom: "5px",
          }}
        >
          {heading}
        </Typography>
        {/* <Typography
          style={{
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Beatrice",
            color: "#03705E",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            setEdit(heading);
            setShow(true);
          }}
        >
          {heading === "Format" ||
          heading === "Size" ||
          heading === "Controllers" ||
          heading === "Add-ons" ||
          heading === "Reference Image" ? (
            <></>
          ) : (
            <>Edit</>
          )}
        </Typography> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {backingStyleImage ? (
          <div
            style={{
              border: "2px solid #AFE9E0",
              width: "100%",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "center",
              height: "100px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <img
              src={
                backingStyleImage
                  ? backingStyleImage
                  : backing.name === "custom"
                  ? Heart
                  : ""
              }
              alt="Backing Img"
            />
          </div>
        ) : (
          <div
            style={{
              height: "100px",
              width: "100%",
              border: "2px solid #AFE9E0",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {backing?.name === "custom" ? (
              <>
                <img
                  style={{
                    // height: "100px",
                    width: "100%",
                    // border: "2px solid #AFE9E0",
                    borderRadius: "16px",
                  }}
                  src={backing.image}
                  alt="Backing Img"
                />
                {/* <Typography
                  style={{
                    margin: "20px",
                    fontSize: "32px",
                    fontWeight: "400",
                    fontFamily: "Beatrice",
                    color: "#000000",
                  }}
                >
                  Custom
                </Typography> */}
              </>
            ) : (
              <>
                <Typography
                  style={{
                    margin: "20px",
                    fontSize: "32px",
                    fontWeight: "400",
                    fontFamily: "Beatrice",
                    color: "#000000",
                  }}
                >
                  N/A
                </Typography>
              </>
            )}
          </div>
        )}
      </div>
      <EditModal edit={edit} show={show} close={() => setShow(false)} />
    </div>
  );
};

export default BackingStyleCard;
