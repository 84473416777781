import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Close from "../../Assets/Close";

const InquiryThankYou = ({ open, close }) => {
  const details = useSelector(
    (state) => state.activeStep.product.delivery.details
  );
  const deliveryType = useSelector(
    (state) => state.activeStep.product.delivery.deliveryType
  );

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 768 ? "50%" : "100%",
    // height: "95vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    // p: 4,
    // overflowY: "scroll",
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          // className="ThankYouBG"
          style={{
            backgroundColor: "#36CDB4",
            padding: "20px",
            // borderRadius: "20px 20px 0px 0px",
            // border: "solid 5px #36CDB4",
            // borderColor: "#36CDB4 #36CDB4 #36CDB4 #36CDB4",
            borderRadius: "20px 20px 0px 0px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontFamily: "Beatrice-Display",
              color: "#FFF",
              fontSize: window.innerWidth > 768 ? "3rem" : "1.25rem",
              fontWeight: "700",
              fontStyle: "italic",
              textAlign: "center",
              letterSpacing: "0.05em",
            }}
          >
            Thank You For Your Inquiry!
          </Typography>
          <div
            style={{
              color: "white",
              cursor: "pointer",
              zIndex: 9999,
            }}
            onClick={close}
          >
            <Close color="white" />
          </div>
        </div>
        <div
          style={{
            padding: "30px",
            paddingLeft: "50px",
            paddingRight: "50px",
          }}
        >
          <div>
            <Typography
              style={{
                fontFamily: "Beatrice",
                color: "#03705E",
                fontSize: window.innerWidth > 768 ? "24px" : "1rem",
                fontWeight: "600",
              }}
            >
              Dear{" "}
              <span
                style={{
                  fontFamily: "Beatrice",
                  color: "#BF6BE3",
                  fontSize: window.innerWidth > 768 ? "24px" : "1rem",
                  fontWeight: "600",
                }}
              >
                {details?.name}
              </span>
            </Typography>
          </div>
          <div>
            <Typography
              style={{
                fontFamily: "Beatrice",
                color: "#03705E",
                fontSize: window.innerWidth > 768 ? "14px" : "0.75rem",
                fontWeight: "400",
              }}
            >
              You are well on your way to GLO-ing. We will be in touch shortly
              with an official quote or any follow up questions we may have.
              Can&apos;t wait to make you shine! <br />
              <p
                style={{
                  textAlign: "right",
                  width: "100%",
                }}
              >
                – GLO Studio
              </p>
            </Typography>
          </div>
          <Typography
            style={{
              color: "#03705E",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: window.innerWidth > 768 ? "24px" : "1rem",
              fontWeight: "600",
              fontFamily: "Beatrice",
            }}
          >
            Your inquiry:
          </Typography>
          <div
            style={{
              marginBottom: "10px",
            }}
          >
            {/* <Row>
              <Col lg={4} md={6} sm={5} xs={6}>
                <Typography
                  style={{
                    color: "#03705E",
                    fontFamily: "Beatrice",
                    fontSize: window.innerWidth > 768 ? "16px" : "0.75rem",
                    fontWeight: "700",
                  }}
                >
                  Transit time
                </Typography>
              </Col>
              <Col lg={8} md={6} sm={7} xs={6}>
                <Typography
                  style={{
                    fontFamily: "Beatrice",
                    color: "#03705E",
                    fontSize: window.innerWidth > 768 ? "16px" : "0.75rem",
                    fontWeight: "400",
                  }}
                >
                  {shippingTime ? shippingTime : "N/A"}
                </Typography>
              </Col>
            </Row>
            <hr /> */}
            <Row>
              <Col lg={4} md={6} sm={5} xs={6}>
                <Typography
                  style={{
                    color: "#03705E",
                    fontFamily: "Beatrice",
                    fontSize: window.innerWidth > 768 ? "16px" : "0.75rem",
                    fontWeight: "700",
                  }}
                >
                  Name
                </Typography>
              </Col>
              <Col lg={8} md={6} sm={7} xs={6}>
                <Typography
                  style={{
                    fontFamily: "Beatrice",
                    color: "#03705E",
                    fontSize: window.innerWidth > 768 ? "16px" : "0.75rem",
                    fontWeight: "400",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                >
                  {details?.name}
                </Typography>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={4} md={6} sm={5} xs={6}>
                <Typography
                  style={{
                    color: "#03705E",
                    fontFamily: "Beatrice",
                    fontSize: window.innerWidth > 768 ? "16px" : "0.75rem",
                    fontWeight: "700",
                  }}
                >
                  Email
                </Typography>
              </Col>
              <Col lg={8} md={6} sm={7} xs={6}>
                <Typography
                  style={{
                    fontFamily: "Beatrice",
                    color: "#03705E",
                    fontSize: window.innerWidth > 768 ? "16px" : "0.75rem",
                    fontWeight: "400",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                >
                  {details?.email}
                </Typography>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={4} md={6} sm={5} xs={6}>
                <Typography
                  style={{
                    color: "#03705E",
                    fontFamily: "Beatrice",
                    fontSize: window.innerWidth > 768 ? "16px" : "0.75rem",
                    fontWeight: "700",
                  }}
                >
                  Contact
                </Typography>
              </Col>
              <Col lg={8} md={6} sm={7} xs={6}>
                <Typography
                  style={{
                    fontFamily: "Beatrice",
                    color: "#03705E",
                    fontSize: window.innerWidth > 768 ? "16px" : "0.75rem",
                    fontWeight: "400",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                >
                  {details?.phoneNumber ? details?.phoneNumber : "N/A"}
                </Typography>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={4} md={6} sm={5} xs={6}>
                <Typography
                  style={{
                    color: "#03705E",
                    fontFamily: "Beatrice",
                    fontSize: window.innerWidth > 768 ? "16px" : "0.75rem",
                    fontWeight: "700",
                  }}
                >
                  Delivery Type
                </Typography>
              </Col>
              <Col lg={8} md={6} sm={7} xs={6}>
                <Typography
                  style={{
                    fontFamily: "Beatrice",
                    color: "#03705E",
                    fontSize: window.innerWidth > 768 ? "16px" : "0.75rem",
                    fontWeight: "400",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                >
                  {deliveryType ? deliveryType : "N/A"}
                </Typography>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={4} md={6} sm={5} xs={6}>
                <Typography
                  style={{
                    color: "#03705E",
                    fontFamily: "Beatrice",
                    fontSize: window.innerWidth > 768 ? "16px" : "0.75rem",
                    fontWeight: "700",
                  }}
                >
                  Address
                </Typography>
              </Col>
              <Col lg={8} md={6} sm={7} xs={6}>
                <Typography
                  style={{
                    fontFamily: "Beatrice",
                    color: "#03705E",
                    fontSize: window.innerWidth > 768 ? "16px" : "0.75rem",
                    fontWeight: "400",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                >
                  {details?.address
                    ? details?.address +
                      " " +
                      details?.state +
                      " " +
                      details?.city +
                      " " +
                      details?.zip
                    : "N/A"}
                </Typography>
              </Col>
            </Row>
            <hr />
          </div>
          {/* <div>
            <Typography
              style={{
                fontFamily: "Beatrice",
                color: "#03705E",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Throughout the creation process, if you have any questions or
              would like updates on your order's progress, please don't hesitate
              to reach out to our customer service team at{" "}
              <Typography>
                <span
                  style={{
                    color: "#03705E",
                    fontFamily: "Beatrice",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Email:{" "}
                </span>
                <span
                  style={{
                    color: "#BF6BE3",
                    fontFamily: "Beatrice",
                    fontSize: "16px",
                    fontWeight: "700",
                    textDecoration: "underline",
                  }}
                >
                  customer.service@nameglo.org{" "}
                </span>
                <span
                  style={{
                    color: "#03705E",
                    fontFamily: "Beatrice",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Call: (123) 456 7890.
                </span>
              </Typography>
            </Typography>
          </div> */}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            {product?.format === "super-custom" ||
            product?.backing?.name === "custom" ||
            product.delivery.deliveryType === "rush" ? (
              <>
                <Button
                  className=" hover-effect"
                  sx={{
                    color: "#FFF",
                    fontSize: "18px",
                    width: "40%",
                    fontFamily: "Beatrice",
                    fontWeight: "600",
                    bgcolor: "#25CCB0",
                    borderRadius: "24px",
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                    padding: "15px",
                    ":hover": {
                      color: "#25CCB0",
                      bgcolor: "#FFF",
                      border: "1px solid #25CCB0",
                    },
                  }}
                  onClick={close}
                >
                  Proceed
                </Button>
              </>
            ) : (
              <>
                <Button
                  className=" hover-effect"
                  sx={{
                    color: "#FFF",
                    fontSize: "18px",
                    width: "40%",
                    fontFamily: "Beatrice",
                    fontWeight: "600",
                    bgcolor: "#25CCB0",
                    borderRadius: "24px",
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                    padding: "15px",
                    ":hover": {
                      color: "#25CCB0",
                      bgcolor: "#FFF",
                      border: "1px solid #25CCB0",
                    },
                  }}
                  onClick={handleSubmit}
                >
                  Proceed to Invoice
                </Button>
              </>
            )}
          </div> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <a
            style={{
              color: "#ffffff",
              fontSize: "18px",
              fontWeight: "600",
              fontFamily: "Beatrice",
              // width: "50%",
              textAlign: "center",
              paddingTop: "8px",
              paddingLeft: "50px",
              paddingRight: "50px",
              margin: "10px",
              marginTop: "0px",
              border: "none",
              backgroundColor: "#36CDB4",
              borderRadius: "35px",
              textTransform: "uppercase",
              textDecoration: "none",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
            }}
            href="https://glo-studio.com/pages/thanks"
          >
            Ok, I&apos;m done!
          </a>
        </div>
      </Box>
    </Modal>
  );
};

export default InquiryThankYou;
