import "./App.css";
import "./App.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { Route, Routes } from "react-router-dom";

import Source from "./Pages/Source";
import Content from "./Pages/Content";
import Colors from "./Pages/Colors";
import Backing from "./Pages/Backing";
import Size from "./Pages/Size";
import Accessories from "./Pages/Accessories";
import Delivery from "./Pages/Delivery";
import Review from "./Pages/Review";
import BackingColor from "./Pages/BackingColor";
import { fetchFonts, getLEDSettings, getNeonSettings } from "./Redux/Slice";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/collapse.js";

const Font = lazy(() => import("./Components/Content/Font"));
const ProtectedRoutes = lazy(() =>
  import("./Components/Admin/ProtectedRoutes")
);
const AdminLogIn = lazy(() => import("./Pages/Admin/AdminLogIn"));
const Layout = lazy(() => import("./Layouts/Layout"));
const AdminDashboard = lazy(() => import("./Layouts/AdminDashboard"));
const Format = lazy(() => import("./Pages/Format"));

//const <div>Loadingdiv</> = () => <>Loading</>;

function App() {
  const activeStep = useSelector((state) => state.activeStep);
  const product = useSelector((state) => state.activeStep.product);
  const textAreas = useSelector((state) => state.activeStep.product.fonts);
  const fonts = useSelector((state) => state.activeStep.fonts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFonts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (product.source === "LED") {
      dispatch(getLEDSettings());
    } else if (product.source === "Glass") {
      dispatch(getNeonSettings());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.source]);

  useEffect(() => {
    if (product.backing.color) {
      document.documentElement.style.setProperty(
        "--custom-background-color",
        product.backing.color
      );
    }
    if (textAreas.length > 0) {
      document.documentElement.style.setProperty(
        "--stroke-color",
        textAreas[0]?.color
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (

    <Suspense
      fallback={(
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <ThreeDots
            color="#36CDB4"
          />
        </div>
      )}
    >

      {fonts.map((font, index) => (
        <Font
          key={font.id + "-" + index}
          name={font.name}
          woffFile={font.woffFile}
          woff2File={font.woff2File}
        />
      ))}

      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              {activeStep.step === 0 && <Format />}
              {activeStep.step === 1 && <Source />}
              {activeStep.step === 2 && <Content />}
              {activeStep.step === 3 && <Colors />}
              {activeStep.step === 4 && <Backing />}
              {activeStep.step === 5 && (
                <>
                  {product.format === "super-custom" ? (
                    <Size />
                  ) : (
                    <BackingColor />
                  )}
                </>
              )}
              {activeStep.step === 6 && (
                <>
                  {product.format === "super-custom" ? (
                    <Accessories />
                  ) : (
                    <Size />
                  )}
                </>
              )}
              {activeStep.step === 7 && (
                <>
                  {product.format === "super-custom" ? (
                    <Delivery />
                  ) : (
                    <Accessories />
                  )}
                </>
              )}
              {activeStep.step === 8 && (
                <>
                  {product.format === "super-custom" ? (
                    <Review />
                  ) : (
                    <Delivery />
                  )}
                </>
              )}
              {activeStep.step === 9 && (
                <>
                  <Review />
                </>
              )}
            </Layout>
          }
        />
        <Route element={<ProtectedRoutes />}>
          <Route path="/adminDashboard/*" element={<AdminDashboard />} />
        </Route>
        <Route path="/login" element={<AdminLogIn />} />
      </Routes>
    </Suspense>

  );
}

export default App;
