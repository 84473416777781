import React from "react";

const Lock = ({ width, height }) => {
  return (
    <svg
      width={width ? width : "26"}
      height={height ? height : "38"}
      viewBox="0 0 26 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="15" width="26" height="22.75" fill="#25CCB0" />
      <path
        d="M6.5 15C6.5 15 6.5 12.8333 6.5 8.5C6.5 4.16667 8.66667 2 13 2C17.3333 2 19.5 4.16667 19.5 8.5C19.5 12.8333 19.5 15 19.5 15"
        stroke="#25CCB0"
        strokeWidth="3"
      />
    </svg>
  );
};

export default Lock;
