import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialValues = {
  shippingAPIRate: 0,
  showConsoles: false,
  refImageHidden: true,
  whiteFranceRate: 0,
  blackFranceRate: 0,
  glassShippingMaterial: 0,
  glassPickMaterial: 0,
  weightPerCubicInch: 0,
  installationCost: 0,
  baseInstallValues: {},
  backingFlatRate: 0,
  backingFlatRateAdded: false,
  ledShippingMaterial: 0,
  InterShippingCost: 0,
  woodInterShippingCost: 0,
  acrylicInterShippingCost: 0,
  standardRGBCost: 0,
  magicRGBCost: 0,
  SpecialColorFee: 0,
  materialLengthFactor: 1,
  step: 0,
  theme: "white",
  showExamples: false,
  power: "Tech-22",
  powerQuantity: 0,
  helpData: {},
  examples: [],
  sources: [],
  installations: [],
  allFonts: [],
  fonts: [],
  colors: [],
  controllers: [],
  cables: [],
  accessories: [],
  backingStyles: [],
  backingColors: [],
  positions: {},
  colorCost: 0,
  bendingCostPerFt: 0,
  customBackingCost: 0,
  blackAccessoryCost: 0,
  clearPlexiCost: 0,
  tech22Cost: 0,
  plexiHeightMax: 0,
  plexiWidthMax: 0,
  materialLengthPerTech: 0,
  markup: 0,
  discount: 0,
  otherFonts: [],
  userFonts: [],
  otherColors: [],
  product: {
    refImage: {
      link: "",
      size: "",
      name: "",
    },
    cost: null,
    shippingCost: null,
    shippingTime: null,
    neonCost: null,
    backingCost: null,
    accessoriesCost: null,
    installationPrice: 0,
    totalCost: null,
    format: "custom",
    source: "LED",
    fonts: [],
    fontsAlignment: "column",
    fontsDescription: "",
    RGBColor: "",
    customBacking: "",
    colorDescription: "",
    backing: {
      name: "square",
      image: null,
      paddings: {},
      width: 0,
      height: 0,
      lockedWidth: 0,
      lockedHeight: 0,
      color: "#f0f0f0",
      styleDescription: "",
      colorDescription: "",
    },
    size: {
      width: 0,
      height: 0,
      depth: 0,
      minWidth: 0,
      minHeight: 0,
      fontSize: "",
      description: "",
    },
    accessories: {
      position: { x: 0, y: 0 },
      wireColor: "#000000",
      description: "",
      dimmer: {},
      addOns: [],
    },
    delivery: {
      deliveryType: "",
      details: {},
      install: {},
    },
  },
};
let steps =
  initialValues.product.format === "custom"
    ? [
        "Format",
        "Light Source",
        "Content",
        "Colors",
        "Backing Style",
        "Backing Color",
        "Size",
        "Accessories",
        "Delivery",
        "Review",
      ]
    : [
        "Format",
        "Light Source",
        "Content",
        "Colors",
        "Backing",
        "Size",
        "Accessories",
        "Delivery",
        "Review",
      ];

export const Slice = createSlice({
  name: "activeStep",
  initialState: initialValues,
  reducers: {
    setLockedWidth: (state, action) => {
      state.product.backing.lockedWidth = action.payload;
    },
    setLockedHeight: (state, action) => {
      state.product.backing.lockedHeight = action.payload;
    },
    setShippingAPIRate: (state, action) => {
      state.shippingAPIRate = action.payload;
    },
    setShowConsoles: (state, action) => {
      state.showConsoles = action.payload;
    },
    setMaterialLengthFactor: (state, action) => {
      state.materialLengthFactor = action.payload;
    },
    setCustomBacking: (state, action) => {
      state.product.customBacking = action.payload;
    },
    setPower: (state, action) => {
      state.power = action.payload;
    },
    setPowerQuantity: (state, action) => {
      state.powerQuantity = action.payload;
    },
    setCustomBackingCost: (state, action) => {
      state.customBackingCost = action.payload;
    },
    updateUserFont: (state, action) => {
      state.userFonts.push(action.payload);
    },
    setInternationalShippingPerSqIn: (state, action) => {
      state.InterShippingCost = action.payload;
    },
    setWoodInternationalShippingPerSqIn: (state, action) => {
      state.woodInterShippingCost = action.payload;
    },
    setAcrylicInternationalShippingPerSqIn: (state, action) => {
      state.acrylicInterShippingCost = action.payload;
    },
    setStandardRGBCost: (state, action) => {
      state.standardRGBCost = action.payload;
    },
    setMagicRGBCost: (state, action) => {
      state.magicRGBCost = action.payload;
    },
    setSpecialColorFee: (state, action) => {
      state.SpecialColorFee = action.payload;
    },
    updateRefImageStatus: (state, action) => {
      state.refImageHidden = action.payload;
    },
    updateAccessoryPosition: (state, action) => {
      state.product.accessories.position = action.payload;
    },
    setWhiteFranceRate: (state, action) => {
      state.whiteFranceRate = action.payload;
    },
    setBlackFranceRate: (state, action) => {
      state.blackFranceRate = action.payload;
    },
    setWeightPerCubicInch: (state, action) => {
      state.weightPerCubicInch = action.payload;
    },
    setGlassShippingMaterial: (state, action) => {
      state.glassShippingMaterial = action.payload;
    },
    setLEDShippingMaterial: (state, action) => {
      state.ledShippingMaterial = action.payload;
    },
    setGlassPickUpMaterial: (state, action) => {
      state.glassPickMaterial = action.payload;
    },
    setInstallationCost: (state, action) => {
      state.installationCost = action.payload;
    },
    setBaseInstallValues: (state, action) => {
      state.baseInstallValues = action.payload;
    },
    setProductMinWidth: (state, action) => {
      state.product.size.minWidth = action.payload;
    },
    setProductMinHeight: (state, action) => {
      state.product.size.minHeight = action.payload;
    },
    setProductBackingWidth: (state, action) => {
      state.product.backing.width = action.payload;
    },
    setProductBackingHeight: (state, action) => {
      state.product.backing.height = action.payload;
    },
    setBackingFlatRateAdded: (state, action) => {
      state.backingFlatRateAdded = action.payload;
    },
    setBackingFlatRate: (state, action) => {
      state.backingFlatRate = action.payload;
    },
    setProductSizeDepth: (state, action) => {
      state.product.size.depth = action.payload;
    },
    setProductDeliveryType: (state, action) => {
      state.product.delivery.deliveryType = action.payload;
    },
    setProductDeliveryDetails: (state, action) => {
      state.product.delivery.details = action.payload;
    },
    setProductBackingPaddings: (state, action) => {
      state.product.backing.paddings = action.payload;
    },
    setRGBColor: (state, action) => {
      state.product.RGBColor = action.payload;
    },
    setShippingCost: (state, action) => {
      state.product.shippingCost = action.payload;
    },
    setShippingTime: (state, action) => {
      state.product.shippingTime = action.payload;
    },
    setNeonCost: (state, action) => {
      state.product.neonCost = action.payload;
    },
    setBackingCost: (state, action) => {
      state.product.backingCost = action.payload;
    },
    setAccessoriesCost: (state, action) => {
      state.product.accessoriesCost = action.payload;
    },
    setInstallationPrice: (state, action) => {
      state.product.installationPrice = action.payload;
    },
    setTotalCost: (state, action) => {
      state.product.totalCost = action.payload;
    },

    setMarkUp: (state, action) => {
      state.markup = action.payload;
    },
    setDiscount: (state, action) => {
      state.discount = action.payload;
    },
    setPlexiHeightMax: (state, action) => {
      state.plexiHeightMax = action.payload;
    },
    setPlexiWidthMax: (state, action) => {
      state.plexiWidthMax = action.payload;
    },
    setMaterialLengthPerTech: (state, action) => {
      state.materialLengthPerTech = action.payload;
    },
    setBendingCost: (state, action) => {
      state.bendingCostPerFt = action.payload;
    },
    setClearPlexiCost: (state, action) => {
      state.clearPlexiCost = action.payload;
    },
    setBlackAccessoryCost: (state, action) => {
      state.blackAccessoryCost = action.payload;
    },
    setTech22Cost: (state, action) => {
      state.tech22Cost = action.payload;
    },
    setColorCost: (state, action) => {
      state.colorCost = action.payload;
    },
    updatePrice: (state, action) => {
      state.product.cost = state.product.cost + action.payload;
    },
    addPrice: (state, action) => {
      state.product.cost = action.payload;
    },
    updatePositions: (state, action) => {
      state.positions = action.payload;
    },
    setProductAccessoriesAddOns: (state, action) => {
      state.product.accessories.addOns.push(action.payload);
    },
    addProductAccessoriesAddOns: (state, action) => {
      state.product.accessories.addOns = action.payload;
    },
    removeProductAccessoriesAddOns: (state, action) => {
      const controllerIndex = state.product.accessories.addOns.findIndex(
        (addOn) => addOn.title === action.payload.title
      );
      if (controllerIndex !== -1) {
        state.product.accessories.addOns.splice(controllerIndex, 1);
      }
    },
    setProductAccessoriesDimmer: (state, action) => {
      state.product.accessories.dimmer = action.payload;
    },
    setProductInstall: (state, action) => {
      state.product.delivery.install = action.payload;
    },
    setProductAccessoriesDescription: (state, action) => {
      state.product.accessories.description = action.payload;
    },
    setProductAccessoriesWireColor: (state, action) => {
      state.product.accessories.wireColor = action.payload;
    },
    setProductSizeDescription: (state, action) => {
      state.product.size.description = action.payload;
    },
    setProductSizeWidth: (state, action) => {
      state.product.size.width = action.payload;
    },
    setProductSizeFontSize: (state, action) => {
      state.product.size.fontSize = action.payload;
    },
    setProductSizeHeight: (state, action) => {
      state.product.size.height = action.payload;
    },
    setProductBacking: (state, action) => {
      state.product.backing.name = action.payload;
    },
    setProductBackingImage: (state, action) => {
      state.product.backing.image = action.payload;
    },
    setProductBackingColor: (state, action) => {
      state.product.backing.color = action.payload;
    },
    setProductBackingColorDescription: (state, action) => {
      state.product.backing.colorDescription = action.payload;
    },
    setProductBackingStyleDescription: (state, action) => {
      state.product.backing.styleDescription = action.payload;
    },
    setProductColorDescription: (state, action) => {
      state.product.colorDescription = action.payload;
    },
    setProductFont: (state, action) => {
      state.product.fonts = action.payload;
    },
    setProductRefImageLink: (state, action) => {
      state.product.refImage.link = action.payload;
    },
    setProductRefImageName: (state, action) => {
      state.product.refImage.name = action.payload;
    },
    setProductRefImageSize: (state, action) => {
      state.product.refImage.size = action.payload;
    },
    setProductFontAlignment: (state, action) => {
      state.product.fontsAlignment = action.payload;
    },
    setProductFontDescription: (state, action) => {
      state.product.fontsDescription = action.payload;
    },
    setProductFormat: (state, action) => {
      state.product.format = action.payload;
    },
    setProductSource: (state, action) => {
      state.product.source = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setBackingStyles: (state, action) => {
      state.backingStyles = action.payload;
    },
    setBackingColors: (state, action) => {
      state.backingColors = action.payload;
    },
    setControllers: (state, action) => {
      state.controllers = action.payload;
    },
    setAccessories: (state, action) => {
      state.accessories = action.payload;
    },
    setCables: (state, action) => {
      state.cables = action.payload;
    },
    setColors: (state, action) => {
      state.colors = action.payload;
    },
    setFonts: (state, action) => {
      state.fonts = action.payload;
    },

    setAllFonts: (state, action) => {
      state.allFonts = action.payload;
    },
    addFont: (state, action) => {
      state.fonts.push(action.payload);
    },

    setSources: (state, action) => {
      state.sources = action.payload;
    },
    setInstallations: (state, action) => {
      state.installations = action.payload;
    },

    setExamplesData: (state, action) => {
      state.examples = action.payload;
    },
    setShowExamples: (state, action) => {
      state.showExamples = action.payload;
    },

    setHelpData: (state, action) => {
      state.helpData = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    nextStep: (state) => {
      if (state.step < steps.length - 1) {
        state.step += 1;
      }
    },
    backStep: (state) => {
      if (state.step > 0) {
        state.step -= 1;
      }
    },
    addOtherFonts: (state, action) => {
      state.otherFonts.push(action.payload);
    },
    addOtherColors: (state, action) => {
      state.otherColors.push(action.payload);
    },
    emptyOtherColors: (state) => {
      state.otherColors = [];
    },
    removeOtherFonts: (state, action) => {
      const fontIndex = state.otherFonts.findIndex(
        (color) => color.name === action.payload.name
      );
      if (fontIndex !== -1) {
        state.otherFonts.splice(fontIndex, 1);
      }
    },
    removeOtherColors: (state, action) => {
      const colorIndex = state.otherColors.findIndex(
        (color) => color.name === action.payload.name
      );
      if (colorIndex !== -1) {
        state.otherColors.splice(colorIndex, 1);
      }
    },
  },
});

export const {
  setLockedHeight,
  setLockedWidth,
  setShippingAPIRate,
  setShowConsoles,
  setInstallationPrice,
  setTotalCost,
  emptyOtherColors,
  setMaterialLengthFactor,
  setCustomBacking,
  setMagicRGBCost,
  setStandardRGBCost,
  setPower,
  setPowerQuantity,
  updateUserFont,
  addProductAccessoriesAddOns,
  setInternationalShippingPerSqIn,
  setRGBCost,
  setSpecialColorFee,
  setLEDShippingMaterial,
  updateRefImageStatus,
  updateAccessoryPosition,
  setBlackFranceRate,
  setWhiteFranceRate,
  setGlassPickUpMaterial,
  setGlassShippingMaterial,
  setInstallationCost,
  setWeightPerCubicInch,
  setBaseInstallValues,
  setProductMinHeight,
  setProductMinWidth,
  setProductBackingHeight,
  setProductBackingWidth,
  setBackingFlatRateAdded,
  setBackingFlatRate,
  setProductSizeDepth,
  setProductDeliveryType,
  setProductDeliveryDetails,
  setProductBackingPaddings,
  setProductBackingImage,
  setProductRefImageLink,
  setProductRefImageSize,
  setProductRefImageName,
  addFont,
  addOtherFonts,
  addOtherColors,
  removeOtherColors,
  removeOtherFonts,
  nextStep,
  backStep,
  setStep,
  setTheme,
  setHelpData,
  setAcrylicInternationalShippingPerSqIn,
  setWoodInternationalShippingPerSqIn,
  setExamplesData,
  setShowExamples,
  setSources,
  setInstallations,
  setFonts,
  setAllFonts,
  setColors,
  setBackingStyles,
  setBackingColors,
  setProductFormat,
  setProductSource,
  setProductFont,
  setProductFontAlignment,
  setProductFontDescription,
  setProductColorDescription,
  setProductBacking,
  setProductBackingColor,
  setProductBackingStyleDescription,
  setProductBackingColorDescription,
  setProductSizeWidth,
  setProductSizeFontSize,
  setProductSizeHeight,
  setProductSizeDescription,
  setProductAccessoriesWireColor,
  setProductAccessoriesDescription,
  setProductAccessoriesDimmer,
  setProductAccessoriesAddOns,
  setProductInstall,
  removeProductAccessoriesAddOns,
  updatePositions,
  updatePrice,
  addPrice,
  setControllers,
  setAccessories,
  setCables,
  setColorCost,
  setBendingCost,
  setBlackAccessoryCost,
  setClearPlexiCost,
  setTech22Cost,
  setMarkUp,
  setDiscount,
  setMaterialLengthPerTech,
  setPlexiHeightMax,
  setPlexiWidthMax,
  setShippingCost,
  setNeonCost,
  setBackingCost,
  setAccessoriesCost,
  setRGBColor,
  setShippingTime,
  setCustomBackingCost,
} = Slice.actions;

export const fetchHelp = (type) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}help-pages/${type}`
    );
    dispatch(setHelpData(response.data));
  } catch (err) {
    console.log(err);
  }
};
export const fetchExamples = (pageName, type, subPage) => async (dispatch) => {
  try {
    if (subPage) {
      // console.log("Subpoageeeeeeeeeeeeeeeeeeee");
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}example-pages/${pageName}/${type}/${subPage}`
      );
      // console.log(response.data);
      dispatch(setExamplesData(response.data));
    } else {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}example-pages/${pageName}/${type}`
      );
      // console.log(response.data);
      dispatch(setExamplesData(response.data));
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchSources = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}lighttypes`
    );
    dispatch(setSources(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const fetchInstallations = (type) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}delivery/install/${type}`
    );
    dispatch(setInstallations(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const fetchBackingColors = (type) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}acrylic/${type}`
    );
    dispatch(setBackingColors(response.data));
  } catch (err) {
    console.log(err);
  }
};
export const fetchBackingStyles = (type) => async (dispatch) => {
  try {
    if (type) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}backing/${type}`
      );
      dispatch(setBackingStyles(response.data));
    } else {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}backing`
      );
      dispatch(setBackingStyles(response.data));
    }
  } catch (err) {
    console.log(err);
  }
};
export const fetchColors = (type) => async (dispatch) => {
  try {
    if (type) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}colors/type/${type}`
      );
      dispatch(setColors(response.data));
    } else {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}colors`
      );
      dispatch(setColors(response.data));
    }
  } catch (err) {
    console.log(err);
  }
};
export const fetchControllers = (source) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}controller/${source}`
    );
    dispatch(setControllers(response.data));
  } catch (err) {
    console.log(err);
  }
};
export const fetchCables = (source) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}cables/${source}`
    );
    dispatch(setCables(response.data));
  } catch (err) {
    console.log(err);
  }
};
export const fetchAccessories = (source) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}accessories/${source}`
    );
    dispatch(setAccessories(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const fetchFonts = (type) => async (dispatch) => {
  try {
    if (type) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}fonts/${type}`
      );
      dispatch(setFonts(response.data));
    } else {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}fonts`
      );
      dispatch(setAllFonts(response.data));
    }
  } catch (err) {
    console.log(err);
  }
};

export const getNeonSettings = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}neon`
    );
    const { data } = response;
    if (data.length > 0) {
      console.log('getNeonSettings');
      console.log(data[0]);
      let baseValues = [
        {
          name: "Concealed Install",
          value: data[0].baseConceledInstallCost,
        },
        { name: "Backing Install", value: data[0].baseBackingInstallCost },
        {
          name: "Passthrough Install",
          value: data[0].basePassthroughInstallCost,
        },
        { name: "Hanging Install", value: data[0].baseHangingInstallCost },
        { name: "Surface Install", value: data[0].baseSurfaceInstallCost },
      ];
      dispatch(setBaseInstallValues(baseValues));
      dispatch(setColorCost(data[0].EGLWhite));
      dispatch(setBendingCost(data[0].glassBendingPerFit));
      dispatch(setClearPlexiCost(data[0].clearSquareplexi));
      dispatch(setTech22Cost(data[0].tech22Cost));
      dispatch(setBlackAccessoryCost(data[0].basicAccessoriesPerUnitCost));
      dispatch(setMarkUp(data[0].markup));
      dispatch(setDiscount(data[0].discount));
      dispatch(setPlexiHeightMax(data[0].clearSquareMaxPlexiHeight));
      dispatch(setPlexiWidthMax(data[0].clearSquareMaxPlexiWidth));
      dispatch(setMaterialLengthPerTech(data[0].materialLengthPerTech));
      dispatch(setInstallationCost(data[0].installCost));
      dispatch(setCustomBackingCost(data[0].customBackingCost));
      dispatch(setShowConsoles(data[0].showConsole));
    } 
  } catch (error) {
    console.log(error);
  }
};
export const getLEDSettings = () => async (dispatch) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}led`);
    const { data } = response;
    if (data.length > 0) {
      let baseValues = [
        {
          name: "Concealed Install",
          value: data[0].baseConceledInstallCost,
        },
        { name: "Backing Install", value: data[0].baseBackingInstallCost },
        {
          name: "Passthrough Install",
          value: data[0].basePassthroughInstallCost,
        },
        { name: "Hanging Install", value: data[0].baseHangingInstallCost },
        { name: "Surface Install", value: data[0].baseSurfaceInstallCost },
      ];
      dispatch(setBaseInstallValues(baseValues));
      dispatch(setColorCost(data[0].coolWhite));
      dispatch(setClearPlexiCost(data[0].clearSquareplexi));
      dispatch(setTech22Cost(data[0].tech22Cost));
      dispatch(setBlackAccessoryCost(data[0].basicAccessoriesPerUnitCost));
      dispatch(setMarkUp(data[0].markup));
      dispatch(setDiscount(data[0].discount));
      dispatch(setPlexiHeightMax(data[0].clearSquareMaxPlexiHeight));
      dispatch(setPlexiWidthMax(data[0].clearSquareMaxPlexiWidth));
      dispatch(setMaterialLengthPerTech(data[0].materialLengthPerTech));
      dispatch(setStandardRGBCost(data[0].standardRGBCost));
      dispatch(setMagicRGBCost(data[0].magicRGBCost));
      dispatch(setSpecialColorFee(data[0].specialColorFee));
      dispatch(setInstallationCost(data[0].installCost));
      dispatch(setCustomBackingCost(data[0].customBackingCost));
      dispatch(
        setInternationalShippingPerSqIn(data[0].standardInterShippingCost)
      );
      dispatch(
        setWoodInternationalShippingPerSqIn(data[0].woodInterShippingCost)
      );
      dispatch(
        setAcrylicInternationalShippingPerSqIn(data[0].acrylicInterShippingCost)
      );
      dispatch(setShowConsoles(data[0].showConsole));

    }
  } catch (error) {
    console.log(error);
  }
};

export default Slice.reducer;
