import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import Close from "../../Assets/Close";

const CustomColorPopup = ({ setShowCustom, show, close }) => {
  const handleClose = () => {
    close();
  };

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 768 ? "40%" : "80%",
    // height: "95vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };
  // console.log(risks);
  // console.log(risksError);
  return (
    <>
      <Modal
        open={show}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice",
                color: "#595959",
                fontSize: "1.25rem",
                fontWeight: "600",
                letterSpacing: "0.05em",
              }}
            >
              Some things to consider when selecting a custom color:
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setShowCustom(false);
                close();
              }}
            >
              <Close />
            </div>
          </div>

          <div
            style={{
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "0.75rem",
                fontFamily: "Beatrice",
              }}
            >
              Custom colors can get a little pricey. This is a great option if
              you have a brand color you&apos;re really trying to match, or a
              specific aesthetic need. To get started, choose a color from the
              Color picker on the right side
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              className="hover-effect"
              style={{
                color: "#ffffff",
                fontSize: "1rem",
                fontWeight: "600",
                fontFamily: "Beatrice",
                width: window.innerWidth > 768 ? "50%" : "60%",
                textAlign: "center",
                paddingTop: "10px",
                border: "none",
                backgroundColor: "#36CDB4",
                borderRadius: "35px",
                textTransform: "uppercase",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
              }}
              onClick={handleClose}
            >
              Ok, I’m ready
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CustomColorPopup;
