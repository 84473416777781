import { Box, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import Close from "../../../Assets/Close";
import { Carousel } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Image1 from "../../../Assets/SuperCustom/Navigation/1.png";
import Image2 from "../../../Assets/SuperCustom/Navigation/2.png";
import Image3 from "../../../Assets/SuperCustom/Navigation/3.png";
import {
  setProductRefImageLink,
  setProductRefImageName,
  setProductRefImageSize,
} from "../../../Redux/Slice";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";

const UploadImage = ({ show, close }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const texts = [
    {
      image: Image1,
      note: " Please note, that this feature is meant to be used as a sketch! Once you submit your design, we’ll be in touch to finalize the design and to provide a quote.",
      content:
        "When selecting an image for your neon idea, there are a few technical things to note so that your image looks optimal in the previewer. See the slideshow to learn more.",
    },
    {
      image: Image2,
      note: " Don’t forget to use the notepad (bottom right) to tell us how to use this image.",
      content:
        "You can upload an image that is a reference to draw your neon lines on top of.     Or, you can also upload a fully designs neon concept. ither way, works for us!    By default, our previewer will fill the canvas with your image.    ",
    },
    {
      image: Image3,
      note: "You can always use the notepad (bottom right) to tell us where to place the image!",
      content:
        "If you have a specific placement for the image in mind, you can create an image that reflects the placement like in the examples below. The optimal canvas size for this would be 325px X 130 px (or ratio 2.5 : 1).     ",
    },
  ];
  const [uploading, setUploading] = useState(false); // State to control uploading state
  // const refImage = useSelector((state) => state.activeStep.product.refImage);

  const handleClose = () => {
    close();
  };

  const dispatch = useDispatch();

  const handleFileSelect = async (e) => {
    setUploading(true);
    let image = e.target?.files[0];
    // console.log(image);
    const form = new FormData();
    form.append("image", image);
    // console.log(form.get("image"));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}led-backing`,
        form
      );
      // console.log(response.data);
      dispatch(setProductRefImageLink(response.data));
      dispatch(setProductRefImageSize(image?.size));
      dispatch(setProductRefImageName(image?.name));
      setUploading(false);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth < 762 ? "90%" : "70%",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
  };

  return (
    <>
      <Modal
        open={show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                width: "100%",
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: window.innerWidth < 762 ? "1rem" : "40px",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
                textAlign: "center",
              }}
            >
              Upload an Image
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={close}
            >
              <Close />
            </div>
          </div>
          <div>
            <Typography
              style={{
                fontFamily: "Beatrice",
                fontSize: window.innerWidth < 762 ? "0.75rem" : "20px",
                fontWeight: "400",
                color: "#595959",
                marginBottom: "10px",
                textAlign: "justify",
              }}
            >
              {texts[selectedImageIndex].content}
            </Typography>
            <Typography
              style={{
                fontFamily: "Beatrice",
                fontSize: window.innerWidth < 762 ? "0.75rem" : "20px",
                fontWeight: "400",
                color: "#BF6BE3",
                marginBottom: "10px",
                textAlign: "justify",
              }}
            >
              {texts[selectedImageIndex].note}
            </Typography>

            <Carousel
              style={{
                width: "100%",
                borderRadius: "16px",
              }}
              activeIndex={selectedImageIndex}
              onSelect={(index) => setSelectedImageIndex(index)}
            >
              {texts.map((item, index) => (
                <Carousel.Item key={index}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        height: window.innerWidth < 762 ? "200px" : "500px",
                        borderRadius: "16px",
                      }}
                      src={item.image}
                      alt={`img1`}
                    />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
            {uploading ? (
              <>
                <ThreeDots
                  width="100%"
                  radius="9"
                  color="#36CDB4"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </>
            ) : (
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <label
                  style={{
                    width: window.innerWidth < 450 ? "50%" : "20%",
                  }}
                >
                  <input
                    id="fileInput"
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileSelect(e)}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      color: "#ffffff",
                      textAlign: "center",
                      fontSize: window.innerWidth < 762 ? "0.8rem" : "16px",
                      padding: "10px",
                      fontWeight: "600",
                      backgroundColor: "#36CDB4",
                      borderRadius: "16px",
                      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                      ":hover": {
                        color: "#36CDB4",
                        border: "1px solid #36CDB4",
                      },
                    }}
                  >
                    Upload
                  </div>
                </label>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default UploadImage;
